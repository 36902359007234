// Participant reducer types
export const excelConstant = {

    //Excel Upload
    EXCEL_UPLOAD_REQUEST: 'EXCEL_UPLOAD_REQUEST',
    EXCEL_UPLOAD_SUCCESS: 'EXCEL_UPLOAD_SUCCESS',
    EXCEL_UPLOAD_FAILURE: 'EXCEL_UPLOAD_FAILURE',

    //Excel Upload
    EXCEL_DOWNLOAD_REQUEST: 'EXCEL_DOWNLOAD_REQUEST',
    EXCEL_DOWNLOAD_SUCCESS: 'EXCEL_DOWNLOAD_SUCCESS',
    EXCEL_DOWNLOAD_FAILURE: 'EXCEL_DOWNLOAD_FAILURE',

    //update file data
    EXCEL_DATA_UPDATE:'EXCEL_DATA_UPDATE',
    
};
