import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import _ from "lodash";

// Import Styles and Image
import ReportsStyles from "../../ReportsStyles";

// Import Actions
import { dashboardAction, excelAction } from "../../../../redux/actions/dashboardAction";

// Import Component
import HighLevelSummaryTable from "./HighLevelSumaryTable";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";

function HighLevelSummary(props) {

    // Get Props
    const { height, isUpdate, updateReportPreference, stopChartReload, preference, allSurveys, summaryTableColumns } = props;

    // Define Dispatch
    const dispatch = useDispatch();

    // Set HLS Data
    const HLSdata = useSelector((state) => state.dashboard.highlevelSummary);
    const { hlsinitialReload } = useSelector((state) => state.dashboard.initialReload);
    const { dashboardData: { keydriver } } = useSelector((state) => state.dashboard);
    const loading = HLSdata.loading;
    const his_loading = HLSdata.his_loading;

    // API call for table data
    useEffect(() => {
        if (isUpdate && !loading ) {
            dispatch(dashboardAction.getHistoricalData())
            stopChartReload("0")
        }
    }, [dispatch, isUpdate, loading, hlsinitialReload])

    // Get Preference Values
    const { hls: HLS, historical } = { ...preference };

    let selectedRgi = HLS && HLS["rg"] ? HLS["rg"] : "tenure_groupings";
    let selectValue = HLS && HLS["type"] ? HLS["type"] : "category"
    let historicalType = historical && historical["type"] ? historical["type"] : ""
    let historicalSurvey = historical && historical["survey"] ? historical["survey"]["name"] : ""

    // sort from preference
    let selectedSort = HLS && HLS["sort"] ? HLS["sort"] : ((keydriver.length > 0 && selectValue !== "demographic")? "desc" :  'asc')
    let selectedSortBy = HLS && HLS["sortBy"] ? HLS["sortBy"] : (selectValue === "question" ? (keydriver.length > 0 ? "corelation" :  'questionText') : (keydriver.length > 0 ? "corelation" :  'keytext'))

    // Historical date API call for dates
    const onChangeDateValue = (value) => {
        const data = {
            ...preference,
            surveys: preference.surveys.map((e) => (e._id ? e._id : e)),
            historical: {
                type: value,
                survey: null
            },
        };
        // Save Preferences data
        updateReportPreference(data, "0");
        stopChartReload("0")
    }

    // Historical date API call for Survey
    const onChangeSurveyvalue = (value) => {
        const data = {
            ...preference,
            surveys: preference.surveys.map((e) => (e._id ? e._id : e)),
            historical: {
                type: null,
                survey: value
            },
        };

        // Save Preferences data
        updateReportPreference(data, "0")
        stopChartReload("0")
    }

    //saving preference
    const saveReportPreferences = () => {
        const data = {
            ...preference,
            surveys: preference.surveys.map((e) => (e._id ? e._id : e)),
            hls: {
                type: selectValue,
                rg: selectedRgi
            }
        };
        // Save Preferences data
        updateReportPreference(data, -1)
    };

    //update selected tye and save preferencre
    const updateSelectedValue = (type, fields) => {
        selectValue = type;
        saveReportPreferences();

        // do not display the first field in the dropdown
        fields.shift()
        
        // Clear HLS Filters
        dispatch(dashboardAction.applyFilters(
            () => {
                // update the dropdown
                dispatch(dashboardAction.updateSummaryWidgetColumnsDropdown(fields))
            },
            true,
            'hls',
            {}
        ))
    };

    // update selected rg and save preferencre
    const updateSelectedrg = (rg) => {
        selectedRgi = rg;
        saveReportPreferences();
    };

    /**
  * Handle Table Sort Request Event
  */
    const onSortRequest = (sortBy, sort) => {

        // update sort preference
        const data = {
            ...preference,
            surveys: preference.surveys.map((e) => (e._id ? e._id : e)),
            hls: {
                ...(preference.hls || {}),
                sort: sort,
                sortBy: sortBy
            },
        };

        // Save Preferences data
        updateReportPreference(data, -1)

    };

    // Apply Filters
    const applyFilters = ((callback, param, data) => {
        dispatch(
            dashboardAction.applyFilters(
                callback,
                true,
                param,
                data
            )
        )
    })

    return (
        <React.Fragment>
            {(loading || his_loading) &&
                <LoaderComponent></LoaderComponent>
            }
            <Grid item sm={12}>
                {HLSdata && HLSdata?.['hlsData']?.['category'] && !loading && !his_loading &&
                    (Object.keys(HLSdata.hlsData.category).length > 0
                        ?
                        <Grid container spacing={3} style={{ marginLeft: "-12px" }}>
                            <HighLevelSummaryTable
                                HLSdata={HLSdata}
                                selectedValue={selectValue}
                                sort={selectedSort}
                                sortBy={selectedSortBy}
                                historical={historicalType}
                                historicalSurvey={historicalSurvey}
                                selectedRgidata={selectedRgi}
                                updateSelectedValue={updateSelectedValue}
                                updateSelectedrg={updateSelectedrg}
                                surveynames={allSurveys}
                                height={height}
                                noDataErrorMessage={"No Records Found"}
                                onChangeDateValue={onChangeDateValue}
                                onChangeSurveyvalue={onChangeSurveyvalue}
                                onSortRequest={onSortRequest}
                                keydriver={keydriver}
                                summaryTableColumns={summaryTableColumns}
                                filtersApplied={HLSdata?.['filtersApplied']}
                                applyFilters={applyFilters}
                            />

                        </Grid> :
                        <Grid container className="justifycenter dflex mt-3">
                            <TypographyComponent variant="h3">Not enough responses for the selected content</TypographyComponent>
                        </Grid>
                    )
                }
            </Grid>

        </React.Fragment>
    );
}

// default props
HighLevelSummary.defaultProps = {
    classes: {},
    height: "350px",
    isUpdate: false,
    preference: {},
    updateReportPreference: () => { },
    stopChartReload: () => { },
    allSurveys: []
};

// prop types
HighLevelSummary.propTypes = {
    classes: PropTypes.object,
    height: PropTypes.number,
    isUpdate: PropTypes.bool,
    preference: PropTypes.object,
    updateReportPreference: PropTypes.func,
    stopChartReload: PropTypes.func,
    allSurveys: PropTypes.array
};
export default withStyles(ReportsStyles)(HighLevelSummary);