import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import TakeSurveyStyles from "./TakeSurveyStyles";

// Import Actions
import { takeSurveyAction } from "../../redux/actions";

// Import Take Survey Components
import TakeSurvey from "./TakeSurvey";
import AuthenticationLayout from "../../layouts/AuthenticationLayout/AuthenticationLayout";
import AuthKiosk from "./Authentication/AuthKiosk";
import AuthAnonymous from "./Authentication/AuthAnonymous";
import ThankYouComponent from "./ThankYouComponent";
import ErrorPageComponent from "./ErrorPageComponent";

//Import Loading Container
// import LoadingComponent from "../PageSetupPreference/PageSetUpPreference";
import LoadingComponent from "./LoadingComponent";

function TakeSurveyAuthentication(props) {
  //get props
  const { match, takeSurveyUrl, surveyDeviceType, queryString } = props;

  const { params } = match;

  // Define Dispatch
  const dispatch = useDispatch();
  //Get Survey
  useEffect(() => {
    const surveyGetQuery = {
      takeSurveyUrl,
      params,
      queryString,
      surveyDeviceType,
    };
    dispatch(takeSurveyAction.getSurvey(surveyGetQuery));
  }, [dispatch]);

  const surveyAuthType = useSelector((state) => state.takeSurvey.surveyAuthType);
  const surveyAuthValidate = useSelector((state) => state.takeSurvey.surveyAuthValidate);

  const takeSurveyStatus = useSelector((state) => state.takeSurvey.takeSurveyStatus);

  const takeSurveyErrors = useSelector((state) => state.takeSurvey.takeSurveyErrors);

  // Uncomment this code when want to redirect takesurvey to Production Take survey URL
  const isRedirectProd = useSelector((state) => state.takeSurvey.redirect);

  if (isRedirectProd) {
    window.location.href = isRedirectProd;
  }

  //"done", "closed", "pending", "expired"
  return (
    <React.Fragment>
      {takeSurveyStatus === true ? (
        surveyAuthType && surveyAuthType == takeSurveyUrl && !surveyAuthValidate ? (
          <AuthenticationLayout>{surveyAuthType == "kiosk" ? <AuthKiosk {...props} /> : surveyAuthType == "anonymous" ? <AuthAnonymous {...props} /> : null}</AuthenticationLayout>
        ) : (
          <TakeSurvey {...props} />
        )
      ) : takeSurveyStatus == "done" ? (
        <ThankYouComponent props={{ ...props }} match={match} />
      ) : takeSurveyErrors == null ? (
        <LoadingComponent takeSurveyStatus={takeSurveyStatus} />
      ) : (
        <ErrorPageComponent takeSurveyError={takeSurveyErrors} />
      )}
    </React.Fragment>
  );
}

// default props
TakeSurveyAuthentication.defaultProps = {
  classes: {},
};

// prop types
TakeSurveyAuthentication.propTypes = {
  classes: PropTypes.object,
};
export default withStyles(TakeSurveyStyles)(TakeSurveyAuthentication);
