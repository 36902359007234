/* eslint-disable no-unneeded-ternary */
import React from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";

// import Components
import ImportFileComponent from "../../../../components/ImportFileComponent/ImportFileComponent";

// Import Actions
import { excelAction, alertAction} from "../../../../redux/actions";

// import constants
import { participantConstants, excelConstants } from "../../../../config/constants";

function ImportPage (props) {
  const dispatch = useDispatch();

  // update file data in reducer and redirect to submit page
  const importFileData = (file) => {
    const fileData = file ? file : {};
    if (fileData && fileData.path) {
      const excelType = excelConstants.ADMIN_UPLOAD;
      var formData = new FormData();
      formData.append("file", fileData);
      formData.append("excelType", excelType);
      dispatch(excelAction.upload(formData, excelType));
    } else {
      dispatch(alertAction.error("Please select a file to upload"));
    }
  };

  return (
    <ImportFileComponent
      backBtnURL={"/manage_people/admins"}
      cancelBtnURL={"/manage_people/admins"}
      headerText={participantConstants.ADMIN_UPLOAD}
      dropText={participantConstants.UNASSIGN_IMPORT}
      importFileData={importFileData}
    />
  );
}
// default props
ImportPage.defaultProps = {
  match: {}
};

// prop types
ImportPage.propTypes = {
  match: PropTypes.object
};
export default ImportPage;
