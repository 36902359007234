import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';

//import table Dimentsions
import { getHeaderTabTableHeight, getReportTableRowSize } from "../../../config/layout";

// Import History
import history from "../../../config/history";

// Import helper
import { getQueryVariable } from "../../../helpers";

// Import Component
import TableComponent from "../../../components/TableComponent/TableComponent";
import DialogComponent from "../../../components/DialogComponent/DialogComponent";
import CreateReportPopup from "./CreateReport/CreateReportPopup";

// Import Actions
import { reportAction, excelAction, pdfAction } from "../../../redux/actions";

// Import Styles and Image
import ReportsStyles from "../ReportsStyles";

// import constants
import { excelConstants, pdfConstants, ReportsConstants, REPORT_TYPE_CONSTANTS } from "../../../config/constants";

function SavedReports(props) {
  const excelReports = [REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT, REPORT_TYPE_CONSTANTS.REHIRE_REPORT,
    REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT, REPORT_TYPE_CONSTANTS.RAW_DATA]
    
  const dispatch = useDispatch();

  //table dimensions
  const tableHeight = getHeaderTabTableHeight();
  const tableRowSize = getReportTableRowSize();

  // get props
  const { classes, location } = props;
  //state for open close popup
  const [savePopup, setSavePopup] = useState(false);
  const openPopup = () => {
    setSavePopup(true);
  };
  const closePopup = () => {
    setSavePopup(false);
    dispatch(reportAction.resetReportData());
  };

  // Get reports List
  const reportList = useSelector((state) => state.report.savedReports);
  const { data, page } = reportList;

  //Reset all search text box to empty
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";
  useEffect(() => {
    if (isPageReset) {
      history.replace(props.location.pathname)
      dispatch(reportAction.getSavedReports(1, tableRowSize, "status", "desc", [], []));
    }
  }, [isPageReset]);

  //Handle Table pagination Event
  const onChangePagination = (skip, limit) => {
    dispatch(reportAction.getSavedReports(skip,limit,page.sortBy,page.sort,page.sFields,page.sValues));
  };

  //Handle Table Sort Request Event
  const onSortRequest = (sortBy, sort) => {
    dispatch(
      reportAction.getSavedReports(page.skip,page.limit,sortBy,sort,page.sFields,page.sValues));
  };

  //Handle Table Search Input Change Event
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    dispatch(reportAction.getSavedReports(1,tableRowSize,page.sortBy,page.sort,sFields,sValues));
  };

  // handle export
  const handleExport = (item) => {
    const { download: reportData, reportType} = item;
    const { selectedDemographic: demographics, commentQuestions } = reportData;
    // reportData.surveys = reportData.surveysData
    if (excelReports.indexOf(reportType) > -1) {
      if (reportType === REPORT_TYPE_CONSTANTS.RED_FLAG_REPORT) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.RED_FLAG_REPORT
        }));
      } else if (reportType === REPORT_TYPE_CONSTANTS.RAW_DATA) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.RAW_DATA_REPORT
        }));
      } else if (reportType === REPORT_TYPE_CONSTANTS.PARTICIPATION_REPORT) {
        dispatch(excelAction.download({ filters: reportData, excelType: excelConstants.PARTICIPATION_REPORT }));
      } else if (reportType === REPORT_TYPE_CONSTANTS.REHIRE_REPORT) {
        dispatch(excelAction.download({
          ...reportData,
          excelType: excelConstants.REHIRE_POTENTIAL_REPORT
        }));
      }
    } else {
      //comment Report
      if (reportType === REPORT_TYPE_CONSTANTS.COMMENT_REPORT) {
        const filters = { ...reportData, commentQuestions: commentQuestions }
        if (demographics === "Topics") {
          dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.COMMENT_REPORT_NLP }))
        } else {
          dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.COMMENT_REPORT }))
        }
      }

      //benchmark Report
      else if (reportType === pdfConstants.BENCHMARK_REPORT) {
        const filters = { ...reportData}
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.BENCHMARK_REPORT, reqType: "report" }))
      }

      //dashboard report
      else if (reportType === pdfConstants.DASHBOARD_REPORT){
        const filters = { ...reportData}
        dispatch(pdfAction.download({ filters: filters, pdfType: pdfConstants.DASHBOARD_SAVED_REPORT, reqType: "report" }))
      }
    }
    
  };

  //Handle DOWNLOAD Button Event
  const handelActionButtonClick = (field, id, item) => {
    switch (field) {
      case "EXPORT":
        handleExport(item);
        break;
      default:
        break;
    }
  };

  //Delete Report
  const handleTableDeleteAction = (id) => {
    dispatch(reportAction.remove({ id: id }, () => {
        //retain old search values
        if (page.skip) {
          dispatch(reportAction.getSavedReports(page.skip,page.limit,page.sortBy,page.sort,page.sFields,page.sValues));
        } else {
          dispatch(reportAction.getSavedReports(1,tableRowSize,"status","desc",[],[]));
        }
      })
    );
  };

  /**
   * Clear Search Text By Field
   * @param {*} searchField
   * @param {*} searchKey
   */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(reportAction.getSavedReports(1,tableRowSize,page.sortBy,page.sort,sFields,sValues));
  };

  //Handle Table Navigation Event
  const handleNavigateAction = (id, field, item) => {
    dispatch(reportAction.getReportData({ id: id }));
    openPopup();
  };

  return (
    <React.Fragment>
      <TableComponent
        data={data}
        header={page.fieldFilters.headerCols}
        field={page.fieldFilters.fieldCols}
        search={page.fieldFilters.searchCols}
        actions={["EXPORT", "DELETE"]}
        removeTooltipCols={["date", "frequency"]}
        height={tableHeight}
        page={page.skip}
        count={page.count}
        rowsPerPage={page.limit}
        links={[page.fieldFilters.fieldCols[0]]}
        sort={page.sort}
        sortBy={page.sortBy}
        sFields={page.sFields}
        sValues={page.sValues}
        noDataErrorMessage={"No Reports Found"}
        paginationVisible={!page.lastPage}
        alignCenterFields={[]}
        customIconFields={'reportType'}
        deleteContent={ReportsConstants.DELETE_REPORT}
        handleNavigateAction={handleNavigateAction}
        onChangePagination={onChangePagination}
        onSortRequest={onSortRequest}
        onInputChangeRequest={onInputChangeRequest}
        handelActionButtonClick={handelActionButtonClick}
        handleTableDeleteAction={handleTableDeleteAction}
        handleClearSearchByField={handleClearSearchByField}
        resetSearchText={isPageReset}
      ></TableComponent>
      {savePopup && (
        <DialogComponent open={true} fullScreen={true}>
          <CreateReportPopup title={ReportsConstants.EDITA_REPORT} closePopup={closePopup} classes={classes} isSaveReport={true} />
        </DialogComponent>
      )}
    </React.Fragment>
  );
}
// default props
SavedReports.defaultProps = {
  classes: {},
  location:{},
};

// prop types
SavedReports.propTypes = {
  classes: PropTypes.object,
  location:PropTypes.object
};
export default withStyles(ReportsStyles)(SavedReports);
