import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';
import Popover from "@mui/material/Popover";
import MenuItem from "@mui/material/MenuItem";

// Import Styles and Image
import ReportsStyles from "./ReportsStyles";

// Import Support Contaniners
import InsightSaveReport from './Insight/InsightSaveReport'
import InsightDownloadReport from './Insight/InsightDownloadReport'
import FilterIcon from './Insight/FilterIcon'
import OptionsMenu from './OptionsMenu'
import { ReportsConstants } from '../../config/constants';

// Import Components
import ToolTipComponent from '../../components/ToolTipComponent/ToolTipComponent';
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";

// Import Helpers
import { generateOverViewText } from "../../helpers/appHelper"
import { adminsAction } from "../../redux/actions"
import LoaderComponent from '../../components/LoaderComponent/LoaderComponent';
import { userGuide, downloadPdf, openNewTab } from "../../../src/assets/images/svgComponents/ImageComponent";
function ReportsActions(props) {
	//Get props
	const { location, match, classes } = props;
	const dispatch = useDispatch();
	// Redux Selectors
	let { surveys, date } = useSelector(state => state.admin.preference)
	const { allSurveys, filterData } = useSelector(state => state.filter)
	let { dashboardActionLoader, dashBoardLoaderQuestion, dashBoardLoaderCount } = useSelector(state => state.dashboard.dashboardData)
	const hasMESurvey = (filterData.surveys || []).filter(f => f.type === "Multi-rater feedback").length > 0
	const { pdfLoader } = useSelector(state => state.dashboard)
	let isDashboard = true

	let loadingText = "Loading data..."
	if (pdfLoader) {
		loadingText = "Downloading file..."
	} else {
		loadingText = "Loading dashboard data..."
	}

	// To check if the surveys met survey threshold value
	const surveysId = [];
	(surveys || []).map((val) => {
		surveysId.push(val._id)
	})
	if (allSurveys) {
		surveys = allSurveys.filter((sur) => surveysId.includes(sur._id))
	}
		//open close save popup
		const [anchorE2, setAnchorE2] = React.useState(null);
		const handleClick = (event) => {
			setAnchorE2(event.currentTarget);
		};
		const handleClose = () => {
			setAnchorE2(null);
		};

		const open = Boolean(anchorE2);
		const id = open ? "simple-popover" : undefined;

	/**
 * Insight Menu
 */
	const [anchorEl, setAnchorEl] = React.useState(null);

	const onFilterIconClick = () => {
		dispatch(adminsAction.toggleFilterDrawer())
	}

	if (location.pathname !== "/reports") {
		isDashboard = false
	}

	// Save Report - Report Type
	const reportType = location.pathname === '/reports/comment' ? 'Dashboard Report Comment' : 'Dashboard Report';

	return (
		<>
		<Grid item>
			<Grid container alignItems="center">
				{(dashboardActionLoader || dashBoardLoaderCount || dashBoardLoaderQuestion ) && isDashboard &&
					<Grid className='dflex pr-1'>
						<TypographyComponent variant="h6">
							{loadingText}
						</TypographyComponent>
						<CircularProgress size={18} className={classes.circularBarPrimary} />
						<TypographyComponent className='pl5' variant="h6">
							{"|"}
						</TypographyComponent>
					</Grid>
				}
				{pdfLoader &&
					<Grid className='dflex pr-1'>
						<TypographyComponent variant="h6">
							{loadingText}
						</TypographyComponent>
						<CircularProgress size={18} className={classes.circularBarPrimary} />
						<TypographyComponent className='pl5' variant="h6">
							{"|"}
						</TypographyComponent>
					</Grid>
				}
				<ToolTipComponent title={generateOverViewText('full', surveys && surveys.length > 0 ? surveys : [], date)}>
					<Grid item className={location.pathname === '/reports' ? '' : 'pr-1'}>
						<TypographyComponent className='cursor-pointer' variant="h6">
							{generateOverViewText('read', surveys && surveys.length > 0 ? surveys : [], date)}
						</TypographyComponent>
					</Grid>
				</ToolTipComponent>
				{(location.pathname === "/reports/insight" || location.pathname === "/reports/comment") &&
					<React.Fragment>
						{
							!hasMESurvey &&
								<Grid item>
									<Grid item display={'flex'} alignItems={'center'} className={'cursor-pointer'}>
										<ButtonComponent
											title={ReportsConstants.USER_GUIDE}
											color={'primary'}
											handleClick={handleClick}
										>
										</ButtonComponent>
									</Grid>
								</Grid>
						}
						<Grid item><FilterIcon onClick={onFilterIconClick} /></Grid>
						<Grid item><InsightSaveReport reportType={reportType} /></Grid>
						<Grid item><InsightDownloadReport reportType={reportType} /></Grid>
						{
							location.pathname === '/reports/insight' &&
							<Grid item>
								<OptionsMenu
									match={match}
									classes={classes}
									onChange={(e) => {
										console.log(e)
									}}
									anchorEl={anchorEl}
									setAnchorEl={setAnchorEl}
									/>
							</Grid>
						}
					</React.Fragment>
				}
			</Grid>
		</Grid>
		<Popover
		id={id}
		open={open}
		anchorEl={anchorE2}
		onClose={handleClose}
		anchorOrigin={{
			vertical: "bottom",
			horizontal: "right",
		}}
		transformOrigin={{
			vertical: "top",
			horizontal: "right",
		}}
	>
		{(location.pathname === "/reports/insight")&&
		<Grid className={`pt-2 pb-2`} minWidth={'215px'}>
			<MenuItem component="a" href="https://pe-client-doc.s3.us-west-2.amazonaws.com/Report+User+Guide+V2.pdf" download="Report User Guide" target="_blank"s className='pt-0 pb-0'>
				<Grid item display={'flex'} alignItems={'center'} gap={1.25} paddingTop={1.25} paddingBottom={1.25}>
					{downloadPdf()}
					<TypographyComponent variant="h6">
						{ReportsConstants.DOWNLOAD_PDF}
					</TypographyComponent>
				</Grid>
			</MenuItem>
			<MenuItem component="a" href="https://20709424.hs-sites.com/report-overview" target='_blank' className='pt-0 pb-0 mt4'>
				<Grid item display={'flex'} alignItems={'center'} gap={1.25} paddingTop={1.25} paddingBottom={1.25}>
					{openNewTab()}
					<TypographyComponent className={'pl4'} variant="h6">
						{ReportsConstants.OPEN_NEW_TAB}
					</TypographyComponent>
				</Grid>
			</MenuItem>
		</Grid>
         }
		 {(location.pathname === "/reports/comment")&&
		<Grid className={`pt-2 pb-2`} minWidth={'215px'}>
			<MenuItem component="a" href="https://pe-client-doc.s3.us-west-2.amazonaws.com/Comments+User+Guide.pdf" download="Comment User Guide" target="_blank"s className='pt-0 pb-0'>
				<Grid item display={'flex'} alignItems={'center'} gap={1.25} paddingTop={1.25} paddingBottom={1.25}>
					{downloadPdf()}
					<TypographyComponent variant="h6">
						{ReportsConstants.DOWNLOAD_PDF}
					</TypographyComponent>
				</Grid>
			</MenuItem>
			<MenuItem component="a" href="https://20709424.hs-sites.com/comment-analysis" target='_blank' className='pt-0 pb-0 mt4'>
				<Grid item display={'flex'} alignItems={'center'} gap={1.25} paddingTop={1.25} paddingBottom={1.25}>
					{openNewTab()}
					<TypographyComponent className={'pl4'} variant="h6">
						{ReportsConstants.OPEN_NEW_TAB}
					</TypographyComponent>
				</Grid>
			</MenuItem>
		</Grid>
         }
	</Popover>
	</>
	)
}
// default props
ReportsActions.defaultProps = {
	classes: {},
	match: {},
	location: {}
};

// prop types
ReportsActions.propTypes = {
	classes: PropTypes.object,
	match: PropTypes.object,
	location: PropTypes.object
};
export default withStyles(ReportsStyles)(ReportsActions);