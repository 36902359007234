/* eslint-disable quote-props */
/* eslint-disable dot-notation */
import React from "react";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import { useDispatch } from "react-redux";

//Image
import { Download } from "../../../../../assets/images/svgComponents/ImageComponent";


// Import Actions
import { excelAction, alertAction } from "../../../../../redux/actions";

// import constants
import { excelConstants } from "../../../../../config/constants";

function ConversionDownload (props) {
  const dispatch = useDispatch();

  // get props
  const { classes, metadataSelected } = props;

  // export conversion Data
  const conversionExport = (selectedSurvey) => {
    if (selectedSurvey && metadataSelected["key"]) {
      dispatch(excelAction.download({ "selectedMetadata": metadataSelected["key"], "excelType": excelConstants.CONVERSION_DOWNLOAD }));
    } else {
      dispatch(alertAction.error("Something went wrong"));
    }
  };

  return (
    <React.Fragment>
      <Tooltip arrow title={excelConstants.DOWNLOAD_CONVERSION}>
      <IconButton
        className={classes.demographyDialogBtn}
        onClick={(e) => conversionExport(e)}
        size="large">
        {Download()}
      </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}
// default props
ConversionDownload.defaultProps = {
  classes: {},
  metadataSelected: {}

};

// prop types
ConversionDownload.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object
};
export default ConversionDownload;
