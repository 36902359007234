/* eslint-disable prefer-const */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import PropTypes from "prop-types";
import Paper from "@mui/material/Paper";
import Badge from "@mui/material/Badge";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

// Import Actions
import { alertAction, clientAction, updateStateReducerAction, metadataAction, profileAction } from "../../redux/actions";

// Import Images
import IconLogo from "../../assets/images/svg/logo.svg";
import CreateIcon from "../../assets/images/svg/editprofile.svg";

// Import Helpers
import { getReducerUpdatedData } from "../../helpers";

// Import Container
import Container from "../Containers/Container";

// Import Components
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import SwitchComponent from "../../components/SwitchComponent/SwitchComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import SliderComponent from "../../components/SliderComponent";

// Import Constants
import { clientConstants, errorsConstants, surveySettingsConstants } from "../../config/constants";

// Import Reducer Contant
import { clientConstant } from "../../redux/constants";

// Import constants
// import { userRoles } from "../../config/pageAccess"

// Import Style
import ClientStyles from "./ClientStyles";
import "./client.css";

// Import History
import history from "../../config/history";

// Import To Get Page Access Details
import { userRoles } from "../../config/pageAccess";

function EditClient(props) {
  // Get Props
  const { classes, user } = props;
  // const rPage = redirectPage(user);

  // Define Dispatch
  const dispatch = useDispatch();

  /**
   * Fetch Client Data
   */
  useEffect(() => {
    dispatch(clientAction.getOne(() => {
      dispatch(clientAction.checkSurveys())
    }));
  }, [dispatch]);

  // Get Client By Id
  const industries = useSelector((state) => state.client.industries);
  const client = useSelector((state) => state.client.getOne);
  const { survey_threshold, prev_threshold_value, me_surveys } = useSelector((state) => state.client);
  const [openDialogue, setOpenDialogue] = useState(false)
  const [first, setFirst] = useState(false);
  const defaultThresholdValue = client && client.threshold ? client.threshold : surveySettingsConstants.SURVEY_THRESHOLD_MIN;


  // Alert when any of the survey threshold value is less than updated client threshold value
  if (survey_threshold === "failed" && !first) {
    setOpenDialogue(true)
    setFirst(true)
    // dispatch(clientAction.getOne());
  }

  /**
 * Handle form change
 */
  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, client);
    if (event.name === "managerEffectiveness" && me_surveys.length > 0 && event.value == false) {
      dispatch(alertAction.error("There are open Manager 360 survey(s). The Manager 360 surveys must be closed before the Manager 360 can be turned off."))
    } else {
      dispatch(updateStateReducerAction.update(clientConstant.CLIENTS_GET_ONE_UPDATE, reducerData));
    }
  };

  /**
 * Handle Reset on cancel click
 */
  const handleResetClient = () => {
    history.goBack()
  };


  const handleSurveyThreshold = (value) => {
    if (value) {
      handleChange({ name: "threshold", value })
    }
  }

  /**
   * Handle Form Submit
   */
  let handleSubmit = (e) => {
    dispatch(clientAction.update(client, () => {
      dispatch(profileAction.user(() => {
        dispatch(metadataAction.getClientMetadata(() => {
          if (!openDialogue) {
            history.goBack()
          }
        })
        );
      }));
    })
    );
  };

  const handleDialogClose = () => {
    setOpenDialogue(false)
    setFirst(false)
    dispatch(updateStateReducerAction.update(clientConstant.CLIENTS_SURVEY_THRESHOLD_UPDATE_SUCCESS, ""));
    handleChange({ name: "threshold", value: prev_threshold_value })
  }

  /**
     * Profile image change handler
     */
  let imageHandler = e => {
    const validExts = [".png", ".jpeg", ".jpg"];
    let fileExt = e.target.files[0].name;
    fileExt = fileExt.substring(fileExt.lastIndexOf('.'));

    if (validExts.indexOf(fileExt) < 0) {
      dispatch(alertAction.error(errorsConstants.invalidImageFormat))
      return false;
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.readyState === 2) {
          dispatch(updateStateReducerAction.update(clientConstant.CLIENTS_GET_ONE_UPDATE, {
            ...client,
            logo: reader.result
          }
          ));
        }
      };
      try {
        reader.readAsDataURL(e.target.files[0]);
      } catch (err) {
        // failed to read image
        console.error(err);
      }
    }
  };

  /**
  * Switch Toggles
  */
  let generateSwitches = (permissions) => {
    return permissions.map((data, index) => {
      return <Grid item xs={12} className="pb-1" key={`switch-${index}`}>
        <SwitchComponent item xs={12}
          name={data.name}
          color="primary"
          size="small"
          checked={client[data.name]}
          handleChange={(e) => { handleChange(e); }}
          label={
            <span>
              <TypographyComponent
                variant="h6"
              >{data.label}</TypographyComponent>
            </span>
          }
        />
      </Grid>;
    });
  };


  return (
    <React.Fragment>
      <Grid className={classes.page_title}>
        <TypographyComponent variant="h3"
          title={"Edit Client"}
        />
      </Grid>
      <Container page={"sub"} >
        <Grid className={"container mt-8"}>
          <Badge className={classes.client_profileBadge}
            overlap="circular"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right"
            }}
            badgeContent={<Grid className="image-upload">
              <label htmlFor="file-input">
                <img className={classes.profile_editicon} src={CreateIcon} alt="create" />
              </label>

              <input id="file-input" onChange={imageHandler} type="file" accept=".png,.jpeg,.jpg" />
            </Grid>}
          >
            {/* <img className={classes.imgStyle} alt="Travis Howard" src={client.logo} /> */}
          </Badge>
          <Paper className="borderRadius6" elevation={1}>
            <Box container p={5} >
              <ValidatorForm
                name="ClientNewEditForm"
                useref="form"
                autoComplete="off"
                onSubmit={handleSubmit}
                onError={(errors) => console.log(errors)}
              >
                <Grid container justifyContent="center">
                  <img src={client.logo ? client.logo : IconLogo} className={classes.Client_Profile} alt="avatar" />
                </Grid>

                <Grid container spacing={2} className="pt-3">
                  <Grid item xs={8}>
                    <Grid container spacing={3}>
                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          title={clientConstants.CLIENT_NAME}
                          variant={"h6"}
                        ></TypographyComponent>
                        <TextFieldComponent
                          id={"client_name"}
                          name={"name"}
                          size={"small"}
                          value={client.name}
                          margin={"none"}
                          handleChange={(e) => handleChange(e)}
                          validators={["required"]}
                          className="height36"
                        ></TextFieldComponent>
                      </Grid>

                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          title={clientConstants.SHARE_FILE}
                          variant={"h6"}
                        ></TypographyComponent>
                        <TextFieldComponent
                          id={"folder_name"}
                          size={"small"}
                          name={"sharefile"}
                          value={client.sharefile}
                          margin={"none"}
                          handleChange={(e) => handleChange(e)}
                          validators={["folderName"]}
                          className="height36"
                        ></TextFieldComponent>
                      </Grid>

                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          title={clientConstants.INDUSTRY}
                          variant={"h6"}
                        ></TypographyComponent>
                        <AutoCompleteComponent
                          name={"industry"}
                          suggestions={industries}
                          handleAutoCompolete={(event, name) => handleChange({ name, value: event.name })}
                          value={{ name: client.industry }}
                          validators={["required"]}
                          placeholder={"Select Industry"}
                          showErrorMessages={true}
                          className="height36"
                        />
                      </Grid>

                      <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                        <TypographyComponent
                          variant={"h6"}
                        >
                          {clientConstants.THRESHOLD}
                          <InformationComponent>
                            <TypographyComponent variant="tooltipbody">
                              {clientConstants.THRESHOLD_HELP}
                            </TypographyComponent>
                          </InformationComponent>

                        </TypographyComponent>
                        <SliderComponent
                          minValue={surveySettingsConstants.SURVEY_THRESHOLD_MIN_CLIENTPAGE}
                          maxValue={surveySettingsConstants.SURVEY_THRESHOLD_MAX}
                          defaultValue={surveySettingsConstants.SURVEY_THRESHOLD_MIN}
                          value={defaultThresholdValue}
                          onSelect={handleSurveyThreshold}
                          disable={user.role && user.role === "superadmin" ? false : true}
                        />
                        {/* <AutoCompleteComponent
                          name={"threshold"}
                          suggestions={clientConstants.THRESHOLDS()}
                          handleAutoCompolete={(event, name) => handleChange({ name, value: event })}
                          value={client.threshold.toString()}
                          validators={["required"]}
                          placeholder={"Select Threshold"}
                          showErrorMessages={true}
                          suggestionType={"array"}
                          className="height36"
                        />  */}
                      </Grid>

                      {user && user.role === userRoles.superadmin && client.transfloAccess &&
                        <React.Fragment>
                          <Grid item sm={12} className={`p-0 ${classes.hr}`}></Grid>

                          <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                            <TypographyComponent
                              title={clientConstants.RECIPIENT_ID}
                              variant={"h6"}
                            ></TypographyComponent>
                            <TextFieldComponent
                              id={"recepient_id"}
                              name={"transflo.recipient"}
                              size={"small"}
                              value={client.transflo.recipient}
                              margin={"none"}
                              handleChange={(e) => handleChange(e)}
                              validators={["required"]}
                              className="height36"
                            ></TextFieldComponent>
                          </Grid>

                          <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                            <TypographyComponent
                              title={clientConstants.AUTH_TOKEN}
                              variant={"h6"}
                            ></TypographyComponent>
                            <TextFieldComponent
                              id={"auth_token"}
                              size={"small"}
                              name={"transflo.auth"}
                              value={client.transflo.auth}
                              margin={"none"}
                              handleChange={(e) => handleChange(e)}
                              validators={["required"]}
                              className="height36"
                            ></TextFieldComponent>
                          </Grid>
                        </React.Fragment>
                      }

                      {user && user.role === userRoles.superadmin && client.samlssoAccess &&
                        <React.Fragment>
                          <Grid item sm={12} className={`p-0 ${classes.hr}`}></Grid>

                          <Grid item xs={12} lg={6} md={6} className="lg-pr-80">
                            <TypographyComponent
                              variant={"h6"}
                            >
                              {clientConstants.SSO_IDENTIFIER}
                              <InformationComponent>
                                <TypographyComponent variant="tooltipbody">
                                  {clientConstants.SSO_IDENTIFIER_HELP}
                                </TypographyComponent>
                              </InformationComponent>
                            </TypographyComponent>
                            <TextFieldComponent
                              id={"attributeName"}
                              name={"saml.attributeName"}
                              size={"small"}
                              disabled={client.disableSamlField}
                              value={client.saml.attributeName}
                              margin={"none"}
                              handleChange={(e) => handleChange(e)}
                              validators={[]}
                              className="height36"
                            ></TextFieldComponent>
                          </Grid>
                        </React.Fragment>
                      }
                    </Grid>
                  </Grid>
                  {user && user.role === userRoles.superadmin &&
                    <Grid item xs={4} className="lg-pl-30">
                      <Grid container justifyContent='center'>
                        <Grid item xs={12} className="bgGrey  p-3">
                          <TypographyComponent
                            title={"Enable Permission"}
                            variant={"h4"}
                            className="txtBold pb-2"
                          />
                          <Grid>
                            {generateSwitches(clientConstants.PERMISSIONS)}
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  }
                  <Grid item xs={12} className={"mt-2"}>
                    <Grid container justifyContent={"flex-end"}>
                      <Grid item>
                        <ButtonComponent
                          title={"Save"}
                          color={"primary"}
                          size={"small"}
                          type="submit"
                        ></ButtonComponent>
                        <ButtonComponent
                          title={"Cancel"}
                          color={"default"}
                          size={"small"}
                          pageClassName="ml-1"
                          handleClick={handleResetClient}
                        ></ButtonComponent>
                      </Grid>
                    </Grid>
                  </Grid>

                </Grid>
              </ValidatorForm>
            </Box>
          </Paper>
          <Dialog
            open={openDialogue}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className="p-2"
          >
            <DialogContent style={{padding: "30px 34px"}}>
              {clientConstants.THRESHOLD_VALIDATION}
            </DialogContent>
            <DialogActions>
              <ButtonComponent
                title={"Ok"}
                color={"primary"}
                size={"small"}
                handleClick={handleDialogClose}
                componentClassName={"marginLR5"}
              ></ButtonComponent>
            </DialogActions>
          </Dialog>
        </Grid>
      </Container>
    </React.Fragment>
  );
}

// default props
EditClient.defaultProps = {
  classes: {},
  user: {}
};

// prop types
EditClient.propTypes = {
  classes: PropTypes.object,
  user: PropTypes.object
};

export default withStyles(ClientStyles)(EditClient);
