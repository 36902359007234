/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';

// Import Styles and Image
import SpCallerStyles from "./SpCallerStyles";

// Import Component
import Container from "../Containers/Container";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import InformationComponent from "../../components/InformationComponent/InformationComponent";
import SearchInputComponent from "../../components/SearchInputCompoment/SearchInputComponent";
import TableComponent from "../../components/TableComponent/TableComponent";
import OptionsMenu from "./OptionsMenu";
import SelectColumn from "./SelectColumn";
import MetricsDialog from "./MetricsDialog";

// import constants
import { SpcallerConstants, excelConstants } from "../../config/constants";
import { getTableHeight, getTableRowSize } from "../../config/layout";

// Import History
import history from "../../config/history";

// Import Actions
import { spCallerAction, excelAction } from "../../redux/actions";

// Import helper
import { getQueryVariable, isNineToNine } from "../../helpers";

function SurveyQueue(props) {
  // get props
  const { classes, location } = props;
  const locationState = location.state || {};

  // Define Dispatch
  const dispatch = useDispatch();
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();

  // sidebar column select
  const [drawer, setDrawer] = useState(false);
  // metric popup
  const [metricPopup, setMetricPopup] = useState({ open: false, title: "" });

  // for global search text box
  const [globalSearchKey, setGlobalSearchKey] = useState("");

  // Get Survey Queue List
  const { loading } = useSelector(state => state.UI);
  const { survey_queue } = useSelector(state => state.spCaller);
  const { data, page } = survey_queue;
  let isPageReset = getQueryVariable(props.location.search, "reset") === "true";
  isPageReset = locationState.isPageReset !== undefined ? locationState.isPageReset : isPageReset

  useEffect(() => {
    if (isPageReset && !survey_queue.loading) {
      dispatch(spCallerAction.getSurveyQueue(1, tableRowSize, "zero_attempts", "desc", [], []));
      history.replace(props.location.pathname);
    }
  }, [isPageReset]);

  /**
   * Get Survey Queue List
   */
  useEffect(() => {
    if (!isPageReset && !survey_queue.loading) {
      dispatch(spCallerAction.getSurveyQueue(1, tableRowSize, "zero_attempts", "desc", [], [], [], {
        ...locationState, defaultCall: false
      }));
    }
  }, [dispatch]);

  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(spCallerAction.getSurveyQueue(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  /**
     * Handle Table Sort Request Event
     */
  const onSortRequest = (sortBy, sort) => {
    dispatch(spCallerAction.getSurveyQueue(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  /**
     * Handle Table Search Input Change Event
     */
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }
    dispatch(spCallerAction.getSurveyQueue(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
  };

  /**
     * Clear Search Text By Field
     * @param {*} searchField
     * @param {*} searchKey
     */
  const handleClearSearchByField = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    sValues[sFields.indexOf(searchField)] = searchKey;
    dispatch(spCallerAction.getSurveyQueue(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
  };

  /**
    * Handle Table Navigation Event
    */
  const handleNavigateAction = (id, field, item) => {
    if (isNineToNine()) {
      history.push({
        pathname: `/spcaller/caller-queue/nine-to-nine`,
        state: {
          from: "nine-to-nine",
          isPageReset: true,
          client_id: item.client._id,
          gt_survey_id: id,
          defaultCall: true,
          survey_alone: true
        }
      });
    } else {
      history.push({
        pathname: `/spcaller/caller-queue/${id}`,
        state: {
          isPageReset: true,
          client_id: item.client._id,
          // survey_alone: true
        }
      });
    }
  };

  /**
   * Download Excel
   */
  const excelDownload = (downloadType, formData) => {
    dispatch(excelAction.download({
      ...formData,
      excelType: downloadType
    }));
  };

  /**
   * Callback method when menu is clicked
   * @param {*} menu selected menu name
   */
  const menuOnClick = (menu) => {
    switch (menu) {
      case SpcallerConstants.SELECT_COLUMN:
        setDrawer(true);
        break;
      case SpcallerConstants.GLOBAL_QUEUE:
        history.replace({
          pathname: `/spcaller/caller-queue/global-search`,
          state: {
            from: 'global-caller-queue',
            isGlobalCallerQueue: 'global-caller-queue'
          }
        });
        break;
      case SpcallerConstants.CALLER_METRICS:
        setMetricPopup({ open: true, title: "Caller Metrics Download", type: excelConstants.CALLER_METRIC });
        break;
      case SpcallerConstants.SURVEY_METRICS:
        setMetricPopup({ open: true, title: "Survey Metrics Download", type: excelConstants.SURVEY_METRIC });
        break;
      default:
    }
  }

  /**
   * method call back when sidebar closes
   * @param {*} drawerState sidebar toggle state
   */
  const toggleDrawer = (drawerState) => {
    setDrawer(drawerState);
  }

  /**
   * callback method when column selected / deselected
   * @param {*} updatedVisibleColumns updated visible ccolumns
   */
  const onSelectColumnChange = (updatedVisibleColumns) => {
    const { sFields, sValues } = page;
    dispatch(spCallerAction.getSurveyQueue(1, tableRowSize, page.sortBy, page.sort, sFields, sValues, updatedVisibleColumns));
  }

  const gotoGlobalSearch = (needSearch = true, title, extraParam = {}) => {
    if (globalSearchKey.trim().length > 0 || !needSearch) {
      history.replace({
        pathname: `/spcaller/caller-queue/global-search`,
        state: {
          from: 'global-search',
          globalSearchKey,
          title,
          ...extraParam
          // isPageReset: true
        }
      });
    }
  }

  return (
    <React.Fragment>
      <Container align={"baseline"}>
        <Grid 
          container
          spacing={2}
        >
          <Grid item xs={12}>
            <Grid container justifyContent="space-between" alignItems="center">
              <Grid>
                <Grid container alignItems="center">
                  <TypographyComponent variant="h3" className="ml-1">{SpcallerConstants.SURVEY_QUEUE}
                  </TypographyComponent>
                </Grid>
              </Grid>
              <Grid>
                <Grid container alignItems="center">
                  {page && page.sFields && page.sFields.length > 0 &&
                    <ButtonComponent
                      form="clear-table-search"
                      title={"Clear all"}
                      color={"primary"}
                      size={"small"}
                      type="submit"
                      variant={"text"}
                      pageClassName="mr-1"
                      handleClick={() => {
                        history.replace(`${props.location.pathname}?reset=true`);
                      }}
                    />
                  }
                  <ButtonComponent
                    size="small"
                    color="primary"
                    title={SpcallerConstants.ASSIGNED_CALLS}
                    pageClassName="mr-1"
                    handleClick={() => {
                      history.replace({
                        pathname: `/spcaller/caller-queue/assigned-calls?reset=true`,
                        state: {
                          from: "assigned-calls",
                          isPageReset: true
                        }
                      });
                    }}
                  />
                  <Grid item className="mr-1">
                    <Grid container className={`${classes.AssignedServeySearch}`}>
                      <SearchInputComponent
                        name={globalSearchKey}
                        placeholder={"Search"}
                        fullWidth={false}
                        value={globalSearchKey}
                        onInputChangeRequest={(name, value) => {
                          setGlobalSearchKey(value)
                        }}
                      />
                      <ButtonComponent
                        size="small"
                        title={SpcallerConstants.SEARCH}
                        pageClassName="buttonOrange"
                        color="primary"
                        handleClick={() => gotoGlobalSearch(true, null)}
                      />
                    </Grid>
                  </Grid>
                  <OptionsMenu
                    classes={classes}
                    menus={[
                      SpcallerConstants.SELECT_COLUMN,
                      SpcallerConstants.GLOBAL_QUEUE,
                      SpcallerConstants.SURVEY_METRICS,
                      SpcallerConstants.CALLER_METRICS
                    ]}
                    menuOnClick={menuOnClick}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid item sm={12}>
            <TableComponent
              id="survey-queue-table"
              data={data}
              header={page.fieldFilters.headerCols}
              field={page.fieldFilters.fieldCols}
              search={page.fieldFilters.searchCols}
              height={tableHeight}
              page={page.skip}
              count={page.count}
              rowsPerPage={page.limit}
              links={[page.fieldFilters.fieldCols[1]]}
              sort={page.sort}
              sortBy={page.sortBy}
              sFields={page.sFields}
              sValues={page.sValues}
              paginationVisible={!page.lastPage}
              handleNavigateAction={handleNavigateAction}
              onChangePagination={onChangePagination}
              onSortRequest={onSortRequest}
              onInputChangeRequest={onInputChangeRequest}
              handleClearSearchByField={handleClearSearchByField}
              resetSearchText={isPageReset}
              customAriaLabel={(item, field, index) => {
                switch(index) {
                  case 1: return `Navigate to ${item.name}`
                  default: return "Navigation Link"
                }
              }}
              isLoading={loading}
              noDataErrorMessage={loading ? "Loading Survey Queue" : "No Records Found"}
            />
          </Grid>

          <SelectColumn
            classes={classes}
            open={drawer}
            page={page}
            toggleDrawer={toggleDrawer}
            onSelectColumnChange={onSelectColumnChange}
          />

          <MetricsDialog
            {...metricPopup}
            classes={classes}
            onDownload={(datesSeleted) => {
              excelDownload(metricPopup.type, datesSeleted);
              setMetricPopup({ open: false, title: "" });
            }}
            onCancel={() => {
              setMetricPopup({ open: false, title: "" });
            }}
          />

        </Grid>
      </Container>
    </React.Fragment>
  );
}
// default props
SurveyQueue.defaultProps = {
  classes: {},
  handlePopoverClick: () => { }
};

// prop types
SurveyQueue.propTypes = {
  classes: PropTypes.object,
  handlePopoverClick: PropTypes.func
};
export default withStyles(SpCallerStyles)(SurveyQueue);
