import React, { useState, useMemo } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import Popover from "@mui/material/Popover";
import TableSortLabel from "@mui/material/TableSortLabel";
import MenuItem from "@mui/material/MenuItem";
import { TextField, ListSubheader } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import Select from "@mui/material/Select";
import FormControl from '@mui/material/FormControl';
import ListItem from "@mui/material/ListItem";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Tooltip from "@mui/material/Tooltip";

// Import Loadsh
import _ from "lodash";

//import images
import { ArrowBlue, ArrowOrange, keyDriver, Filter } from "../../../../assets/images/svgComponents/ImageComponent";

/* import HighRateArrowImg from "../../../../assets/images/svg/dashboard_ratedarrowblue.svg";
import LowRateArrowImg from "../../../../assets/images/svg/dashboard_ratedarroworange.svg"; */

// Import Styles
import HighLevelSummaryTableStyle from "./HighLevelSummaryTableStyle";

// import constants
import { ReportsConstants, surveyTypes } from "../../../../config/constants";

// Import helpers
import { generateKey } from "../../../../helpers";


// Import Component
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import MultiSelectChecboxWithSearch from "../../../../components/MultiSelectComponent/MultiSelectCheckboxWithSearch";

function HighLevelSummaryTable(props) {
  const {
    height,
    classes,
    HLSdata,
    selectedValue,
    sort,
    sortBy,
    updateSelectedValue,
    selectedRgidata,
    historical,
    historicalSurvey,
    surveynames,
    showPagination,
    isRightTable,
    isLeftTable,
    keydriver,
    summaryTableColumns,
    filtersApplied,
    applyFilters,
  } = props;
  let isKeyDriver = keydriver && keydriver.length > 0
  let selectValue = isKeyDriver ? selectedValue : (selectedValue === "keyDrivers" ? "question" : selectedValue)
  let showCategory = false;
  let showDropdown = false;

  let data = HLSdata?.['hlsData']?.['category'];
  let meandata = HLSdata?.['hcData']?.['category'];
  let field = ["keytext", "participantCount", "mean", "percentfavorable", "companyComparison", "historicalData"];
  let headerMapFields = {
    "Key Driver": "keyDriverValue",
    "category": "categoryName",
    "N": "participantCount",
    "Mean": "mean",
    "% Favorable": "percentfavorable",
    "Comparison to company": "companyComparison"
  }
  let showdemographic = false;
  let data2 = HLSdata.hlsData.demographic;
  let selectedRgi = selectedRgidata
  if (!data2[selectedRgi]) {
    selectedRgi = Object.keys(data2)[0]
  }


  if (selectValue === "question" || selectValue === "keyDrivers") {
    data = HLSdata?.['hlsData']?.['question'];
    meandata = HLSdata?.['hcData']?.['question'];
    if (isKeyDriver) {
      field = ["questionText", "keyDriverValue", "categoryName", "participantCount", "mean", "percentfavorable", "companyComparison", "historicalData"];
    } else {
      field = ["questionText", "categoryName", "participantCount", "mean", "percentfavorable", "companyComparison", "historicalData"];
    }
    showdemographic = false;
    showCategory = true;
    showDropdown = false;
  }
  else if (selectValue === "category") {
    data = HLSdata.hlsData.category;
    meandata = HLSdata.hcData.category;
    field = ["keytext", "participantCount", "mean", "percentfavorable", "companyComparison", "historicalData"];
    showdemographic = false;
    showCategory = false;
    showDropdown = false;
  }
  else if (HLSdata && selectValue === "demographic") {
    meandata = HLSdata.hcData.demographic;
    field = ["keytext", "participantCount", "mean", "percentfavorable", "companyComparison", "historicalData"];
    showdemographic = true;
    showCategory = false;
    showDropdown = true;
  }

  // do not filter out the first column in summary widget
  field = field.filter((item) => {
    if (item === "questionText" || item === "keytext") {
      return true;
    } else {
      return summaryTableColumns.includes(item) ? true : false;
    }
  });

  // remove headers which are not selected in the customize column popup
  let tableHeaders = ReportsConstants.HLS_TABLE_HEADERS;
  tableHeaders = tableHeaders.filter((item) =>
    summaryTableColumns.includes(headerMapFields[item])
  );

  const useStyles = makeStyles({
    paper: {
      minWidth: 190
    },
    MuiListSubheader: {
      color: "#606060"
    },
  });

  const classesmenu = useStyles();

  // Data for 'view by category' and 'view by question'
  let datatab2 = Object.values(data);

  // Data from 'view by demographics'
  let datatab3 = null

  // custom demographics sort function
  const demographicsSortFunction = (item) =>{
    if(ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedRgi]){
      return ReportsConstants.HIGH_LEVEL_SUMMARY_CHRONOLOGICAL_PRIORITY[selectedRgi][item.keytext]
    }
    else{
      return 0
    }
  }

  // Data from view by demographics dropdown
  let datatab1 = null;
  if (showdemographic === true) {
    datatab1 = Object.keys(data2).map(data => {
            return HLSdata.metadataKeyname[data]
    }).sort((a, b) => a > b ? 1 : -1);
  }

  // Data for historical dropdown
  let surveydata = surveynames;
  surveydata = surveydata.filter((s) => s.type !== surveyTypes.MULTI_RATER_FEEDBACK)
  const [surveySearchName, setSurveySearchName] = useState('');
  const [hlsSurveyData, setHlsSurveyData] = useState(surveydata);
  const [selectedSurvey, setSelectedSurvey] = useState([]);

  useMemo(() => {
    if (surveySearchName !== '') {
      const cleanedSearchName = surveySearchName.toLowerCase();
      setSurveySearchName(surveySearchName);

      const selectedSurveyIndex = surveydata.findIndex(s => s.name.toLowerCase().indexOf(cleanedSearchName) !== -1 && s.name === historicalSurvey);
      const selectedSurveyLocal = (historicalSurvey !== '' && selectedSurveyIndex >= 0) ? [surveydata?.[selectedSurveyIndex]] : [];
      setSelectedSurvey(selectedSurveyLocal);
      
      const filteredData = surveydata.filter((s) => s.name.toLowerCase().indexOf(cleanedSearchName) !== -1 && s.name !== historicalSurvey);
      setHlsSurveyData(filteredData);
    } else {
      setHlsSurveyData(surveydata);
    }
  }, [surveySearchName]);

  let selectHistorical = historical
  if (!historical) {
    selectHistorical = historicalSurvey
  }

  // Historical date handle change
  const onChangeDate = (e => {
    if (ReportsConstants.HISTORICAL_DATES.indexOf(e) > -1) {
      props.onChangeDateValue(e)
    }
    else {
      const foundItem = surveydata.find(item => item['name'] === e);
      props.onChangeSurveyvalue(foundItem)
    }

  })
  // To show menuitems for view by demographics
  const onChangeDemographics = (e => {
    props.updateSelectedrg(HLSdata.metadataDispname[e])
    datatab3 = data2[selectedRgi]

    applyFilters(
      () => {},
      'hls',
      {}
    )
  })

  /**
     * Handle Sorting
     */
  const handleSorting = property => event => {
    let sortValue = "desc";
    if (sortBy === property && sort === "desc") {
      sortValue = "asc";
    }
    props.onSortRequest(property, sortValue);
  };

  //Max characters restriction
  const addEllipsis1 = (input, charCount) => {
    if (input && input.length > charCount) {
      return input.substring(0, charCount) + "...";
    }
    return input;
  };

  // Filter Option Anchors
  const [viewByAnchor, setViewByAnchor] = useState(null);
  const [categoryAnchor, setCategoryAnchor] = useState(null);

  const parseDataValue = (type => {
    const hlsBaseQuesIds = Object.keys(HLSdata?.['hlsData']?.['question'] || {}) || [];
    const hlsDataDemographicParsedValue = HLSdata?.['hlsData']?.['demographic']?.[selectedRgi];
    const hlsDataDemographic = Boolean(hlsDataDemographicParsedValue && _.isObject(hlsDataDemographicParsedValue) && Object.keys(hlsDataDemographicParsedValue).length > 0);
    
    let categoryData = Boolean(HLSdata?.['hlsData']?.['category'] && _.isObject(HLSdata?.['hlsData']?.['category'])) ?
      _.sortBy(Object.keys(HLSdata?.['hlsData']?.['category'])) :
      Boolean(HLSdata?.['hcData']?.['category'] && _.isObject(HLSdata?.['hcData']?.['category'])) ?
      _.sortBy(Object.keys(HLSdata?.['hcData']?.['category'])) :
      [];
  
    if (selectedValue === 'keyDrivers') {
      const keyDriverData = _.sortBy(keydriver.filter(curr => curr?.['baseQusId'] && hlsBaseQuesIds.includes(curr?.['baseQusId'])).map(curr => curr?.['category']));
      categoryData = keydriver && JSON.stringify(keyDriverData) !== '[]' ? keyDriverData : categoryData;
    }
  
    const parseData = {
      'question': _.sortBy(HLSdata?.['hlsData']?.['question'], 'questionText'), // Sort questions alphabetically by 'questionText'
      'category': categoryData,
      'keyDrivers':
        _.sortBy((keydriver || [])).reduce((acc, curr, index) => {
          if (curr?.['baseQusId'] && hlsBaseQuesIds.indexOf(curr?.['baseQusId']) !== -1) {
            acc = [...acc, curr]
          }
          return acc;
        }, []).sort((a, b) => a.question.localeCompare(b.question)), // Sort questions alphabetically
      'demographic':
        hlsDataDemographic ?
          _.chain(hlsDataDemographicParsedValue)
            .sortBy([value => demographicsSortFunction({...value}), 'keytext'])
            .map('keytext')
            .value() : []
    }
  
    return parseData?.[type] || null;
  });

  const parseDataCondition = ((type, data) => {
    const parseCondition = {
      'question': data && _.isObject(data) && Object.keys(data).length > 0,
      'category': data && _.isArray(data) && data.length > 0,
      'keyDrivers': data && _.isArray(data) && data.length > 0,
      'demographic': data && _.isObject(data) && Object.keys(data).length > 0,
    }

    return parseCondition?.[type] || null;
  });

  const getInitialFilteredData = (viewByType => {
    const parsedData = parseDataValue(viewByType);
    const parsedCondition = parseDataCondition(viewByType, parsedData);

    if (parsedData === null || (parsedCondition === null || !parsedCondition)) {
      return {};
    }

    if (viewByType === 'question') {
      return Object.values(parsedData).reduce((acc, curr, index) => {
        if (curr?.['keytext'] && curr?.['questionText']) {
          acc = {...acc, [generateKey(curr?.['questionText'])]: curr?.['questionText']};
        }

        return acc;
      }, {});
    }

    if (viewByType === 'category' || viewByType === 'demographic') {
      return parsedData.reduce((acc, curr, index) => {
        if (curr) {
          return {...acc, [generateKey(curr)]: curr};
        }
      }, {});
    }

    if (viewByType === 'keyDrivers') {
      return parsedData.reduce((acc, curr, index) => {
        if (curr?.['baseQusId'] && curr?.['question']) {
          return {...acc, [generateKey(curr?.['question'])]: curr?.['question']};
        }
      }, {});
    }
  });

  const getSelectedFilterData = (viewByType => {
    const parsedData = parseDataValue(viewByType);
    const parsedCondition = parseDataCondition(viewByType, parsedData);
    // const viewByTypeBasedOnFiltersApplied = (['question', 'keyDrivers'].indexOf(viewByType) !== -1) ?
    //   filtersApplied?.['question'] ? 'question' : 'category'
    // : viewByType;
    
    const filtersAppliedCondtion = Boolean(
      filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 && 
      filtersApplied?.[viewByType] && _.isArray(filtersApplied?.[viewByType]) && filtersApplied?.[viewByType].length > 0
    );

    if (parsedData === null || (parsedCondition === null || !parsedCondition) || !filtersAppliedCondtion) {
      return [];
    }

    if (viewByType === 'question' || viewByType === 'keyDrivers') {
      return Object.values(parsedData).reduce((acc, curr, index) => {
        const dynamicQuestionName = viewByType === 'keyDrivers' && isKeyDriver ? 'question' : 'questionText';
        const dynamicCategoryName = viewByType === 'keyDrivers' && isKeyDriver ? 'category' : 'categoryName';
        const conditionBasedOnFilters = filtersApplied?.['question'] || filtersApplied?.['keyDrivers'] ?
          curr?.[dynamicQuestionName] && (filtersApplied?.['question'] || filtersApplied?.['keyDrivers'] || []).indexOf(curr?.[dynamicQuestionName]) !== -1 :
          curr?.[dynamicCategoryName] && (filtersApplied?.['category'] || []).indexOf(curr?.[dynamicCategoryName]) !== -1;
        const currentKey = generateKey(curr?.[dynamicQuestionName]);

        if (conditionBasedOnFilters && (acc || []).indexOf(currentKey) === -1) {
          acc = [...acc, currentKey];
        }

        return acc;
      }, []);
    }

    if (viewByType === 'category' || viewByType === 'demographic') {
      return parsedData.reduce((acc, curr, index) => {
        const currentKey = generateKey(curr);

        if (curr && (filtersApplied?.[viewByType] || []).indexOf(curr) !== -1 && (acc || []).indexOf(currentKey) === -1) {
          acc = [...acc, currentKey];
        }

        return acc;
      }, []);
    }

    return [];
  });

  const applyFilterBtn = ((triggerType, data) => {
    const trigType = triggerType === 'subCategory' ? 'category' : triggerType;
    const filterDataBasedOnType = getInitialFilteredData(trigType);
    const isAllSelected = _.isEqual(_.orderBy(Object.keys(filterDataBasedOnType || {})), _.orderBy((data || [])));
    let reduxData = {};

    if (!isAllSelected) {
      const triggerTypeData = (data || []).reduce((acc, curr, index) => {
        if (filterDataBasedOnType?.[curr]) {
          acc = [...acc, filterDataBasedOnType?.[curr]];
        }

        return acc;
      }, []);

      reduxData = (['question', 'keyDrivers'].indexOf(selectValue) !== -1) ?
      {
        [trigType]: triggerTypeData
      } : {
        [selectValue]: triggerTypeData
      };
    }

    applyFilters(
      () => {
        triggerType !== 'subCategory' ?
          setViewByAnchor(null) :
          setCategoryAnchor(null)
      },
      'hls',
      reduxData
    )
  });

  /**
  * Table Header Row
  */
  const tableHeaderRow = () => {
    let hls_selectFields = ReportsConstants.VIEW_BY_NAME
    if (!isKeyDriver) {
      hls_selectFields = hls_selectFields.filter((k) => k.value !== "keyDrivers")
    }
    return (

      <TableRow className={classes.tableheadercolor}>
        <TableCell width="35%">
          {/* view by - Filter Icon with tooltip */}
          <Tooltip arrow title={`${filtersApplied?.[selectValue] ? 'Filters Applied' : 'Apply Filters'}`}>
            <IconButton
              className={`icon-hover ${filtersApplied?.[selectValue] ? 'active' : ''}`}
              aria-describedby={'hlsViewByPopover'}
              onClick={(e) => setViewByAnchor(e.currentTarget)}
            >
              {Filter()}
            </IconButton>
          </Tooltip>
          {/* view by - Filter Icon popover */}
          <Popover
            id={'hlsViewByPopover'}
            open={Boolean(viewByAnchor)}
            anchorEl={viewByAnchor}
            onClose={() => {setViewByAnchor(null)}}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
          >
            <MultiSelectChecboxWithSearch
              checkboxData={getInitialFilteredData(selectValue)}
              defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
              handleApply={applyFilterBtn}
              handleCancel={() => {setViewByAnchor(null)}}
              triggerType={selectValue}
              selectedCheckboxDatas={getSelectedFilterData(selectValue)}
            />
          </Popover>
          <Select
            className={`${classes.HLSselectMenu} txtSemiBold fontMontserrat hls`}
            value={isKeyDriver ? selectValue : (selectValue === "keyDrivers" ? "question" : selectValue)}
            disableUnderline={true}
            variant="standard"
            onChange={(e) => {
              updateSelectedValue(e.target.value, field)
            }}
          >
            {hls_selectFields.map((item, keyIndex) => (
              <MenuItem
                key={keyIndex}
                value={item.value}
              >
                {item.name}
              </MenuItem>
            ))}
          </Select>
          {selectValue === "keyDrivers" &&
            <InformationComponent>
              <TypographyComponent variant="tooltipbody">
                Items marked with a blue key refer to your top engagement
                drivers based on key driver analysis results for the entire engagement survey.
                <a
                  href="https://20709424.hs-sites.com/understanding-key-drivers"
                  target="_blank"
                  style={{ color: "#54c0fd" }}
                > Click here</a> to learn more.
              </TypographyComponent>
            </InformationComponent>
          }
          {showDropdown &&
            <Select
              className={`${classes.HLSselectMenu} txtSemiBold fontMontserrat hls`}
              variant={"standard"}
              value={HLSdata.metadataKeyname[selectedRgi]}
              disableUnderline={true}
              onChange={(e) => onChangeDemographics(e.target.value)}
            >
              {datatab1.map((item, keyIndex) => (
                <MenuItem key={keyIndex} value={item} >{item}</MenuItem>
              ))}

            </Select>
          }

          <TableSortLabel
            active={sortBy === ((selectValue === "question" || selectValue === "keyDrivers") ? "questionText" : "keytext")}
            direction={sort}
            onClick={handleSorting((selectValue === "question" || selectValue === "keyDrivers") ? "questionText" : "keytext")}
          />
        </TableCell>
        {summaryTableColumns.includes("keyDriverValue") &&
          isKeyDriver && (selectValue === "question" || selectValue === "keyDrivers") &&
          <TableCell
            className="fontMontserrat fontSize13 txtSemiBold pl-15"
            onClick={handleSorting('corelation')}
          >
            <div>
              <TableSortLabel
                active={sortBy === "keyDriverValue"}
                direction={sort}
              >Key Driver</TableSortLabel>
            </div>
          </TableCell>
        }

        {showCategory && summaryTableColumns.includes("categoryName") &&
          <TableCell
            width="25%"
            className="fontMontserrat fontSize13 txtSemiBold pl-2"
          >
            {/* category - Filter Icon with tooltip */}
            <Tooltip arrow title={`${filtersApplied?.['category'] ? 'Filters Applied' : 'Apply Filters'}`}>
              <IconButton
                className={`icon-hover ${filtersApplied?.['category'] ? 'active' : ''}`}
                aria-describedby={'hlsCategory'}
                onClick={(e) => setCategoryAnchor(e.currentTarget)}
              >
                {Filter()}
              </IconButton>
            </Tooltip>
            {/* category - Filter Icon popover */}
            <Popover
              id={'hlsCategoryPopover'}
              open={Boolean(categoryAnchor)}
              anchorEl={categoryAnchor}
              onClose={() => {setCategoryAnchor(null);}}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
            >
              <MultiSelectChecboxWithSearch
                checkboxData={getInitialFilteredData('category')}
                handleApply={applyFilterBtn}
                defaultSelectAll={filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length > 0 ? false : true}
                handleCancel={() => {setCategoryAnchor(null)}}
                triggerType={'subCategory'}
                selectedCheckboxDatas={getSelectedFilterData('category')}
              />
            </Popover>
            <TableSortLabel
              active={sortBy === 'categoryName'}
              direction={sort}
              onClick={handleSorting('categoryName')}
            >Category</TableSortLabel>
          </TableCell>
        }
        {tableHeaders.map((item, k) => (
          item === "Comparison to company"
            ?
            <TableCell key={k} width="20%" className="txtcenter fontSize13 txtSemiBold fontMontserrat pl-3"
            onClick={handleSorting(headerMapFields[item])}>
              <TableSortLabel
                active={sortBy === headerMapFields[item]}
                direction={sort}
              >
                {item}  
                <InformationComponent >
                      {ReportsConstants.COMPARISON_TO_COMP_INFO}
                </InformationComponent>
              </TableSortLabel>
            </TableCell>
            :
            <TableCell key={k} width={item == "% Favorable" ? "12%" : "7%"} style={{ minWidth: "50px !important" }} className="txtcenter fontSize13 txtSemiBold fontMontserrat pl-3"                 
            onClick={handleSorting(headerMapFields[item])}>
              <TableSortLabel
                active={sortBy === headerMapFields[item]}
                direction={sort}
              >
                {item}
              </TableSortLabel>
            </TableCell>
        ))}
        {summaryTableColumns.includes("historicalData") && 
        <TableCell className="txtcenter justifycenter datecomp">
          <Grid className="dflex justifycenter">
            <FormControl
              style={{ maxWidth: "160px" }}>
              <Tooltip arrow title={selectHistorical} placement="bottom-start"
                PopperProps={{
                  disablePortal: true,
                  popperOptions: {
                    positionFixed: true,
                    modifiers: {
                      preventOverflow: {
                        enabled: true,
                        boundariesElement: 'window'
                      }
                    }
                  }
                }}>
                <Select
                  value={selectHistorical}
                  className={`txtSemiBold fontMontserrat hls`}
                  variant={"standard"}
                  disableUnderline={true}
                  onClose={() => {setHlsSurveyData(surveydata);setSurveySearchName('')}}
                  onChange={(e) => onChangeDate(e.target.value)}
                >
                  <ListSubheader disableSticky="true" className={classesmenu.MuiListSubheader}>Date Range</ListSubheader>
                  {ReportsConstants.HISTORICAL_DATES.map((item, keyIndex) => (
                    <MenuItem
                      className={`${classesmenu.paper} ml-2`}
                      key={keyIndex}
                      value={item}
                      disabled={item === historical ? true : false}
                    >
                      {item}
                    </MenuItem>
                  ))}
                  <ListSubheader disableSticky="true" className={classesmenu.MuiListSubheader} sx={{display: 'flex', alignItems: 'center'}}>
                    Select Survey
                    <TextField
                      variant="outlined"
                      value={surveySearchName}
                      placeholder="Type to search"
                      className={`${"height28 pl-2 m-0"}`}
                      onChange= {(e) => {setSurveySearchName(e.target.value)}}
                      onKeyDown={e => e.stopPropagation()}
                    />
                  </ListSubheader>
                  {
                    (surveySearchName && selectedSurvey.length === 1) &&
                      <MenuItem
                        className={`ml-2`}
                        key={selectedSurvey[0]?._id}
                        value={selectedSurvey[0]?.name}
                        disabled={true}
                      >
                        {selectedSurvey[0]?.name}
                      </MenuItem>
                  }
                  {
                    hlsSurveyData.length > 0 &&
                      hlsSurveyData.map((item, keyIndex) => (
                        <MenuItem
                          className={`ml-2`}
                          key={item?._id}
                          value={item?.name}
                          disabled={item?.name === historicalSurvey}
                        >
                          {item?.name}
                        </MenuItem>
                      ))
                  }
                  {(selectedSurvey.length === 0 && hlsSurveyData.length === 0) && <TypographyComponent variant={'h6'} title={"No Search Results Found"} className={`${classes.hlsSurveySearchError} ml-2`} color={'#B1B2B3'} />}
                </Select>
              </Tooltip>
            </FormControl>

            <InformationComponent className={`${classes.TableHelpIcon} mt-15`}>
              {ReportsConstants.HLS_INFO}
            </InformationComponent>
            <Grid className={`pl5 mt5`}>
              <TableSortLabel
                style={{width:"20px"}}
                active={sortBy === "historicalData"}
                direction={sort}
                onClick={handleSorting("historicalData")}
              />
            </Grid>
          </Grid>
        </TableCell>}

      </TableRow >

    );
  };

  /**
      * Table Data Rows
      */

  const tableDataRow = () => {
    if (showdemographic) {
      datatab2 = datatab3
    }
    else {
      datatab2 = Object.values(data);
    }

    if (datatab2) {
      datatab2 = datatab2
    }
    else if (data2[selectedRgi]) {
      datatab2 = Object.values(data2[selectedRgi])
    }
    else {
      if (data2[selectedRgi])
        datatab2 = Object.values(data2[selectedRgi]);
      else
        datatab2 = []
    }

    let keyDriverIds = keydriver.map((k) => k.baseQusId)

    // Calculate Historical Data
    datatab2 = datatab2.map(item => {
      let keyDriverValue = 0
      let corelation = 0
      if (selectValue === "question" || selectValue === "keyDrivers") {
        keydriver.map((k) => {
          if (k.baseQusId === item.keytext) {
            keyDriverValue = 1
            corelation = k.corelation
          }
        })

      }
      let mean = null;
      if (meandata) {
        if (showdemographic && Boolean(meandata?.[selectedRgi]?.[item.keytext])) {
          mean = Math.round(item.percentfavorable - meandata[selectedRgi][item.keytext].percentfavorable);
        }
        else if (Boolean(meandata[item.keytext]) && !showdemographic) {
          mean = Math.round(item.percentfavorable - meandata[item.keytext].percentfavorable);
        }
      }
      else {
        mean = null
      }

      return {
        ...item,
        historicalData: mean,
        keyDriverValue: keyDriverValue,
        corelation: corelation
      }
    })

    // Do Sorting
    if (sortBy === "corelation") {
      if(showdemographic){
        datatab2 = _.orderBy(datatab2, [demographicsSortFunction], [sort])
      }
      else{
        datatab2 = [..._.orderBy(datatab2.filter(f => (f.keyDriverValue === 1)), [sortBy], [sort]), ...datatab2.filter(f => (f.keyDriverValue === 0))]
      }
    }else if(showdemographic && sortBy==="keytext"){
      datatab2 = _.orderBy(datatab2, [demographicsSortFunction], [sort])
    }
    else {
      datatab2 = _.orderBy(datatab2, [sortBy], [sort])
    }

    if (selectValue === "keyDrivers") {
      datatab2 = datatab2.filter((val) => val.keyDriverValue === 1)
      datatab2 = _.orderBy(datatab2, [sortBy ? sortBy : "corelation"], [sort ? sort : "desc"])
    }

    const mapFieldBySelectedValue = {
      'question': 'questionText',
      'category': ['question', 'keyDrivers'].indexOf(selectedValue) !== -1 ? 'categoryName' : 'keytext',
      'demographic': 'keytext',
      'keyDrivers': 'questionText',
    };

    const appliedFilterFinder = filtersApplied && _.isObject(filtersApplied) && Object.keys(filtersApplied).length === 1 && Object.keys(filtersApplied)?.[0];
    const selectedFilterCondition = appliedFilterFinder && _.isArray(filtersApplied?.[appliedFilterFinder]) && filtersApplied?.[appliedFilterFinder].length > 0;

    if (selectedFilterCondition) {
      datatab2 = datatab2.filter(d => {
        return (filtersApplied?.[appliedFilterFinder] || []).indexOf(d?.[mapFieldBySelectedValue?.[appliedFilterFinder]]) !== -1
      })
    }

    return (
      <React.Fragment>
        {datatab2 && datatab2.map((item, index) =>
          <TableRow key={index} className={classes.tableRow}>
            {tableDataRowValues(item)}
          </TableRow>
        )}
      </React.Fragment>
    );
  };

  // Value entry in table row
  const tableDataRowValues = (item) => {
    return (
      <React.Fragment>
        {field.map((field, index) => {
          return field === "companyComparison" ?
            <TableCell
              className="relative txtcenter"
              key={"value-" + index}
            >
              <Grid className={classes.HLSLine}>
              </Grid>
              {bindSelectField(item, field)}
            </TableCell>
            : (field === "keyDriverValue" ?
              <TableCell
                width="5%"
                key={"value-" + index}
                className="pr-3 pl-15 relative "
              >
                <Grid className="txtcenter">
                  {item && item.keyDriverValue === 1 && <Tooltip arrow title={"Key Driver"}>{keyDriver()}</Tooltip>}
                </Grid>
              </TableCell>
              : (field === "keytext" || field === "questionText" ?
                <TableCell
                  width="20%"
                  key={"value-" + index}
                  className="pl-15 relative"
                >
                  {bindSelectField(item, field)}
                </TableCell>
                : (field === "categoryName" ?
                  <TableCell
                    width="10%"
                    key={"value-" + index}
                    className="pr-2 pl-15"
                  >
                    {bindSelectField(item, field)}
                  </TableCell> :
                  <TableCell
                    className="txtcenter"
                    key={"value-" + index}
                  >
                    {bindSelectField(item, field)}
                  </TableCell>
                )
              )
            )
        }
        )}
      </React.Fragment>
    );
  };

  /**
       * Bind Table Row Cell Value or Value Component
       * Select Field
       */

  const bindSelectField = (item, pathArr) => {
    const arr = pathArr.split(".");
    const value = arr.reduce((obj, key) => (obj && obj[key] !== "undefined")
      ? obj[key]
      : undefined,
      item
    );

    //questionText 
    if (pathArr === "questionText") {
      let charCount = 90
      const summaryTableColumnLength = summaryTableColumns.filter(el => Boolean(el) == true).length
      charCount = 90 + ((7 - ((summaryTableColumnLength > 7 || summaryTableColumnLength < 0) ? 7: summaryTableColumnLength)) * 20)
      return (
        <React.Fragment>
          {value &&
            <>
              <Tooltip arrow title={value} placement="bottom-start" >
                <Grid className={`text-ellipsis ${pathArr}`}>{addEllipsis1(value, charCount)}</Grid>
              </Tooltip>
            </>
          }

        </React.Fragment>
      )
    }

    //category tooltip
    if (pathArr === "keytext") {
      return (
        <React.Fragment>
          {value &&
            <Tooltip arrow title={value} placement="bottom-start" >
              <Grid>{addEllipsis1(value, 50)}</Grid>
            </Tooltip>
          }

        </React.Fragment>
      )
    }

    //categoryText
    if (pathArr === "categoryName") {
      return (
        <React.Fragment>
          <Tooltip arrow title={value} placement="bottom-start" >
            <Grid>{value}</Grid>
          </Tooltip>
        </React.Fragment>
      )
    }


    // To calculate historical data
    if (pathArr === "historicalData") {
      return (
        <React.Fragment>
          <ListItemAvatar>
            {value > 0 ?
              <ListItem className="justifycenter">
                <Grid className={classes.HighRatesCount}>
                  <Grid className={classes.arrow_img_up}>
                    {ArrowBlue()}
                  </Grid>
                  {/* <img className={classes.arrow_img_up} src={HighRateArrowImg}></img> */}
                </Grid>
                <Grid className="pl5 pb5">
                  {value}
                </Grid>
              </ListItem>
              : (value < 0 ?
                <ListItem className="justifycenter">
                  <Grid className={classes.arrow_img_down}>
                    {/* <img  src={LowRateArrowImg} ></img> */}
                    {ArrowOrange()}
                  </Grid>

                  <Grid className="pl5 pb5">{value}</Grid>
                </ListItem>
                :
                (value === 0 ?
                  <ListItem className="justifycenter">
                    <Grid className="pl-2">{value}</Grid>
                  </ListItem>
                  :
                  <ListItem className="justifycenter">
                    <Grid> - </Grid>
                  </ListItem>
                )
              )
            }
          </ListItemAvatar>
        </React.Fragment>
      );
    }

    // Graph for companycomparison column
    else if (pathArr === "companyComparison") {
      return (
        <React.Fragment>

          {Math.round(value) === 0
            ?
            <Grid md={6} className={classes.HLSPositiveWrapper}>
              <Grid className={classes.HLSchartPoscontainer}>
                <Grid
                  className="hlsBarPositive"
                  style={{ width: `${(((value) + 2) * 0)}%`, float: "left", paddingLeft: "0px" }}
                ></Grid>
                <Grid className={classes.HLSpositivevalue}></Grid>

              </Grid>
            </Grid>
            :
            (Math.round(value) > 0 ?
              <Grid md={6} className={classes.HLSPositiveWrapper}>
                <Grid className={classes.HLSchartPoscontainer}>
                  <Grid
                    className="hlsBarPositive"
                    style={{ width: `${(((Math.round(value)) + 2) * 1)}%`, float: "left", paddingLeft: "4px" }}
                  ></Grid>
                  <Grid className={classes.HLSpositivevalue}>{Math.round(value)}</Grid>

                </Grid>
              </Grid>
              :
              <Grid md={6} className={classes.HLSNegativeWrapper}>
                <Grid className={classes.HLSchartNegcontainer}>
                  <Grid
                    className="hlsBarNegative"
                    style={{ width: `${(((Math.round(value)) - 2) * -1) * 1}%`, float: "right" }}
                  ></Grid>
                  <Grid className={classes.HLSnegtivevalue}>{Math.round(value)}</Grid>

                </Grid>
              </Grid>
            )

          }

        </React.Fragment>
      );
    }
    else if (pathArr === "percentfavorable") {
      return (
        <Grid className={classes.bg}>
          <Grid>{Math.round(value)}</Grid>
        </Grid>
      );
    }
    else if (pathArr === "mean") {
      return (
        <Grid className={classes.bg}>
          <Grid>{(Math.round(value * 100) / 100).toFixed(2)}</Grid>
        </Grid>
      );
    }
    else {
      return (
        <div>{value}</div>
      );

    }
  };

  return (
    <div className={`${isLeftTable ? classes.leftroot : (isRightTable ? classes.rightroot : classes.root)}`}>
      <div className={`scrollx ${showPagination ? classes.tableContainer : classes.tableContainer}`} style={{ height: height, marginLeft: "0px" }}>
        <Table stickyHeader aria-label="sticky table" className={`${isRightTable === false ? classes.table : ''} hlstable`}>
          <TableHead>
            {tableHeaderRow()}
          </TableHead>
          <TableBody >
            {tableDataRow()}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

// prop types
HighLevelSummaryTable.propTypes = {
  height: PropTypes.string,
  data: PropTypes.array.isRequired,
  header: PropTypes.array.isRequired,
  field: PropTypes.array.isRequired,
  search: PropTypes.array.isRequired,
  actions: PropTypes.array,
  links: PropTypes.array,
  rowsPerPage: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  sortBy: PropTypes.string.isRequired,
  sort: PropTypes.string.isRequired,
  removeSortingHeader: PropTypes.array,
  ellipsis: PropTypes.bool,
  componentMap: PropTypes.object,
  deleteContent: PropTypes.string,
  isRightTable: PropTypes.bool,
  isLeftTable: PropTypes.bool,
  onSortRequest: PropTypes.func.isRequired,
  onChangeDate: PropTypes.func,
  onChangeDemographics: PropTypes.func,
  filtersApplied: PropTypes.object,
  applyFilters: PropTypes.func,
};

// default types
HighLevelSummaryTable.defaultProps = {
  height: "0px",
  data: [],
  header: [],
  field: [],
  search: [],
  actions: [],
  links: [],
  rowsPerPage: 0,
  count: 0,
  sortBy: "",
  sort: "asc",
  dateFields: [],
  removeSortingHeader: [],
  classes: {},
  ellipsis: true,
  componentMap: {},
  isRightTable: false,
  isLeftTable: false,
  onSortRequest: () => { },
  onChangeDate: () => { },
  onChangeDemographics: () => { },
  filtersApplied: {},
  applyFilters: () => {},
};

export default withStyles(HighLevelSummaryTableStyle)(HighLevelSummaryTable);
