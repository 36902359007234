/* eslint-disable no-unneeded-ternary */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import SvgIcon from '@mui/material/SvgIcon';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";


// Import Icons 
import { WifiAccord } from "../../../../assets/images/svgComponents/ImageComponent";

import communicationicon from "../../../../assets/images/svg/wifi.svg";

// Import Components
import TextEditor from "../../../../components/TextEditor";
import ButtonComponent from "../../../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../../../components/TextFieldComponent/TextFieldComponent";
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import SelectFieldComponent from "../../../../components/SelectFieldComponent/SelectFieldComponent";
import InformationComponent from "../../../../components/InformationComponent/InformationComponent";
import PoweredByGoogleComponent from "../../../../components/PoweredByGoogleComponent/PoweredByGoogleComponent";
import SwitchComponent from "../../../../components/SwitchComponent/SwitchComponent";
// constants
import { surveySettingsConstants, translateLanguagesList, surveyQuestionsConstants, surveyTypes } from "../../../../config/constants";
import { surveyConstant } from "../../../../redux/constants";

// Import Actions
import { dashboardAction, updateStateReducerAction, surveyAction } from "../../../../redux/actions";

// Import Helpers
import { getReducerUpdatedData, isTranslated, iseditedLangtxt } from "../../../../helpers";

function CommunicationPanel(props) {
  const { classes, data, expanded } = props;
  const dispatch = useDispatch();

  const { settings, translated_history } = useSelector((state) => state.survey);

  const surveyType = useSelector((state) => state.survey.getOne.type)
  const [selectedLanaguage, setSelectedLanguage] = useState({
    lang: "English",
    langcode: "en"
  })
  const [translationConfirmDialog, setTranslationConfirmDialog] = useState({ flag: false });
  const { getOne } = useSelector((state) => state.client);
  const { languageTranslation } = getOne
  const { lang, langcode } = selectedLanaguage


  /*   if (surveyType && surveyType !== surveyTypes.MULTI_RATER_FEEDBACK && data.welcome_description["en"] === surveySettingsConstants.WELCOME_DEC) {
      data.welcome_description["en"] = ""
    } */

  /**
   * Handle Language Change Event
   * @param {*} panel
   */

  const handlelangchange = (event) => {
    findlangkey(event.value);
  };


  /**
   * Find Language Key
   * @param {*} panel
   */

  const findlangkey = (event) => {
    for (var i = 0; i < translateLanguagesList.length; i++) {
      if (translateLanguagesList[i].name === event) {
        // setlangcode(translateLanguagesList[i].code);
        // setlang(event);
        setSelectedLanguage({
          lang: event,
          langcode: translateLanguagesList[i].code
        })
        break
      }
    }
  };

  /**
   * Handle Text area Change Event
   * @param {*} panel
   */
  const handleTextChange = (event) => {
    console.log("text changed")
    handleChange({ name: event.target.name, value: event.target.value, fieldName: event.fieldName });
  };

  /**
   * Handle Change Event
   * @param {*} panel
   */
  const handleChange = (event) => {
    if(event.name == "data.welcome_name_access"){
      data.welcome_name_access = event.value
    }
    // call update reducer after getting suvery info
    if (data._id && !data.isLoading && data.frequency) {
      data.description = data.description ? data.description : {};
      data.thanks_header = data.thanks_header ? data.thanks_header : {};
      data.thanks_description = data.thanks_description ? data.thanks_description : {};
      data.welcome_header = data.welcome_header ? data.welcome_header : {};
      data.welcome_description = data.welcome_description ? data.welcome_description : {};
      iseditedLangtxt(translated_history, langcode, event.fieldName)
      const reducerData = getReducerUpdatedData(event, data);
      dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_SETTINGS_REDUCER_UPDATE, reducerData));
    }
  };

  /**
   * Handle Tab Change Event
   * @param {*} name
   */
  const handleTabChange = (panel) => (event, isExpanded) => {
    props.handleTabChange({ isExpanded, panel });
  };


  const handleTranslation = () => {
    let surveyData = settings
    surveyData = { ...surveyData, translated_history }
    const targetLanguage = langcode;
    dispatch(surveyAction.translateSurveySettingsCommunication({
      surveyData,
      targetLanguage
    }));
    setTranslationConfirmDialog(false)
  };

  return (
    <React.Fragment>
      <Accordion
        className="mb-2 pe-accordion"
        expanded={expanded === "communication-panel"}
        onChange={handleTabChange("communication-panel")}
      >

        {/*
        panel header summary-
      */}

        <AccordionSummary
          expandIcon={<ExpandMoreIcon className="expandicon" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <SvgIcon xmlns="http://www.w3.org/2000/svg" width="23.577" height="17.821" viewBox="0 0 23.577 17.821" className={`${classes.settings_icons} ${expanded === "communication-panel" ? "panel-active" : "panel-inactive"} `} alt={"settings"}>
            {WifiAccord()}
          </SvgIcon>
          <TypographyComponent
            className={` ${classes.setting_accordheading} ${expanded === "communication-panel" ? "panel-active" : ""} `}
            variant={"h5"}
          >{surveySettingsConstants.COMMUNICATION_PANEL_TITLE}</TypographyComponent>
          <TypographyComponent
            className={`${"oneline_ellipse mt2"} ${expanded === "communication-panel" ? "panel-active" : ""}`}
            variant={"h6"}
          >{surveySettingsConstants.COMMUNICATION_PANEL_MESSAGE}</TypographyComponent>
        </AccordionSummary>

        {/*
        panel body
      */}

        {data &&
          <AccordionDetails>
            <Grid container spacing={3} className={`${classes.setting_expandcontainer} `}>
              <Grid item sm={12}>
                <Grid container justifyContent="flex-end" alignItems="center">
                  <Grid className="mt-0 pl-1">
                    <SelectFieldComponent
                      select
                      name="lang"
                      value={lang}
                      disabled={data.languages.length > 1 ? (languageTranslation === false ? true : false) : true}
                      variant="outlined"
                      fullWidth={false}
                      handleChange={(e) => handlelangchange(e)}
                      options={data.languages}
                    />
                  </Grid>
                  {languageTranslation === true &&
                    <Grid className="mt-0 pl-1">

                      <Button
                        variant="contained"
                        className="buttonOrange txtwhite"
                        disabled={lang === "English" ? true : false}
                        disableElevation
                        size="small"
                        onClick={() => { setTranslationConfirmDialog({ flag: true }) }}
                      >
                        {surveySettingsConstants.APPLY_TRANSLATION_BUTTON}
                      </Button>
                      <InformationComponent>
                        <TypographyComponent variant="tooltipbody">
                          {surveySettingsConstants.APPLY_TRANSLATION_INFO_COMMUNICATION}
                        </TypographyComponent>
                      </InformationComponent>
                    </Grid>
                  }
                </Grid>

                <Grid>
                  <TypographyComponent variant="h5" className={"pb-1 txtBold"}>
                    {surveySettingsConstants.SURVEY_DESCRIPTION}
                    <InformationComponent>
                      <TypographyComponent variant="tooltipbody">
                        {surveySettingsConstants.COMMUNICATION_SURVEY_DESC}
                      </TypographyComponent>
                    </InformationComponent>
                  </TypographyComponent>

                  <TextareaAutosize
                    className={`width100 pe_textarea mb-m5 borderRadius6 ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "description_message") ? classes.translatedField : classes.editedtranslatedField) : ""}`}
                    name={`description.${langcode}`}
                    placeholder={data.description && data.description["en"] ? data.description["en"] : ""}
                    value={data.description ? (data.description[langcode] ? data.description[langcode] : "") : ""}
                    onChange={(e) => handleTextChange({ ...e, "fieldName": "description_message" })}
                    minRows={3} />
                </Grid>

                <Grid className="mt-2 pt-1 pb-1">
                  <TypographyComponent variant="h5" className={"txtBold"}>
                    {surveySettingsConstants.SURVEY_WELCOME_TITLE}
                  </TypographyComponent>
                </Grid>

                <Grid className="pt-1 pb-1">
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.SURVEY_THANK_YOU_HEADER}
                  </TypographyComponent>
                  <Grid container alignItems="center" className={`pe_textarea ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "welcome_header") ? classes.translatedField : classes.editedtranslatedField) : ""}`}>
                    <Grid item sm={2} marginRight="10px">
                      <TextFieldComponent
                        name={`welcome_header.${langcode}`}
                        placeholder={data.welcome_header && data.welcome_header["en"] ? data.welcome_header["en"] : ""}
                        size={"medium"}
                        fullWidth={true}
                        variant="outlined"
                        margin="none"
                        value={data.welcome_header ? (data.welcome_header[langcode] ? data.welcome_header[langcode] : "") : ""}
                        handleChange={(e) => handleChange({ ...e, "fieldName": "welcome_header" })}
                        className={`${"br-0 br-none m-0"} ${classes.distri_emailmsg}  ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "welcome_header") ? classes.translatedField : classes.editedtranslatedField) : ""} `} />

                      {/* <TextareaAutosize
                        className={`br-none width100 pe_textarea_border_none mb-m5 borderRadius6 ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "thanks_header") ? classes.translatedField : classes.editedtranslatedField) : ""}`}
                        name={`welcome_header.${langcode}`}
                        placeholder={data.welcome_header && data.welcome_header["en"] ? data.welcome_header["en"] : ""}
                        value={data.welcome_header ? (data.welcome_header[langcode] ? data.welcome_header[langcode] : "") : ""}
                        onChange={(e) => handleTextChange({ ...e, "fieldName": "welcome_header" })}
                        rowsMin={1} /> */}
                    </Grid>
                    <SwitchComponent item xs={5}
                  name="data.welcome_name_access"
                  color="primary"
                  size="small"
                  checked={data.welcome_name_access}
                  handleChange={(e) => { handleChange(e); }}
                  label={
                    <span>
                      {/* <TypographyComponent
                        variant="h6"
                      >{data.label}</TypographyComponent> */}
                    </span>
                  }
                />
                    <Grid item sm={5} className={classes.CommunicationConstant_size} color={data.welcome_name_access ? "" :"#888383"}>
                      {surveySettingsConstants.GREETINGS_CONSTANT_NAME}
                    </Grid>
                  </Grid>


                  {/* <Grid
                    item sm={12}
                    className={`mb-1 pt5 auto-translate ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "welcome_header") ? "edited" : "non-edited") : "initial"}`}
                  >
                    <TextEditor
                      id={`welcome_header_${langcode}`}
                      height={"145px"}
                      placeholder={langcode !== "en" && data.welcome_header ? data.welcome_header["en"] : ""}
                      data={data.welcome_header ? (data.welcome_header[langcode] ? data.welcome_header[langcode].replace(/&amp; nbsp;/g, " ") : "") : ""}
                      onChange={(e, editor) => handleChange({ "name": `welcome_header.${langcode}`, "value": editor.getData(), "fieldName": "welcome_header" })}
                    />
                  </Grid> */}

                  {/* <TextareaAutosize
                    className={`width100 pe_textarea mb-m5 borderRadius6 ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "thanks_header") ? classes.translatedField : classes.editedtranslatedField) : ""}`}
                    name={`welcome_header.${langcode}`}
                    placeholder={data.welcome_header && data.welcome_header["en"] ? data.welcome_header["en"] : ""}
                    value={data.welcome_header ? (data.welcome_header[langcode] ? data.welcome_header[langcode] : "") : ""}
                    onChange={(e) => handleChange({ ...e, "fieldName": "welcome_header" })}
                    rowsMin={3} /> */}
                </Grid>

                <Grid className="pt-1 pb-1">
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.SURVEY_THANK_YOU_DESC}
                  </TypographyComponent>
                  <Grid
                    item sm={12}
                    className={`mb-1 pt5 auto-translate ${classes.CommunicationConstant_size} ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "welcome_description") ? "edited" : "non-edited") : "initial"}`}
                  >
                    <TextEditor
                      id={`welcome_description_${langcode}`}
                      height={"145px"}
                      placeholder={langcode !== "en" && data.welcome_description ? data.welcome_description["en"] : ""}
                      data={data.welcome_description ? (data.welcome_description[langcode] ? data.welcome_description[langcode] : "") : ""}
                      onChange={(e, editor) => handleChange({ "name": `welcome_description.${langcode}`, "value": editor.getData(), "fieldName": "welcome_description" })}
                    />
                  </Grid>
                </Grid>

                <Grid className="mt-2 pt-1">
                  <TypographyComponent variant="h5" className={"txtBold"}>
                    {surveySettingsConstants.SURVEY_THANK_YOU_TITLE}
                  </TypographyComponent>
                </Grid>

                <Grid className="pt-1 pb-1">
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.SURVEY_THANK_YOU_HEADER}
                  </TypographyComponent>

                  <TextareaAutosize
                    className={`width100 pe_textarea mb-m5 borderRadius6 ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "thanks_header") ? classes.translatedField : classes.editedtranslatedField) : ""}`}
                    name={`thanks_header.${langcode}`}
                    placeholder={data.thanks_header && data.thanks_header["en"] ? data.thanks_header["en"] : ""}
                    value={data.thanks_header ? (data.thanks_header[langcode] ? data.thanks_header[langcode] : "") : ""}
                    onChange={(e) => handleTextChange({ ...e, "fieldName": "thanks_header" })}
                    minRows={3} />
                </Grid>

                <Grid className="pt-1 pb-1">
                  <TypographyComponent variant="h6">
                    {surveySettingsConstants.SURVEY_THANK_YOU_DESC}
                  </TypographyComponent>
                  <Grid
                    item sm={12}
                    className={`mb-1 pt5 auto-translate ${classes.CommunicationConstant_size} ${translated_history.hasOwnProperty(langcode) ? (isTranslated(translated_history, langcode, "thanks_description") ? "edited" : "non-edited") : "initial"}`}
                  >
                    <TextEditor
                      id={`thanks_description_${langcode}`}
                      height={"145px"}
                      placeholder={langcode !== "en" && data.thanks_description ? data.thanks_description["en"] : ""}
                      data={data.thanks_description && data.thanks_description[langcode] ? data.thanks_description[langcode] : ""}
                      onChange={(e, editor) => handleChange({ "name": `thanks_description.${langcode}`, "value": editor.getData(), "fieldName": "thanks_description" })}
                    />
                  </Grid>
                </Grid>




              </Grid>
            </Grid>
          </AccordionDetails>
        }
      </Accordion>

      {translationConfirmDialog.flag && (
        <Dialog open={translationConfirmDialog.flag}>
          <DialogContent>
            <TypographyComponent
              title={surveyQuestionsConstants.TRANSLATE_CONFIRMATION_DIALOG_MESSAGE_COMMUNICATION.split('\n').map(str => <div>{str}</div>)}
              variant={"div"}
              align={"center"}
              className="txtcenter"
            > </TypographyComponent>
          </DialogContent>
          <DialogActions>
            <ButtonComponent
              title={"Yes"}
              color={"primary"}
              size={"small"}
              handleClick={() => { handleTranslation() }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
            <ButtonComponent
              title={"No"}
              color={"default"}
              size={"small"}
              handleClick={() => { setTranslationConfirmDialog({ flag: false }); }}
              componentClassName={"marginLR5"}
            ></ButtonComponent>
          </DialogActions>
          <DialogContent>
            <PoweredByGoogleComponent />
          </DialogContent>
        </Dialog>
      )}

    </React.Fragment>
  );
}

// default props
CommunicationPanel.defaultProps = {
  classes: {},
  data: {},
  expanded: false,
  handleTabChange: () => { }
};

// prop types
CommunicationPanel.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.object,
  expanded: PropTypes.any,
  handleTabChange: PropTypes.func
};

export default (CommunicationPanel);
