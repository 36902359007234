/* eslint-disable no-unused-vars */
/* eslint-disable no-case-declarations */
/* eslint-disable prefer-const */
/* eslint-disable react/prop-types */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";

// settigns icon data level access
import { SettingGrey, SettingOrange } from "../../../assets/images/svgComponents/ImageComponent";

// import images
import { Plus, BackPage } from "../../../assets/images/svgComponents/ImageComponent";

import PariticipantStyles from "../Pariticipant/PariticipantStyles";

// Import Constants
import { adminConstants, excelConstants, clientConstants } from "../../../config/constants";

// Import Table Size
import { getTableHeight, getTableRowSize } from "../../../config/layout";

// Import History
import history from "../../../config/history";

// Import Container
import Container from "../../Containers/Container";



// Import Helpers
import { getReducerUpdatedData, getQueryVariable } from "../../../helpers";

// Import Component
import TableComponent from "../../../components/TableComponent/TableComponent";
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../../components/ButtonComponent/ButtonComponent";
import SwitchComponent from "../../../components/SwitchComponent/SwitchComponent";

// Import Actions
import { adminsAction, updateStateReducerAction, excelAction } from "../../../redux/actions";

// Import Constants
import { adminsConstant } from "../../../redux/constants/adminsConstant";
// import { excelConstants } from '../../../config/constants';

/**
 * Switch Toggles
 */
const StatusSwitch = ({ data, handleChange }) => {
  return (
    <Grid item xs={12}>
      <SwitchComponent item xs={12}
        name={"status"}
        color="primary"
        checked={data.status}
        handleChange={({ value }) => {
          handleChange("status", data, value);
        }}
        label={
          <span>
            <TypographyComponent
              variant="h6"
            >{data.label}</TypographyComponent>

          </span>
        }
      />
    </Grid>
  );
};

const DataLevelAccessIcon = ({ data, handleChange, classes }) => {
  let hasQuery = data.queryDLA;
  return (
    <div className={classes.tableactions}>
      <Tooltip title="Settings" arrow>
        <Avatar
          alt="Setting"
          className={`tableIcon MuiAvatar-root MuiAvatar-circle ml5 mr5 scrollIntial cursor-pointer`}
          onClick={() => handleChange("ACTION_SETTING", data, null)}
        >
          {hasQuery ?
            <Grid>
              {SettingOrange()}
            </Grid> :
            <Grid>
              {SettingGrey()}
            </Grid>}

        </Avatar>
      </Tooltip>
    </div>
  );
};

const componentMap = {
  status: StatusSwitch
};

const actionComponent = {
  SETTING: DataLevelAccessIcon
};

const AdminList = (props) => {
  const { classes } = props;

  // Define Dispatch
  const dispatch = useDispatch();

  // Table size
  const tableHeight = getTableHeight();
  const tableRowSize = getTableRowSize();

  // Get People from store
  const adminsList = useSelector(state => state.admin.search);
  const admin = useSelector(state => state.admin.getOne);

  const { page, data } = adminsList;

  /**
   * Handle Change Event
   * @param {*} event
   */
  const handleChange = (event) => {
    const reducerData = getReducerUpdatedData(event, admin);
    dispatch(updateStateReducerAction.update(adminsConstant.ADMINS_GET_ONE_UPDATE, reducerData));
  };

  // Get Page Reset 
  const isPageReset = getQueryVariable(props.location.search, "reset") === "true";
  const isPageRefresh = getQueryVariable(props.location.search, "refresh") === "true";

  /**
   * Get Users admins
   */
  useEffect(() => {
    if (!isPageRefresh) {
      let { sFields, sValues } = page;
      if (typeof sFields === 'undefined')
        sFields = []
      if (typeof sValues === 'undefined')
        sValues = []
      dispatch(adminsAction.search(1, tableRowSize, "active", "desc", sFields, sValues));
    }
  }, [dispatch]);

  // // if clear search and call api
  // useEffect(() => {
  //   if (isPageReset) {
  //     dispatch(adminsAction.search(1, tableRowSize, "active", "desc", [], []));
  //     history.replace(props.location.pathname);
  //   }
  // }, [isPageReset]);

  useEffect(() => {
    if (isPageReset) {
      history.replace(props.location.pathname)
      dispatch(adminsAction.search(1, tableRowSize, "active", "desc", [], []));
    }
    if (isPageRefresh)
      history.replace(props.location.pathname)
  }, [isPageRefresh, isPageReset])
  /**
     * Handle Table pagination Event
     */
  const onChangePagination = (skip, limit) => {
    dispatch(adminsAction.search(skip, limit, page.sortBy, page.sort, page.sFields, page.sValues));
  };

  /**
   * Handle Table Sort Request Event
   */
  const onSortRequest = (sortBy, sort) => {
    dispatch(adminsAction.search(page.skip, page.limit, sortBy, sort, page.sFields, page.sValues));
  };

  /**
    * Handle Table Search Input Change Event
   */
  const onInputChangeRequest = (searchField, searchKey) => {
    const { sFields, sValues } = page;
    if (sFields.indexOf(searchField) >= 0) {
      if (searchKey === "") {
        const index = sFields.indexOf(searchField);
        sValues.splice(index, 1);
        sFields.splice(index, 1);
      } else {
        sValues[sFields.indexOf(searchField)] = searchKey;
      }
    } else {
      sFields.push(searchField);
      sValues.push(searchKey);
    }

    if (isPageRefresh)
      dispatch(adminsAction.search(page.skip, tableRowSize, page.sortBy, page.sort, sFields, sValues));
    else
      dispatch(adminsAction.search(1, tableRowSize, page.sortBy, page.sort, sFields, sValues));
  };

  /**
    * Handle Table Navigation Event
   */
  const handleNavigateAction = (id) => {
    history.push({
      pathname: `/manage_people/admins/${id}/edit`
    });
  };

  /**
   * Handle Action Button Event
   * @param {*} field
   * @param {*} id
   */
  const handelActionButtonClick = (field, id, name) => {
    switch (field) {
      case "DELETE":
        break;
      default:
        break;
    }
  };

  /**
     * Handle Field Component On Change Event
     */
  const fieldComponentOnChange = (field, data, value) => {
    switch (field) {
      case "status":
        const updatedData = { ...data, [field]: value };
        dispatch(adminsAction.updateList(updatedData, () => {
          // retain old search values
          if (page.skip) {
            dispatch(adminsAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
          } else {
            dispatch(adminsAction.search(1, tableRowSize, "status", "desc", [], []));
          }
        })
        );
        break;
      case "ACTION_SETTING":
        handleNavigateAction(data._id);
        break;
      default:
    }
  };

  // Create a reference to the hidden file input element
  const hiddenFileInput = React.useRef(null);

  /**
  * Select excel popup
  */
  const excelImport = () => {
    hiddenFileInput.current.click();
  };

  /**
 * upload excel
 */
  const excelUpload = (event) => {
    var formData = new FormData();
    const fileUploaded = event.target.files[0];
    formData.append("file", fileUploaded);
    formData.append("excelType", excelConstants.ADMIN_UPLOAD);
    dispatch(excelAction.upload(formData, excelConstants.ADMIN_UPLOAD, () => {
      dispatch(adminsAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
    }));
  };

  /**
   * Download Excel
   */
  const excelDownload = () => {
    dispatch(excelAction.download({
      sortBy: page.sortBy ? page.sortBy : "status",
      sort: page.sort ? page.sort : "desc",
      sFields: page.sFields ? page.sFields : [],
      sValues: page.sValues ? page.sValues : [],
      excelType: excelConstants.ADMIN_DOWNLOAD
    }));
  };

  /**
   * Handle Add Click
   * @param {*} id
   */
  const handleAddClick = () => {
    history.push({
      pathname: "/manage_people/admins/new"
    });
  };

  /** Delete Function*
  * @param {*} field
  * @param {*} id
  */
  const handleTableDeleteAction = (id) => {
    dispatch(adminsAction.remove(id, () => {
      // retain old search values
      if (page.skip) {
        dispatch(adminsAction.search(page.skip, page.limit, page.sortBy, page.sort, page.sFields, page.sValues));
      } else {
        dispatch(adminsAction.search(1, tableRowSize, "status", "desc", [], []));
      }
    })
    );
  };

  /**
   * Set Saml Field Help Text
   */
  const getInfoHeaders = () => {
    const { fieldCols, selectedCols } = page.fieldFilters;
    let infoHeaders = {}
    if (selectedCols) {
      const samlFieldIndex = Object.keys(selectedCols).indexOf("samlFieldValue");
      if (samlFieldIndex >= 0) {
        infoHeaders[fieldCols[samlFieldIndex - 1]] = clientConstants.SSO_IDENTIFIER_HELP
      }
    }

    infoHeaders['Active Status'] = adminConstants.ACTIVE_STATUS_HELP
    return infoHeaders;
  }

  return (
    <Container align={"baseline"}>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Grid container alignItems="center">
                <Grid onClick={() => history.push("/manage_people")} className={classes.backimg}>
                  {BackPage()}
                </Grid>
                <TypographyComponent
                  variant={"h3"}
                  className="ml-1"
                  title={adminConstants.MANAGE_USER_ROLES} />
              </Grid>
            </Grid>
            <Grid item>
              {page.sFields && page.sFields.length > 0 ? <ButtonComponent
                form="clear-table-search"
                title={adminConstants.CLEAR_ALL}
                color={"primary"}
                size={"small"}
                type="submit"
                variant={"text"}
                pageClassName="mr-0"
                handleClick={() => {
                  history.replace(`${props.location.pathname}?reset=true`);
                }}
              /> : ""}
              <Tooltip title="Add a user" arrow>
                <IconButton
                  className={"p-0 mr-1"}
                  variant="contained"
                  color="primary"
                  onClick={handleAddClick}
                  size="large">
                  {Plus()}
                </IconButton>
              </Tooltip>
              <ButtonComponent
                title={adminConstants.EXPORT}
                color={"primary"}
                size={"small"}
                pageClassName="mr-1"
                handleClick={excelDownload}
              ></ButtonComponent>

              <ButtonComponent
                title={adminConstants.IMPORT}
                color={"primary"}
                size={"small"}
                handleClick={(e) => { history.push({ pathname: "/manage_people/admins/upload/import" }); }}
              ></ButtonComponent>
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <TableComponent
            data={data}
            header={page.fieldFilters.headerCols}
            field={page.fieldFilters.fieldCols}
            search={page.fieldFilters.searchCols}
            actions={["SETTING", "DELETE"]}
            height={tableHeight}
            page={page.skip}
            count={page.count}
            rowsPerPage={page.limit}
            links={[]}
            dateFields={page.fieldFilters.dateCols}
            sort={page.sort}
            sortBy={page.sortBy}
            alignCenterFields={['Active Status', 'status']}
            sFields={page.sFields}
            sValues={page.sValues}
            noDataErrorMessage={"No Participants Found"}
            removedSortingRow={[]}
            moreInfoIcons={[]}
            componentMap={{ ...componentMap }}
            columnsMap={{}}
            showLoading={false}
            prevNext={false}
            paginationVisible={!page.lastPage}
            deleteContent={adminConstants.DELETE_CONTENT}
            handleNavigateAction={handleNavigateAction}
            onChangePagination={onChangePagination}
            onSortRequest={onSortRequest}
            onInputChangeRequest={onInputChangeRequest}
            fieldComponentOnChange={fieldComponentOnChange}
            handelActionButtonClick={handelActionButtonClick}
            handleTableDeleteAction={handleTableDeleteAction}
            resetSearchText={isPageReset}
            actionComponent={actionComponent}
            infoHeaders={getInfoHeaders()}
          >
          </TableComponent>
        </Grid>
      </Grid>
    </Container>
  );
};
export default withStyles(PariticipantStyles)(AdminList);
