import React, { useState, useEffect } from 'react';
import PropTypes from "prop-types";
import { Grid } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import _ from "lodash";

// Import Component
import TypographyComponent from "../../../components/TypographyComponent/TypographyComponent";
import LoaderComponent from "../../../components/LoaderComponent/LoaderComponent";

// Import Widgets
import CardWidget from './Widgets/CardWidget'
import FavorableWidget from './Widgets/FavorableWidget'
import FavorableByCategoryWidget from './Widgets/FavorableByCategoryWidget'
import SaoaWidget from './Widgets/SAOAWidget'
import KeyDriverWidget from './Widgets/KeyDriverWidget'
import LikelyToReturn from './Widgets/LikelyToReturn'
import LikelyToStay from './Widgets/LikelyToStay'
import OveralleNPSWidget from './Widgets/OveralleNPSWidget'
import TurnOverWidget from './Widgets/TurnOverWidget';
import EngagementEmployeeWidget from './Widgets/EngagementEmployeeWidget';

//Import Constants
import { ReportsConstants, surveyTypes } from "../../../config/constants"

//Import Styles
import ReportsStyles from "../ReportsStyles";

// Selector
import { useSelector } from 'react-redux'
import EngagementBenchmark from './Widgets/EngagementBenchmark';

const TopWidgets = (props) => {
    // Get props
    const { classes, adminPreference } = props;
    // Selectors
    const preference = useSelector(state => state?.admin?.preference);
    const { dashboardData } = useSelector(state => state.dashboard);
    const { allSurveys, filterData } = useSelector(state => state.filter);
    const { loading } = useSelector(state => state.UI);
    const zero_check = 0
    let isKeyDriver = dashboardData.keydriver && dashboardData.keydriver.length > 0;
    const hasMESurvey =
        preference &&
        preference?.surveys &&
        preference?.surveys.filter(
            (val) => val.type === surveyTypes.MULTI_RATER_FEEDBACK
        ).length > 0;

    // overall favorable
    // for ME overall favorable is in object else it will be int
    const type = typeof dashboardData.overallFavorable
    let showOverallFavWidget = true
    let overallFavorable = 0.0

    if (type !== "number" && dashboardData.overallFavorable) {
        overallFavorable = (dashboardData.overallFavorable["_"] || {}).percentfavorable || 0
    } else {
        overallFavorable = dashboardData.overallFavorable
    }

    if (!dashboardData.positivepercentfavorable && !dashboardData.negativepercentfavorable && !dashboardData.neturalpercentfavorable && !overallFavorable) {
        showOverallFavWidget = false
    }

    const isResponseAvailable = dashboardData && dashboardData.totalresponse > zero_check;

    let [commonConditions, setCommonConditions] = useState({});

    useEffect(() => {
        setCommonConditions({
            participation: !adminPreference?.data?.hideDashWidgets?.['participation'] && (dashboardData?.totalInvite > zero_check || dashboardData?.totalresponse > zero_check),
            overallFavorability: !adminPreference?.data?.hideDashWidgets?.['overallFavorability'] && ((dashboardData?.positivepercentfavorable > zero_check) || (dashboardData?.negativepercentfavorable > zero_check) || (dashboardData?.neturalpercentfavorable > zero_check)),
            favorableByCategory: !adminPreference?.data?.hideDashWidgets?.['favorableByCategory'] && (dashboardData?.favourable_category && Object.keys(dashboardData?.favourable_category).length >= 2),
            suggestedAreaAction: (!adminPreference?.data?.hideDashWidgets?.['suggestedAreaAction'] && dashboardData?.saoa.length > zero_check),
            keydriver: (!adminPreference?.data?.hideDashWidgets?.['keydriver'] && dashboardData?.keydriver.length > zero_check),
            overalleNPS: !adminPreference?.data?.hideDashWidgets?.['overalleNPS'] && dashboardData?.overalleNPS?.responseCount > zero_check,
            engagedEmployee: !adminPreference?.data?.hideDashWidgets?.['engagedEmployee'] && dashboardData?.engagementBenchMark?.total > zero_check,
            engagementBenchMark: !adminPreference?.data?.hideDashWidgets?.['engagementBenchMark'] && dashboardData?.engagementBenchMark?.total > zero_check,
            likelyToStay: !adminPreference?.data?.hideDashWidgets?.['likelyToStay'] && dashboardData?.likelyToStay?.responseCount > zero_check,
            LikelyToReturn: !adminPreference?.data?.hideDashWidgets?.['LikelyToReturn'] && dashboardData?.likelyToReturn?.responseCount > zero_check,
            turnOver: !adminPreference?.data?.hideDashWidgets?.['turnOver'] && (dashboardData?.turnOver?.chartData.length > zero_check && !dashboardData?.surveyFrequency?.includes('Pulse')),
            projectedTurnover: !adminPreference?.data?.hideDashWidgets?.['projectedTurnover'] && (dashboardData?.turnOver?.chartData.length > zero_check && !dashboardData?.surveyFrequency?.includes('Pulse')),
        })
    }, [dashboardData, preference?.hideDashWidgets]);

    const calculateWigetColumns = (conditions) => {
        const widgetsToShow = Object.entries(conditions).reduce((acc, [key, value]) => {
            if (value) {
                if ((key === 'participation' || key === 'overallFavorability') && acc.indexOf('participation__overallFavorability') === -1) {
                    acc.push('participation__overallFavorability');
                } else if (key !== 'participation' && key !== 'overallFavorability') {
                    acc.push(key);
                }
            }
            return acc;
        }, []);

        const widgetColumnList = (widgets => {
            // Combine suggestedAreaAction and keydriver
            if (widgets.indexOf('suggestedAreaAction') !== -1 || widgets.indexOf('keydriver') !== -1) {
                widgets.splice(0, 0, 'saoaOrKeydrive')
                widgets = widgets.filter(widget => !['suggestedAreaAction', 'keydriver'].includes(widget))
            }

            const widgetsCount = widgets.length;
            let returnArr = {};

            if (widgetsCount === 0) {
                return returnArr;
            }

            if (widgetsCount === 6) {
                widgets.map(widget => {
                    if (['participation__overallFavorability', 'favorableByCategory', 'saoaOrKeydrive', 'overalleNPS'].indexOf(widget) !== -1) {
                        returnArr = { ...returnArr, [widget]: '4' };
                    } else if (widget === 'engagedEmployee' || widget === 'LikelyToReturn' || widget === 'likelyToStay') {
                        returnArr = { ...returnArr, [widget]: '2.25' }
                    } else if (widget === 'engagementBenchMark' || widget === 'turnOver' || widget === 'projectedTurnover') {
                        returnArr = { ...returnArr, [widget]: '5.75' }
                    }
                })
            }

            if (widgetsCount === 5) {
                widgets.map((widget, index) => {
                    if (index <= 2) {
                        returnArr = { ...returnArr, [widget]: '4' };
                    } else {
                        returnArr = { ...returnArr, [widget]: '6' };
                    }
                })
            }

            if (widgetsCount === 4 || widgetsCount === 2) {
                widgets.map(widget => {
                    returnArr = { ...returnArr, [widget]: '6' };
                })
            }

            if (widgetsCount === 3) {
                widgets.map(widget => {
                    returnArr = { ...returnArr, [widget]: '4' };
                })
            }

            if (widgetsCount === 1) {
                widgets.map(widget => {
                    returnArr = { ...returnArr, [widget]: '12' };
                })
            }

            return returnArr;
        });

        return widgetColumnList(widgetsToShow);
    }

    const surveyData = allSurveys.filter((e) => filterData?.surveys.some((f) => f._id == e._id))
    const IsAnonymous = surveyData.filter((e) => e.audience === "anonymous")

    //get propsoverallFavorable
    if (dashboardData.dashBoardLoaderQuestion || loading)
        return <Grid item className={`pr-1 pb-1 ${classes.noDataContainer}`}>
            <Grid className={`${classes.dashboardTile} ${"justifycenter"}`}>
                <Grid item>
                    <LoaderComponent></LoaderComponent>
                </Grid>
            </Grid>
        </Grid>
    else if (!loading && !dashboardData.dashBoardLoaderQuestion && isResponseAvailable) {
        const surveyType = dashboardData.surveyTypes?.[0];
        let conditions = {
            participation: commonConditions['participation'],
            overallFavorability: commonConditions['overallFavorability'],
            favorableByCategory: commonConditions['favorableByCategory'],
            overalleNPS: commonConditions['overalleNPS'],
        };

        if (dashboardData.surveyTypes.length === 1) {
            if (surveyType === 'Engagement') {
                conditions = {
                    ...conditions,
                    keydriver: commonConditions['keydriver'],
                    engagementBenchMark: commonConditions['engagementBenchMark'],
                    engagedEmployee: commonConditions['engagedEmployee']
                }
            } else if (surveyType === 'Exit') {
                conditions = {
                    ...conditions,
                    suggestedAreaAction: commonConditions['suggestedAreaAction'],
                    LikelyToReturn: commonConditions['LikelyToReturn'],
                    turnOver: commonConditions['turnOver'],
                }
            } else if (surveyType === 'New Hire' || surveyType === 'Stay') {
                conditions = {
                    ...conditions,
                    suggestedAreaAction: commonConditions['suggestedAreaAction'],
                    likelyToStay: commonConditions['likelyToStay'],
                    projectedTurnover: commonConditions['projectedTurnover'],
                }
            } else {
                conditions = {
                    ...conditions,
                    suggestedAreaAction: commonConditions['suggestedAreaAction'],
                }
            }
        } else {
            conditions = {
                ...conditions,
                suggestedAreaAction: commonConditions['suggestedAreaAction'],
                overalleNPS: commonConditions['overalleNPS'] &&
                    (dashboardData.surveyTypes.indexOf('Other') === -1 &&
                        ['New Hire', 'Exit', 'Engagement', 'Stay'].some(type => dashboardData.surveyTypes.indexOf(type) !== -1)),
                likelyToStay: commonConditions['likelyToStay'] && (dashboardData.likelyToStay?.responseCount > zero_check && (
                    dashboardData.surveyTypes.length === 1 && dashboardData.surveyTypes.indexOf('Stay') !== -1 ||
                    dashboardData.surveyTypes.length === 2 && _.isEqual(['Engagement', 'New Hire'], dashboardData.surveyTypes.sort())
                )),
                turnOver: 
                    commonConditions['turnOver'] &&
                    dashboardData.surveyTypes.indexOf('Other') === -1 &&
                    (
                        dashboardData.surveyTypes.indexOf("Stay") !== -1 ||
                        (
                            dashboardData.surveyTypes.length === 2 &&
                            (
                                _.isEqual(['Engagement', 'New Hire'], dashboardData.surveyTypes.sort()) ||
                                _.isEqual(['Exit', 'New Hire'], dashboardData.surveyTypes.sort()) 
                            )
                        )
                    ),
                projectedTurnover: false
            }
        }

        const existTurnOverKey = conditions?.['turnOver'] ? 'turnOver' : 'projectedTurnover';
        const widgetListColumnSizes = calculateWigetColumns(conditions);
        const shownWidgets = Object.keys(widgetListColumnSizes).length ?? 0;

        return (
            <>
                {
                    (conditions['participation'] || conditions['overallFavorability']) &&
                    <Grid item xs={widgetListColumnSizes['participation__overallFavorability'] ?? ''}>
                        <Grid container className={`height100 ${shownWidgets > 1 && conditions['overallFavorability'] ? 'flexdirection' : ''}`}>
                            {
                                conditions['participation'] &&
                                <Grid item xs>
                                    <CardWidget widgetPaddingCls={shownWidgets === 1 && conditions['participation'] && conditions['overallFavorability'] ? 'pr-2' : conditions['overallFavorability'] ? 'pb-1' : ''} IsAnonymous={IsAnonymous.length > 0} value={dashboardData.totalInvite} value1={dashboardData.totalresponse} label={ReportsConstants.TOTAL_INVITE} label1={ReportsConstants.TOTAL_RESPONSE} dashboardData={dashboardData} centerLayout={shownWidgets <= 3 || !conditions['overallFavorability']} fullHeight={shownWidgets <= 3 || !conditions['overallFavorability']} type='' adminPreference={adminPreference} />
                                </Grid>
                            }
                            {
                                conditions['overallFavorability'] &&
                                <Grid item xs>
                                    <FavorableWidget type={'large'} value={dashboardData} label={ReportsConstants.OVERALL_FAVORABLITYl} adminPreference={adminPreference} />
                                </Grid>
                            }
                        </Grid>
                    </Grid>
                }
                {
                    conditions['favorableByCategory'] &&
                    <Grid item xs={widgetListColumnSizes?.['favorableByCategory'] ?? ''}>
                        <FavorableByCategoryWidget classes={classes} dashboarddata={dashboardData} adminPreference={adminPreference} />
                    </Grid>
                }
                {
                    conditions['suggestedAreaAction'] && !conditions['keydriver'] ?
                        <Grid item xs={widgetListColumnSizes?.['saoaOrKeydrive'] ?? ''}>
                                <SaoaWidget
                                    label={ReportsConstants.SUGGESTED_AREAACTION}
                                    saoa={dashboardData.saoa}
                                    isCached={dashboardData.isCached}
                                    hasMESurvey={hasMESurvey}
                                    adminPreference={adminPreference}
                                />
                            </Grid>
                        : conditions['keydriver'] ?
                        <Grid item xs={widgetListColumnSizes?.['saoaOrKeydrive'] ?? ''}>
                            <KeyDriverWidget
                                label={ReportsConstants.SUGGESTED_AREAACTION}
                                keydriver={dashboardData.keydriver}
                                isCached={dashboardData.isCached}
                                adminPreference={adminPreference}
                            />
                        </Grid>
                        : <></>
                }
                {
                    conditions['overalleNPS'] &&
                    <Grid item xs={widgetListColumnSizes?.['overalleNPS'] ?? ''}>
                        <OveralleNPSWidget
                            classes={classes}
                            data={dashboardData}
                            adminPreference={adminPreference}
                        />
                    </Grid>
                }
                {
                    conditions['engagedEmployee'] &&
                    <Grid item xs={widgetListColumnSizes?.['engagedEmployee'] ?? ''}>
                        <EngagementEmployeeWidget
                            data={dashboardData?.engagementBenchMark}
                            historicalData={dashboardData?.historical_engagementData}
                            classes={classes}
                            adminPreference={adminPreference}
                        />
                    </Grid>
                }
                {
                    conditions['engagementBenchMark'] &&
                    <Grid item xs={widgetListColumnSizes?.['engagementBenchMark'] ?? ''}>
                        <EngagementBenchmark
                            data={dashboardData?.engagementBenchMark}
                            classes={classes}
                            adminPreference={adminPreference}
                        />
                    </Grid>
                }
                {
                    conditions['LikelyToReturn'] &&
                    <Grid item xs={widgetListColumnSizes['LikelyToReturn'] ?? ''}>
                        <LikelyToReturn classes={classes} data={dashboardData} adminPreference={adminPreference} />
                    </Grid>
                }
                {
                    conditions['likelyToStay'] &&
                    <Grid item xs={widgetListColumnSizes['likelyToStay'] ?? ''}>
                        <LikelyToStay classes={classes} data={dashboardData} adminPreference={adminPreference} />
                    </Grid>
                }
                {
                    conditions[existTurnOverKey] &&
                    <Grid item xs={widgetListColumnSizes[existTurnOverKey] ?? ''}>
                        <TurnOverWidget classes={classes} data={dashboardData?.turnOver} surveyTypes={dashboardData?.surveyTypes} adminPreference={adminPreference} />
                    </Grid>
                }
            </>
        )
    }
    else if (!loading && !dashboardData.dashBoardLoaderQuestion)
        return <Grid item className={`pr-1 pb-1 ${classes.noDataContainer}`}>
            <Grid className={`${classes.dashboardTile} ${"justifycenter"}`}>
                <Grid item>
                    <TypographyComponent variant="h3">
                        {dashboardData && dashboardData.totalInvite <= zero_check && dashboardData.totalresponse <= zero_check &&dashboardData.comments <= zero_check && !showOverallFavWidget && dashboardData.saoa.length <= zero_check ? 'Not enough responses edited for the selected content' : 'Not enough responses for the selected content'}</TypographyComponent>
                </Grid>
            </Grid>
        </Grid>
}
// default props
TopWidgets.defaultProps = {
    adminPreference: {},
};

// prop types
TopWidgets.propTypes = {
    adminPreference: PropTypes.object,
};
export default withStyles(ReportsStyles)(TopWidgets);