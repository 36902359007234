import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Autocomplete from "@mui/material/Autocomplete";
import { IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { TextField } from "@mui/material";

// Import Styles and Image
import {
  DashboardClose,
  Expand,
  Download,
} from "../../../../assets/images/svgComponents/ImageComponent";

// Import Constants
import { ReportsConstants, excelConstants } from "../../../../config/constants";

// Import Reducer Constant
import { dashboardConstant } from "../../../../redux/constants";

// Import Actions
import {
  dashboardAction,
  excelAction,
  updateStateReducerAction,
} from "../../../../redux/actions";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import HotspotTable from "./hotspotTable";
import HotspotPopup from "./hotspotPopup";
import _ from "lodash";
import LoaderComponent from "../../../../components/LoaderComponent/LoaderComponent";
import CacheComponent from "../../../../components/CacheComponent";

function Hotspot(props) {
  //define dispatch
  const dispatch = useDispatch();

  //Get props
  const {
    classes,
    handleClose,
    DragHandle,
    isUpdate,
    updateReportPreference,
    preference,
    stopChartReload,
    hotspotReload,
    updateHotspotReload,
    filtersAppliedChange,
    rlChart
  } = props;

  //get reducer data
  const metadataWithRGIAll = useSelector(
    (state) => state.metadata.metadataWithRGI
  );
  const { hotspot: hotspotData, reload } = useSelector((state) => state.dashboard);
  const { demographicsDropdown: demographics } = useSelector(
    (state) => state.filter
  );
  const { dashboardData: { keydriver }, isDataFiltersApplied } = useSelector((state) => state.dashboard);
  const { hotspotinitialReload } = useSelector(
    (state) => state.dashboard.initialReload
  );

  const { loading, showPopup, data: chartData, isCached, filtersApplied } = hotspotData;
  const reloadChart = () => {
    dispatch(dashboardAction.getHotspotData());
    stopChartReload("4");
    updateHotspotReload(false);
    if (!loading && isDataFiltersApplied) {
      filtersAppliedChange('hotspot', {chartData, filtersApplied}, 'chartData');
    }
  };
  // Get Hotspot Data
  useEffect(() => {
    if (isUpdate && !loading && hotspotReload) {
      reloadChart();
    }
  }, [dispatch, isUpdate, loading]);

  useEffect(() => {
    if (!loading && isDataFiltersApplied) {
      filtersAppliedChange('hotspot', {chartData, filtersApplied}, 'chartData');
    }
  }, [loading]);

  //get true metatadata and sort it
  const demographicsData = Object.keys(demographics);
  let metadataWithRGI = metadataWithRGIAll.filter(
    (d) =>
      d.visible === true &&
      d.visibleInFilter === true &&
      demographicsData.indexOf(d.key) > -1
  );
  metadataWithRGI = _.orderBy(metadataWithRGI, ["value"], ["asc"]);

  //using spread to prevent reducer update on value chage//address error
  const { hotspot } = preference;

  //set default values for reportGroup and selected Type
  let selectedRgi = hotspot && hotspot["rg"] ? hotspot["rg"] : "";
  let selectedType = hotspot && hotspot["type"] ? hotspot["type"] : "question";

  // popup close
  const onClose = () => {
    dispatch(
      updateStateReducerAction.update(
        dashboardConstant.SHOW_HOTSPOT_POPUP,
        !showPopup
      )
    );
  };

  // open popup
  const openPopup = () => {
    dispatch(
      updateStateReducerAction.update(
        dashboardConstant.SHOW_HOTSPOT_POPUP,
        !showPopup
      )
    );
  };

  // Save Report Preference on type change
  const saveReportPreferences = () => {
    const data = {
      ...preference,
      surveys: preference.surveys.map((e) => (e._id ? e._id : e)),
      hotspot: {
        type: selectedType,
        rg: selectedRgi,
      },
    };
    // Update Preferences
    updateReportPreference(data, "4");
  };

  // get display name for auto complete dropdown
  const getOptionSelected = (options, value) => {
    let optionSelected = options.filter((p) => p.key === value)[0] || "";
    return optionSelected;
  };

  //update selected tye and save preferencre
  const updateSelectedType = (type) => {
    selectedType = type;
    saveReportPreferences();
    applyFilters(
      () => {},
      'hotspot',
      {}
    )
  };

  // Apply Filters
  const applyFilters = ((callback, param, data) => {
    dispatch(
      dashboardAction.applyFilters(
        callback,
        true,
        param,
        data
      )
    )
  })

  //excel Download Function
  const excelDownload = () => {
    const { data, color, header2, sorting } = hotspotData;
    const excelData = {
      data,
      sorting: sorting,
      header: header2,
      keydriver: keydriver || [],
      type: selectedType,
      rg: selectedRgi,
      color: color,
      excelType: excelConstants.HOT_SPOT_EXCEL,
      filtersApplied: filtersApplied,
    };

    dispatch(excelAction.download(excelData));
  };

  return (
    <React.Fragment>
      <Grid item sm={12} className={"mb-14"} style={{ position: "relative" }}>
        <Grid item className={classes.insightTile}>
          <Grid container alignItems="center" className="mb-1">
            {!loading && (
              <Grid item>
                <DragHandle />
              </Grid>
            )}
            <Grid item>
              <TypographyComponent
                variant="h4"
                className="txtBold fontSize16  ml-1"
              >
                {"HotSpot"}
              </TypographyComponent>
            </Grid>
            <Grid item className={classes.viewActionButton}>
              <Grid container>
                <CacheComponent
                  isCached={isCached}
                  chart="hotspot"
                  timeout={30000}
                  onUpdate={() => {
                    rlChart('4', true);
                  }}
                />
                {chartData && chartData.length > 0 ? (
                  <Grid className={classes.IconContainer}>
                    <Tooltip title={"Excel Download"} style={{ paddingLeft: "8px", paddingTop: "12px" }} arrow>
                      <IconButton
                        className={classes.IconContainer}
                        onClick={() => {
                          excelDownload();
                        }}
                        size="large"
                      >
                        <Grid className={classes.IconContainer}>
                          {Download()}
                        </Grid>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                ) : null}

                <Grid className={classes.IconContainer}>
                  <Tooltip title={"Expand"} arrow>
                    <IconButton
                      className={classes.IconContainer}
                      onClick={() => {
                        openPopup();
                      }}
                      size="large"
                    >
                      <Grid className={classes.IconContainer}>{Expand()}</Grid>
                    </IconButton>
                  </Tooltip>
                </Grid>
                <Grid className={classes.IconContainer}>
                  <Tooltip title={"Close"} arrow>
                    <IconButton
                      className={classes.IconContainer}
                      onClick={() => handleClose("4")}
                      size="large"
                    >
                      <Grid className={classes.IconContainer}>
                        {DashboardClose()}
                      </Grid>
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>

          <Grid container>
            <Grid item md={5}>
              <Grid container>
                <Grid md={2} className={"pt-1 paddingLefts"}>
                  <TypographyComponent
                    className={"txtBold txtleft"}
                    title={ReportsConstants.VIEW_BY}
                    variant={"h6"}
                  ></TypographyComponent>
                </Grid>
                <Grid md={7} className={"pr-1"}>
                  <Autocomplete
                    id={"HotspotRGI"}
                    disableClearable
                    autoHighlight
                    blurOnSelect
                    options={metadataWithRGI}
                    value={getOptionSelected(metadataWithRGI, selectedRgi)}
                    className={`input-value-editor autocomplete heightone  ${classes.boxMaxWidth}`}
                    getOptionLabel={(option) => option.value || ""}
                    onChange={(e, val) => {
                      selectedRgi = val.key;
                      saveReportPreferences();
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant={"outlined"}
                        className={`${"height28 conversionAutoComplete boderRadius4"}`}
                        placeholder="Select Field"
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {!loading &&
            <Grid container>
              <Grid item sm={12} className={"mt-1"}>
                <HotspotTable
                  zoom={false}
                  updateSelectedType={updateSelectedType}
                  selectedType={selectedType}
                  classes={classes}
                  keydriver={keydriver}
                  applyFilters={applyFilters}
                  filtersApplied={filtersApplied}
                />
              </Grid>
            </Grid>
          }
          {(loading) && (
            <LoaderComponent></LoaderComponent>
          )}
        </Grid>
      </Grid>
      {showPopup && (
        <DialogComponent open={showPopup} fullScreen={true}>
          <HotspotPopup
            updateSelectedType={updateSelectedType}
            selectedType={selectedType}
            classes={classes}
            onClose={onClose}
            zoom={true}
            keydriver={keydriver}
            applyFilters={applyFilters}
            filtersApplied={filtersApplied}
          />
        </DialogComponent>
      )}
    </React.Fragment>
  );
}
// default props
Hotspot.defaultProps = {
  classes: {},
  isUpdate: false,
  preference: {},
  updateReportPreference: () => { },
  handleClose: () => { },
  stopChartReload: () => { },
  filtersAppliedChange: () => {},
};

// prop types
Hotspot.propTypes = {
  classes: PropTypes.object,
  isUpdate: PropTypes.bool,
  preference: PropTypes.object,
  updateReportPreference: PropTypes.func,
  handleClose: PropTypes.func,
  stopChartReload: PropTypes.func,
  filtersAppliedChange: PropTypes.func,
};

export default Hotspot;
