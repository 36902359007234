import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import withStyles from '@mui/styles/withStyles';
import IconButton from "@mui/material/IconButton";
import { Tooltip, Button, Grid } from "@mui/material";
import _ from "lodash";

// Tabs Style
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

// Import Styles and Image
import TabStyle from "../styles/METabStyles";
import {
  SettingGrey, SettingOrange,
  ReportDownload, Plus
} from "../../../../../assets/images/svgComponents/ImageComponent";

// Components
import SwitchComponent from "../../../../../components/SwitchComponent/SwitchComponent";
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import Dialog from "../../../../../components/DialogComponent/DialogComponent";

import OptionsMenu from '../OptionsMenu'

// Import History
import history from "../../../../../config/history";

// Import Actions
import { meSurveyAction, excelAction } from "../../../../../redux/actions";

import DataFilter from "../DrawerPanels/DataFilter/DataFilter"
import Rater from "../DrawerPanels/Rater/Rater"
import ManageRelationship from "../DrawerPanels/ManageRelationship/ManageRelationship"
import { meSurveyConstants } from "../../../../../config/constants";

// Set Tabs Id and Control Name
function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const METabs = (props) => {
  const [openDrawer, setOpenDrawer] = useState(false)
  const [popup, setPopup] = useState(null)
  // Define Dispatch
  const dispatch = useDispatch();
  const { dataFilter, editRaters,
    selectedParticipants, isAlreadySaved, dup_selectedParticipants, assign } = useSelector(state => state.managerEffectiveness);
  const { getOne } = editRaters;
  const { showOnlyManager, filterData } = dataFilter

  const { match, location, classes, survey_id, editDisabled } = props;

  // has un saved selection
  const isUnsaved = _.difference(selectedParticipants, dup_selectedParticipants).filter(f => f !== "all").length || _.difference(dup_selectedParticipants, selectedParticipants).filter(f => f !== "all").length

  // Handle Tabs Change Event
  const handleChange = (event, newValue) => {
    // clear search when tab change
    if (newValue !== location.pathname) {
      dispatch(meSurveyAction.clearSearchValues())
      history.push({
        pathname: newValue
      });
    }
  };

  const onMenuSelect = (menuName) => {
    switch (menuName) {
      case 'import':
        if (isAlreadySaved) {
          history.push({
            pathname: `${match.url}/import`
          });
        } else {
          setPopup({ text: "Please Save before Importing Raters." })
        }
        break;
      case 'relationship':
        setOpenDrawer(menuName)
        break;
      case 'unassign_raters':
        if (isAlreadySaved) {
          downloadRaters({
            type: "unassign_raters"
          })
        } else {
          setPopup({ text: "Please Save before Exporting Unassing Raters." })
        }
        break;
      default:
    }
  }

  // isRaters tab
  const isRaterTab = `${match.url}/raters` === location.pathname

  const downloadRaters = (data = {}) => {
    dispatch(excelAction.download({
      excelType: meSurveyConstants.ME_PARTICIPANT_DOWNLOAD,
      survey_id: survey_id,
      selectedParticipants: selectedParticipants,
      ...data
    }))
  }

  return <>
    <AppBar
      position="static"
      color="default"
      className={`${classes.rightPanelAppBarRoot} ${"tabborder_none pe_color_appbar"}`}>
      <Tabs
        value={location.pathname}
        onChange={handleChange}
        component="div"
        variant="scrollable"
        scrollButtons="auto"
        aria-label="ME Tabs"
        className={classes.rightPanelTabsRoot}>
        <Tab
          value={`${match.url}`}
          style={{ width: "81px", height: "45px" }}
          label="Subjects"
          {...a11yProps(0)}
          classes={{
            root: classes.rightPanelTabRoot,
            selected: classes.rightPanelTabSelected,
          }}
        />
        <Tab
          value={`${match.url}/raters`}
          style={{ width: "67px", height: "45px" }}
          label="Raters"
          {...a11yProps(0)}
          disabled={(selectedParticipants.length === 0 || isUnsaved) && !isRaterTab}
          classes={{
            root: classes.rightPanelTabRoot,
            selected: classes.rightPanelTabSelected,
          }}
        />
        <div className={`${classes.customRightMenus} ${isRaterTab ? 'space-bw' : ''}`}>
          {
            match.url === location.pathname && (
              <>
                <div className={classes.customRightMenu}>
                  <SwitchComponent item xs={12}
                    name={"status"}
                    color="primary"
                    checked={showOnlyManager}
                    handleChange={({ value }) => {
                      // remove select all option when toggle changes
                      dispatch(meSurveyAction.updateSelectedParticipants(
                        selectedParticipants.filter(f => f !== "all")
                      ))
                      dispatch(meSurveyAction.dataFilterUpdate({
                        ...dataFilter,
                        showOnlyManager: !showOnlyManager,
                        filterChanged: true
                      }))
                    }}
                    label={
                      <span>
                        <TypographyComponent
                          variant="h6"
                        >
                          Show Only Managers
                        </TypographyComponent>
                      </span>
                    }
                  />
                </div>
                <div className={classes.customRightMenu}>
                  <Tooltip title={"Data Filter"} arrow>
                    <IconButton
                      className={classes.IconSaveContainer}
                      onClick={() => {
                        setOpenDrawer('datafilter')
                        dispatch(meSurveyAction.getDemographics())
                      }}
                      size="large">
                      {filterData.selectedEmpIds.length > 0 ? SettingOrange() : SettingGrey()}
                    </IconButton>
                  </Tooltip>
                </div>
              </>
            )
          }
          {
            (isRaterTab && assign.data.length > 0) && (
              <>
                <div className={`${classes.customRightMenu} Plusicon`}>
                  {
                    !editDisabled && (
                      <Tooltip title={"Add Rater"} arrow>
                        <IconButton
                          className={classes.IconSaveContainer}
                          onClick={() => {
                            dispatch(meSurveyAction.addOrEditRater({
                              ...editRaters,
                              getOne: {}
                            }))
                          }}
                          size="large">
                          {Plus()}
                        </IconButton>
                      </Tooltip>
                    )
                  }
                </div>
                <div className={classes.customRightMenu}>
                  <div className={classes.customRightMenu}>
                    <Tooltip title={"Download All"} arrow>
                      <IconButton
                        className={classes.IconSaveContainer}
                        onClick={() => {
                          if (isAlreadySaved) {
                            downloadRaters({ type: "raters_tab" })
                          } else {
                            setPopup({ text: "Please Save before Downloading Raters." })
                          }
                        }}
                        size="large">
                        {ReportDownload()}
                      </IconButton>
                    </Tooltip>
                  </div>
                  <div className={classes.customRightMenu}>
                    {
                      !editDisabled && (
                        <OptionsMenu
                          pop=""
                          onMenuSelect={onMenuSelect}
                          menus={[
                            {
                              _id: 'import',
                              name: 'Import'
                            },
                            {
                              _id: 'relationship',
                              name: 'Manage Rater Groups'
                            },
                            {
                              _id: 'unassign_raters',
                              name: 'Unassign Raters'
                            }
                          ]}
                        />
                      )
                    }
                  </div>
                </div>
              </>
            )
          }
        </div>
      </Tabs>
    </AppBar>
    <DataFilter
      open={openDrawer === 'datafilter'}
      onClose={setOpenDrawer}
      isRaterTab={isRaterTab}
    />
    <Rater
      open={getOne !== null}
      onClose={setOpenDrawer}
      data={{ _id: null }}
      survey_id={survey_id}
    />
    <ManageRelationship
      open={openDrawer === 'relationship'}
      onClose={setOpenDrawer}
      survey_id={survey_id}
    />
    {
      popup !== null && (
        <Dialog handleClose={() => setPopup(null)} open={popup !== null}>
          <Grid xs={12} style={{ textAlign: "center", padding: '8px' }}>
            {popup.text}
          </Grid>
          <Grid xs={12} style={{ textAlign: "center", padding: '8px' }}>
            <Button
              onClick={() => setPopup(null)}
              fullWidth={false}
              variant="contained"
              disableElevation
              size="small"
              color="primary">
              OK
            </Button>
          </Grid>
        </Dialog>
      )
    }
  </>;
}

export default withStyles(TabStyle)(METabs);