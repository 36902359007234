/* eslint-disable no-sequences */
import { dashboardConstant } from "../constants";

export const topicSelectedInitState = {
  item: "",
  word: "",
  data: [],
  wordCount: 0,
  type: "Topics",
  mainWord: ""
}

// Set Initial State
const initialState = {

  //Current selected tab name
  currentTab: "reports",

  //To control all charts loading
  initialReload: {
    overAllDashboard: false,
    hlsinitialReload: false,
    commentinitialReload: false,
    yesNoinitialReload: false,
    gridinitialReload: false,
    hotspotinitialReload: false,
    EIinitialReload: false,
    totinitialReload: false
  },

  // Highlevel Summery Chart Data
  highlevelSummary: {
    summaryWidgetColumnsDropdown: [],
    selectedSummaryWidgetColumnsDropdown: [],
    hlsData: {
      category: {}
    },
    hcData: {},
    showPopup: false,
    loading: true,
    his_loading: true,
    filtersApplied: {},
  },

  // Comment Analysis Chart Data
  commentAnalysis: {
    nlpData: {
      baseQusId: "",
      quesText: "",
      frequency: {},
      sentiment: {},
      topicCount: {},
      topics: {},
      wordCloud: {},
      allAns: [],
    },
    questions: [],
    showPopup: false,
    loading: false,
    questionLoading: true,
    chartLoad: false
  },

  // Yes No Chart Data
  yesNo: {
    yesno: {},
    metadataDispname: {},
    metadataKeyname: {},
    showPopup: false,
    loading: true
  },

  // Grid Chart
  gridChart: {
    gridData: {},
    showPopup: false,
    loading: false
  },

  // Hotspot Chart
  hotspot: {
    data: [],
    header2: [],
    color: [],
    sorting: {},
    showPopup: false,
    loading: true,
    filtersApplied: {},
  },

  // Engagement Index Chart
  EiKeyArr: [
    "Actively Disengaged",
    "Opportunity Group",
    "Engaged/Contributor",
    "Actively Engaged",
  ],
  engagementIndex: {
    EiOverall: [],
    EiRgi: [],
    showPopup: false,
    loading: false,
    overall_loading: true,
    keyArr: [
      "Actively Disengaged",
      "Opportunity Group",
      "Engaged/Contributor",
      "Actively Engaged",
    ]
  },

  // Trends Over Time Chart
  trendsOverTime: {
    data: {},
    showPopup: false,
    loading: true,
    preference: []
  },

  // Competency Summary Chart Data
  competency: {
    category_group: [],
    item_group: [],
    showPopup: false,
    loading: true
  },

  dashboardData: {
    comments: 0,
    overallFavorable: "0.00",
    questions: [],
    saoa: [],
    keydriver: [],
    isCached: false,
    saoaCache: false,
    totalresponse: 0,
    participantInvited: 0,
    surveyQuestions: 0,
    totalSubjects: 0,
    totalRaters: 0,
    totalReviewItems: 0,
    dashBoardLoaderQuestion: true,
    dashBoardLoaderCount: true,
    dashboardActionLoader: true,
    dashBoardLoaderFavourableCategory: true,
    surveyFrequency: [],
    surveyTypes: [],
  },
  topicSelected: { ...topicSelectedInitState },
  reload: {
    '0': true,
    '1': true,
    '2': true,
    '3': true,
    '4': true,
    '5': true,
    '6': true
  },
  pdfLoader: false,
  isDataFiltersApplied: false,
};

export default function (state = initialState, action) {
  switch (action.type) {

    // Get Engagement index overall calculation
    case dashboardConstant.GET_EI_OVERALL_REQUEST:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, overall_loading: true, loading: true }
      };
    case dashboardConstant.GET_EI_OVERALL_SUCCESS:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, EiOverall: action.data.data || [], overall_loading: false, loading: false, keyArr: initialState.EiKeyArr }
      };
    case dashboardConstant.GET_EI_OVERALL_FAILURE:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, overall_loading: true, loading: true }
      };

    // Engagement Index Show Hide Popup
    case dashboardConstant.SHOW_EI_POPUP:
      return {
        ...state,
        engagementIndex: {
          ...state.engagementIndex,
          showPopup: action.data
        }
      };

    case dashboardConstant.SHOW_GRIDCHART_POPUP:
      return{
        ...state,
        gridChart: {
          ...state.gridChart,
          showPopup: action.data
        }
      }

    // Get Engagement index overall calculation
    case dashboardConstant.GET_EI_RGI_REQUEST:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, loading: true }
      };
    case dashboardConstant.GET_EI_RGI_SUCCESS:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, EiRgi: action.data.data || [], loading: false },
        initialReload: { ...state.initialReload, EIinitialReload: true }
      };
    case dashboardConstant.GET_EI_RGI_FAILURE:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, loading: true }
      };

    // Dashboard get request/failure
    case dashboardConstant.GET_DASHBOARD_REQUEST, dashboardConstant.GET_DASHBOARD_FAILURE:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          ...initialState.dashboardData,
          dashboardActionLoader: true
        }
      };
    // Dashboard get success
    case dashboardConstant.GET_DASHBOARD_SUCCESS:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          saoa: action.data.data,
          isCached: action.data.isCached ? action.data.isCached : false,
          dashboardActionLoader: false
        },
        initialReload: {
          ...state.initialReload,
          overAllDashboard: true
        }
      };

    // Dashboard action get request/failure
    case dashboardConstant.GET_DASHBOARD_OVERALL_REQUEST:
      return {
        ...state,
        dashboardData: {
          dashBoardLoaderQuestion: false,
          dashBoardLoaderCount: false,
          dashBoardLoaderFavourableCategory: false,
          ...state.dashboardData,
          // dashboardActionLoader: true
        }
      };
    case dashboardConstant.GET_DASHBOARD_OVERALL_FAILURE:
      return {
        ...state,
        dashboardData: {
          dashBoardLoaderQuestion: false,
          dashBoardLoaderCount: false,
          dashBoardLoaderFavourableCategory: false,
          ...state.dashboardData,
          // dashboardActionLoader: false
          // ...initialState.dashboardData
        }
      };
    // Dashboard action get success
    case dashboardConstant.GET_DASHBOARD_OVERALL_SUCCESS:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          ...action.data.data,
          dashBoardLoaderQuestion: action.data.type === "questions" ? false : state.dashboardData.dashBoardLoaderQuestion,
          dashBoardLoaderCount: action.data.type === "count" ? false : state.dashboardData.dashBoardLoaderCount,
          dashBoardLoaderFavourableCategory: action.data.type === "favourableCategory" ? false : state.dashboardData.dashBoardLoaderFavourableCategory,
          // dashboardActionLoader: false
        }
      };

    // Update historical engagement data when toggle is off
    case dashboardConstant.ENGAGEMENT_HISTORICAL_UPDATE:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          historical_engagementData: {
            "showComparison": false,
            "singleEngagement": 0
          }
        }
      };

    // dashboard get participant invite request/failure
    case dashboardConstant.GET_PARTICIPANT_INVITE_REQUEST:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          totalInvite: 0,
          // dashboardActionLoader: true
        }
      }
    case dashboardConstant.GET_PARTICIPANT_INVITE_FAILURE:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          totalInvite: 0,
          // dashboardActionLoader: false
        }
      }
    // dashboard get participant invite success
    case dashboardConstant.GET_PARTICIPANT_INVITE_SUCCESS:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          totalInvite: action.data.data.length > 0 ? action.data.data[0].myCount : 0,
          // dashboardActionLoader: false
        }
      }

    // Get highlevel Summary Data
    case dashboardConstant.GET_HLS_REQUEST:
      return {
        ...state,
        highlevelSummary: { ...state.highlevelSummary, loading: true }
      };
    case dashboardConstant.GET_HLS_SUCCESS:
      if (action.data.data) {
        return {
          ...state,
          highlevelSummary: {
            ...state.highlevelSummary,
            ...action.data.data,
            loading: false,
          },
          initialReload: { ...state.initialReload, hlsinitialReload: state.currentTab === "insight" ? true : false }
        };
      }
      else {
        return {
          ...state,
          highlevelSummary: {
            ...state.highlevelSummary,
            hlsData: {
              category: {}
            },
            loading: false
          }
        };
      }
    case dashboardConstant.GET_HLS_FAILURE:
      return {
        ...state,
        highlevelSummary: { ...state.highlevelSummary, loading: false }
      };

    // update summary widget columns
    case dashboardConstant.UPDATE_SUMMARY_WIDGET_COLUMNS:
      return{
        ...state,
        highlevelSummary:{...state.highlevelSummary,
        summaryWidgetColumnsDropdown: action.data
        }
      }

    // update summary widget columns
    case dashboardConstant.UPDATE_SELECTED_SUMMARY_WIDGET_COLUMNS_IN_DASHBOARD:
      return{
        ...state,
        highlevelSummary:{...state.highlevelSummary,
          selectedSummaryWidgetColumnsDropdown: action.data
        }
      }

    case dashboardConstant.GET_HLS_UPDATE:
      return {
        ...state,
        highlevelSummary: { ...action.data, loading: true }
      };
    case dashboardConstant.SHOW_HIGH_LEVEL_SUMMERY_POPUP:
      return {
        ...state,
        highlevelSummary: {
          ...state.highlevelSummary,
          showPopup: action.data
        }
      };

    case dashboardConstant.UPDATE_EI_KEYS_DATA:
      return {
        ...state,
        engagementIndex: { ...state.engagementIndex, keyArr: action.data }
      };

    case dashboardConstant.UPDATE_GRID_KEYS_DATA:
      const quesId = action.data.quesId
      const keyArr = action.data.keyArr
      return {
        ...state,
        gridChart: {
          ...state.gridChart,
          gridData: {
            ...state.gridChart.gridData,
            [quesId]: {
              ...state.gridChart.gridData[quesId],
              finanlResponse: {
                ...state.gridChart.gridData[quesId].finanlResponse,
                keyArr: keyArr
              }

            }
          }
        }
      };

    // Get HISTORICAL Data
    case dashboardConstant.GET_HISTORICAL_REQUEST:
      return {
        ...state,
        highlevelSummary: { ...state.highlevelSummary, his_loading: true }
      };
    case dashboardConstant.GET_HISTORICAL_SUCCESS:
      return {
        ...state,
        highlevelSummary: {
          ...state.highlevelSummary,
          hcData: action.data.data,
          his_loading: false
        }
      };
    case dashboardConstant.GET_HISTORICAL_FAILURE:
      return {
        ...state,
        highlevelSummary: { ...state.highlevelSummary, his_loading: false }
      };

    // Get Commment Analysis Question Data
    case dashboardConstant.GET_COMMENT_QUESTION_REQUEST:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          questionLoading: true
        }
      };
    case dashboardConstant.GET_COMMENT_QUESTION_SUCCESS:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          questions: action.data.data,
          questionLoading: false
        }
      };
    case dashboardConstant.GET_COMMENT_QUESTION_FAILURE:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          questionLoading: false
        }
      };
    case dashboardConstant.UPDATE_COMMENT_QUESTION:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          questions: [],
        },
        // initialReload: { ...state.initialReload, commentinitialReload: false }
      };

    // Get Commment Analysis Question Data
    case dashboardConstant.GET_COMMENT_ANALYSIS_REQUEST:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          loading: true
        }
      };
    case dashboardConstant.GET_COMMENT_ANALYSIS_SUCCESS:
      const baseQusId = action.data.data.baseQusId
      const index = state.commentAnalysis.questions.findIndex(f=>(f.baseQusId === baseQusId))
      if(index >= 0){
        action.data.data.quesText = state.commentAnalysis.questions[index].question
      }
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          nlpData: action.data.data,
          loading: false
        },
        initialReload: { ...state.initialReload, commentinitialReload: state.currentTab === "insight" ? true : false }
      };
    case dashboardConstant.GET_COMMENT_ANALYSIS_FAILURE:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          loading: false
        }
      };

    // Get TOT Data
    case dashboardConstant.GET_TOT_REQUEST:
      return {
        ...state,
        trendsOverTime: { ...state.trendsOverTime, loading: true }
      };
    case dashboardConstant.GET_TOT_SUCCESS:
      return {
        ...state,
        trendsOverTime: { ...state.trendsOverTime, data: action.data.data, loading: false },
        initialReload: { ...state.initialReload, totinitialReload: state.currentTab === "insight" ? true : false }
      };
    case dashboardConstant.GET_TOT_FAILURE:
      return {
        ...state,
        trendsOverTime: { ...state.trendsOverTime, loading: false }
      };

    case dashboardConstant.UPDATE_TOT_PREFERENCE:
      return {
        ...state,
        trendsOverTime: { ...state.trendsOverTime, preference: [...action.data] }
      }

    // Get Yes No Data
    case dashboardConstant.GET_YESNO_REQUEST:
      return {
        ...state,
        yesNo: { ...state.yesNo, loading: true }
      };
    case dashboardConstant.GET_YESNO_SUCCESS:
      let yesNoData = action.data.data && Object.keys(action.data.data).length ? action.data.data : initialState.yesNo
      return {
        ...state,
        yesNo: { ...yesNoData, loading: false },
        initialReload: { ...state.initialReload, yesNoinitialReload: state.currentTab === "insight" ? true : false }
      };
    case dashboardConstant.GET_YESNO_FAILURE:
      return {
        ...state,
        yesNo: { ...state.yesNo, loading: false }
      };
    case dashboardConstant.SHOW_YESNO_POPUP:
      return {
        ...state,
        yesNo: {
          ...state.yesNo,
          showPopup: action.data
        }
      };

    // Get Hotspot Data
    case dashboardConstant.GET_HOTSPOT_REQUEST:
      return {
        ...state,
        hotspot: { ...state.hotspot, loading: true }
      };
    case dashboardConstant.GET_HOTSPOT_SUCCESS:
      return {
        ...state,
        hotspot: {
          ...state.hotspot,
          ...action.data.data,
          loading: false,
          filtersApplied: state?.['hotspot']?.['filtersApplied'] || initialState?.['hotspot']?.['filtersApplied']
        },
        initialReload: { ...state.initialReload, hotspotinitialReload: state.currentTab === "insight" ? true : false },
        reload: {
          ...state.reload,
          4: false
        }
      };
    case dashboardConstant.GET_HOTSPOT_FAILURE:
      return {
        ...state,
        hotspot: { ...state.hotspot, loading: false }
      };

    //update hotspot reducer
    case dashboardConstant.UPDATE_HOTSPOT_DATA:
      return {
        ...state,
        hotspot: { ...initialState.hotspot, ...action.data }
      };
    case dashboardConstant.SHOW_HOTSPOT_POPUP:
      return {
        ...state,
        hotspot: {
          ...state.hotspot,
          showPopup: action.data
        }
      };

    //update nlp selected type reducer
    case dashboardConstant.UPDATE_NLP_SELECTED_DATA:
      return {
        ...state,
        topicSelected: action.data,
        commentAnalysis: {
          ...state.commentAnalysis
        }
      };

    //update nlp selected type reducer
    case dashboardConstant.UPDATE_CHART_DATAS:
      return {
        ...state,
        highlevelSummary: initialState.highlevelSummary,
        trendsOverTime: initialState.trendsOverTime,
        hotspot: initialState.hotspot,
        yesNo: initialState.yesNo,
        gridChart: initialState.gridChart,
        engagementIndex: initialState.engagementIndex
      };

    // Get Grid Data
    case dashboardConstant.GET_GRID_REQUEST:
      return {
        ...state,
        gridChart: { ...state.gridChart, loading: true }
      };
    case dashboardConstant.GET_GRID_SUCCESS:
      return {
        ...state,
        gridChart: {
          ...state.gridChart,
          gridData: action.data.data,
          loading: false
        },
        initialReload: { ...state.initialReload, gridinitialReload: state.currentTab === "insight" ? true : false }
      };
    case dashboardConstant.GET_GRID_FAILURE:
      return {
        ...state,
        gridChart: { ...state.gridChart, loading: false }
      };

    //download dashboard report
    case dashboardConstant.DOWNLOAD_DASHBOARD_REQUEST:
      return {
        ...state,
        pdfLoader: true
      };
    case dashboardConstant.DOWNLOAD_DASHBOARD_SUCCESS:
      return {
        ...state,
        pdfLoader: false
      };
    case dashboardConstant.DOWNLOAD_DASHBOARD_FAILURE:
      return {
        ...state,
        pdfLoader: false
      };
    case dashboardConstant.CHARTS_INSINGT_RELOAD:
      return {
        ...state,
        reload: action.data
      }
    case dashboardConstant.GET_COMPETENCY_REQUEST:
      return {
        ...state,
        competency: {
          ...state.competency,
          loading: true
        }
      }
    case dashboardConstant.GET_COMPETENCY_FAILURE:
      return {
        ...state,
        competency: {
          ...state.competency,
          loading: false
        }
      }
    case dashboardConstant.GET_COMPETENCY_SUCCESS:
      return {
        ...state,
        competency: {
          ...state.competency,
          category_group: [],
          item_group: [],
          ...(action.data.data || {}),
          loading: false
        }
      };

    case dashboardConstant.RESET_CHART_DATA_REQUEST:
      const isRetainQuestionFilters = action?.['params'] === 'retainQuestionFilters';
      return {
        ...state,
        trendsOverTime: initialState.trendsOverTime,
        hotspot: initialState.hotspot,
        highlevelSummary: {
          ...state.highlevelSummary,
          filtersApplied: isRetainQuestionFilters ? state?.['highlevelSummary']?.['filtersApplied'] : {}
        },
        hotspot: {
          ...state.hotspot,
          filtersApplied: isRetainQuestionFilters ? state?.['hotspot']?.['filtersApplied'] : {}
        },
        isDataFiltersApplied: true,
        yesNo: initialState.yesNo,
        gridChart: initialState.gridChart,
        commentAnalysis: initialState.commentAnalysis,
        engagementIndex: initialState.engagementIndex,
        dashboardData: {
          ...state.dashboardData,
          dashBoardLoaderQuestion: true,
          dashBoardLoaderCount: true,
          dashBoardLoaderFavourableCategory: true,
          dashboardActionLoader: true
        }
      };

    case dashboardConstant.RESET_DATA_FILTERS_APPLIED:
      return {
        ...state,
        isDataFiltersApplied: false
      }

    case dashboardConstant.UPDATE_INITIAL_RELOAD:
      if (action.data === "all") {
        return {
          ...state,
          initialReload: {
            overAllDashboard: false,
            hlsinitialReload: false,
            commentinitialReload: false,
            yesNoinitialReload: false,
            gridinitialReload: false,
            hotspotinitialReload: false,
            EIinitialReload: false,
            totinitialReload: false
          },
        };
      } else {
        return {
          ...state,
          initialReload: {
            ...state.initialReload,
            [action.data]: false
          }
        };
      };

    case dashboardConstant.UPDATE_TAB_NAME:
      let tabName = action.data.substring(action.data.lastIndexOf("/") + 1)
      return {
        ...state,
        currentTab: tabName.toString()
      };

    case dashboardConstant.RESET_COMMENT_CHART:
      return {
        ...state,
        loading: false,
        commentAnalysis: initialState.commentAnalysis,
      };


    case dashboardConstant.GET_KEY_DRIVER_SUCCESS:
      let keyDriverData = action.data.data.splice(0, 10) || []
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          keydriver: keyDriverData
        }
      };

    case dashboardConstant.UPDATE_COMMENT_CHART_STATUS:
      return {
        ...state,
        commentAnalysis: {
          ...state.commentAnalysis,
          chartLoad: action.data
        }
      }

    case dashboardConstant.SAOA_CACHE_UPDATE:
      return {
        ...state,
        dashboardData: {
          ...state.dashboardData,
          saoaCache: true
        }
      }

    case dashboardConstant.APPLY_HOTSPOT_FILTERS:
      return {
        ...state,
        hotspot: {
          ...state.hotspot,
          filtersApplied: action.data
        }
      }

    case dashboardConstant.APPLY_HLS_FILTERS:
      return {
        ...state,
        highlevelSummary: {
          ...state.highlevelSummary,
          filtersApplied: action.data
        }
      }

    default:
      return state;
  }
}