import React, { useEffect } from 'react'
import { useDispatch } from "react-redux";
import { Grid } from "@mui/material";

import withStyles from '@mui/styles/withStyles';

// import Components
import DropZoneComponent from "../../../../../components/DropZoneComponent/DropZoneComponent";

// Import Table Size
import { getTableHeight } from "../../../../../config/layout";

// import constants
import { excelConstants } from "../../../../../config/constants";

// Import Actions
import { excelAction, alertAction, meSurveyAction } from "../../../../../redux/actions";

import TabStyle from "../styles/METabStyles";

// Import History
import history from "../../../../../config/history";

const ImportME = (props) => {

    const { survey_id, classes, editDisabled } = props;

    const tableHeight = getTableHeight(180);
    const dispatch = useDispatch()

    /**
  * Get raters list along survey name for display purpose only
  */
    useEffect(() => {
        dispatch(meSurveyAction.raters(1, 1, "subject_id", "asc", [], [], survey_id));
    }, [dispatch]);

    // update file data in reducer and redirect to submit page
    const importFileData = (file) => {
        const fileData = file ? file : {};
        if (fileData && fileData.path) {
            const excelType = excelConstants.ME_PARTICIPANT_UPLOAD;
            var formData = new FormData();
            formData.append("file", fileData);
            formData.append("excelType", excelType);
            formData.append("survey_id", survey_id)
            dispatch(excelAction.upload(formData, excelType, () => {
                history.push({
                    pathname: `/manage_people/participants/me-survey/assign/${survey_id}/raters`
                })
            }));
        } else {
            dispatch(alertAction.error("Please select a file to upload"));
        }
    };
    
    if (editDisabled) return null

    return (
        <Grid
            container
            className={`${"scrolly scrollContainer"} ${classes.root}`}
            style={{
                height: `${tableHeight}px`
            }}
        >
            <DropZoneComponent
                accept=".xlsx"
                textData={"Drop files here or click to"}
                cancelBtnURL={`/manage_people/participants/me-survey/assign/${survey_id}/raters`}
                importFileData={importFileData}
            />
        </Grid>
    )
}

export default withStyles(TabStyle)(ImportME)