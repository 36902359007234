import { takeSurveyConstant } from "../constants/takeSurveyConstant";

const initialState = {
  getOne: {
    _id: "",
    name: "",
    type: "",
    description: "",
    status: "",
    expiry: "",
    distribute: "",
    isDelete: "",
    catOrder: "",
    catDetails: "",
    languages: "",
    pulse_obj: "",
    startDate: "",
    endDate: "",
  },
  questions: {
    _id: "",
    type: "",
    name: "",
    catOrder: [],
    questions: [],
    catDetails: {},
    survey_name: {},
    languages: [
      {
        code: "en",
        name: "English",
        active: true,
      },
    ],
  },
  client: {
    name: "",
    logo: "",
    _id: "",
  },
  participant: {
    firstName: "",
    lastName: "",
    _id: "",
    kiosk_username: "",
    kiosk_password: "",
  },
  isLoading: false,
  activeCategoryTab: 0,
  surveyLang: "en",
  preResponse: [],
  response: [],
  validatedQues: [],
  surveyRequiredQuestions: {},
  completionPercentage: 0,
  surveyActionStatus: "welcome",
  surveyEditResponse: false,
  rgResponses: {},
  categoryQuestions: [],
  anonymousUserData: null,
  surveyDeviceType: "Internet",
  takeSurveyUrl: "",
  //Survey Auth Prams
  surveyAuthType: null,
  surveyAuthValidate: false,
  takeSurveyStatus: false,
  takeSurveyErrors: null,
  //anonymous user multiple Response Flag
  surveyUpdateFlag: false,
  callerId: null, // Caller Id Only For Sp Caller Take survey
  spCallerHistory: [],
  firstRender: false,
  surveyStartsAt: null,
  assignedDate: null,
  // Uncomment this code when want to redirect takesurvey to Production Take survey URL
  redirect: false
};

export default function (state = initialState, action) {
  switch (action.type) {
    // Survey Getone Reducer
    case takeSurveyConstant.SURVEY_GETONE_REQUEST:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: initialState.settings,
        surveyUpdateFlag: initialState.surveyUpdateFlag,
        surveyStartsAt: initialState.surveyStartsAt,
      };
    case takeSurveyConstant.SURVEY_GETONE_SUCCESS:
      return {
        ...state,
        getOne: action.data && action.data.getOneData ? action.data.getOneData : initialState.getOne,
        questions: action.data && action.data.surveyData ? action.data.surveyData : initialState.questions,
        client: action.data && action.data.surveyClient ? action.data.surveyClient : initialState.client,
        response: action.data && action.data.surveyResponse ? action.data.surveyResponse : initialState.response,
        preResponse: action.data && action.data.surveyResponse ? action.data.surveyResponse : initialState.preResponse,
        rgResponses: action.data && action.data.rgResponses ? action.data.rgResponses : initialState.rgResponses,
        participant: action.data && action.data.surveyParticipant ? action.data.surveyParticipant : initialState.participant,
        surveyLang: action.data && action.data.surveyDefaultLang ? action.data.surveyDefaultLang.code : initialState.surveyLang,
        surveyRequiredQuestions: action.data && action.data.surveyRequiredQuestionsObj ? action.data.surveyRequiredQuestionsObj : initialState.surveyRequiredQuestions,
        completionPercentage: action.data && action.data.surveyPercentage ? Math.round(action.data.surveyPercentage, 2) : initialState.completionPercentage,
        surveyAuthType: action.data && action.data.surveyAuthType ? action.data.surveyAuthType : initialState.surveyAuthType,
        surveyAuthValidate: action.data && action.data.surveyAuthValidate ? action.data.surveyAuthValidate : initialState.surveyAuthValidate,
        surveyEditResponse: action.data && action.data.surveyEditResponse ? action.data.surveyEditResponse : initialState.surveyEditResponse,
        takeSurveyStatus: action.data && action.data.takeSurveyStatus ? action.data.takeSurveyStatus : initialState.takeSurveyStatus,
        anonymousUserData: initialState.anonymousUserData,
        surveyDeviceType: action.data && action.data.surveyDeviceType ? action.data.surveyDeviceType : initialState.surveyDeviceType,
        takeSurveyUrl: action.data && action.data.takeSurveyUrl ? action.data.takeSurveyUrl : initialState.takeSurveyUrl,
        callerId: action.data && action.data.caller_id ? action.data.caller_id : initialState.caller_id,
        spCallerHistory: action.data && action.data.spCallerHistory ? action.data.spCallerHistory : initialState.spCallerHistory,
        firstRender: true,
        surveyStartsAt: new Date(),
        assignedDate: action.data && action.data.assignedDate ? action.data.assignedDate : initialState.assignedDate,
        // Uncomment this code when want to redirect takesurvey to Production Take survey URL
        redirect: action?.data?.redirect
      };
    case takeSurveyConstant.SURVEY_GETONE_CLEAR: {
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: initialState.settings,
        surveyStartsAt: initialState.surveyStartsAt,
      };
    }
    case takeSurveyConstant.SURVEY_GETONE_FAILURE:
      return {
        ...state,
        getOne: initialState.getOne,
        questions: initialState.questions,
        settings: initialState.settings,
        takeSurveyErrors: action.error,
      };
    case takeSurveyConstant.TAKE_SURVEY_AUTH_REQUEST:
      return {
        ...state,
      };
    case takeSurveyConstant.TAKE_SURVEY_AUTH_SUCCESS:
      return {
        ...state,
        surveyAuthValidate: true,
      };
    case takeSurveyConstant.TAKE_SURVEY_AUTH_FAILURE:
      return {
        ...state,
        surveyAuthValidate: initialState.surveyAuthValidate,
      };
    case takeSurveyConstant.TAKE_SURVEY_AUTH_CLEAR:
      return {
        ...state,
        surveyAuthValidate: initialState.surveyAuthValidate,
      };
    case takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_REQUEST:
      return {
        ...state,
      };
    case takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_SUCCESS:
      return {
        ...state,
        questions: action.data && action.data.surveyData ? action.data.surveyData : initialState.questions,
        response: action.data && action.data.surveyResponse ? action.data.surveyResponse : initialState.response,
        preResponse: action.data && action.data.surveyResponse ? action.data.surveyResponse : initialState.preResponse,
        rgResponses: action.data && action.data.rgResponses ? action.data.rgResponses : initialState.rgResponses,
        participant: action.data && action.data.surveyParticipant ? action.data.surveyParticipant : initialState.participant,
        surveyLang: action.data && action.data.surveyDefaultLang ? action.data.surveyDefaultLang.code : initialState.surveyLang,
        surveyRequiredQuestions: action.data && action.data.surveyRequiredQuestionsObj ? action.data.surveyRequiredQuestionsObj : initialState.surveyRequiredQuestions,
        completionPercentage: action.data && action.data.surveyPercentage ? Math.round(action.data.surveyPercentage, 2) : initialState.completionPercentage,
        surveyAuthType: action.data && action.data.surveyAuthType ? action.data.surveyAuthType : initialState.surveyAuthType,
        surveyAuthValidate: action.data && action.data.surveyAuthValidate ? action.data.surveyAuthValidate : initialState.surveyAuthValidate,
        surveyEditResponse: action.data && action.data.surveyEditResponse ? action.data.surveyEditResponse : initialState.surveyEditResponse,
        takeSurveyStatus: action.data && action.data.takeSurveyStatus ? action.data.takeSurveyStatus : initialState.takeSurveyStatus,
      };
    case takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_FAILURE:
      return {
        ...state,
        surveyAuthValidate: initialState.surveyAuthValidate,
      };
    case takeSurveyConstant.TAKE_SURVEY_KIOSK_AUTH_CLEAR:
      return {
        ...state,
        surveyAuthValidate: initialState.surveyAuthValidate,
      };
    case takeSurveyConstant.SURVEY_RESPONSE_UPDATE:
      return {
        ...state,
        response: action.data.surveyResponse,
        validatedQues: action.data.requiredQuesList,
        completionPercentage: Math.round(action.data.surveyPercentage),
        surveyRequiredQuestions: action.data.surveyRequiredQuestions,
        rgResponses: action.data.rgResponses,
      };
    case takeSurveyConstant.SURVEY_REQUIRED_QUESTIONS_UPDATE:
      return {
        ...state,
        validatedQues: action.data,
      };
    case takeSurveyConstant.TAKE_SURVEY_UPDATE_LANGUAGE:
      return {
        ...state,
        surveyLang: action.data,
      };
    case takeSurveyConstant.SURVEY_RESPONSE_UPDATE_SUCCESS:
      const surveyActionStatus = action.data.data.status == "success" && action.data.actionType == "submit" ? "success" : "open"
      return {
        ...state,
        surveyActionStatus: surveyActionStatus,
        surveyUpdateFlag: true,
        preResponse: state.response,
      };
    case takeSurveyConstant.TAKE_SURVEY_UPDATE_OPEN_STATUS:
      return {
        ...state,
        surveyActionStatus: "open",
      }
    // Survey Default Reducer Update
    default:
      return state;
  }
}
