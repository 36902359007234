import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { useBeforeunload } from "react-beforeunload";
import { isMobile } from "react-device-detect";

//Import Component
import CategoryTabs from "./CategoryTabs";
import QuestionsPanel from "./QuestionsPanel";
import ConfirmDialogComponent from "../../components/ConfirmDialogComponent/ConfirmDialogComponent";

// Import Config Layout
import { getQuestionPanelHeight } from "../../config/layout";

//Import Constants
import {
  surveyQuestionsConstants,
  TakeSurveyConstants,
} from "../../config/constants";

// Import Actions
import { takeSurveyAction, alertAction } from "../../redux/actions";
import { set } from "lodash";

let timer = null
function TakeSurveyForm(props) {
  // Define Dispatch
  const dispatch = useDispatch();
  const firstRender = useSelector((state) => state.takeSurvey.firstRender);
  const surveyLang = useSelector((state) => state.takeSurvey.surveyLang);
  const surveyResponse = useSelector((state) => state.takeSurvey.response);
  const surveyLastSavedResponse = useSelector(
    (state) => state.takeSurvey.preResponse
  );
  const surveyEditResponse = useSelector(
    (state) => state.takeSurvey.surveyEditResponse
  );
  const surveyRequiredQuestions = useSelector(
    (state) => state.takeSurvey.surveyRequiredQuestions
  );

  //get props
  const { classes, questionData } = props;
  const { questions, catOrder } = questionData;
  const { languages } = questionData;
  // const categoryCount = catOrder.length - 1;

  const questionPanelHeight =
    isMobile && languages.length > 1
      ? getQuestionPanelHeight() - 20
      : isMobile
        ? getQuestionPanelHeight() + 5
        : getQuestionPanelHeight() - 10;
  const [onLoadHeight, setOnLoadHeight] = useState(questionPanelHeight);

  //Tabs
  const [activeCategoryTab, setActiveCategoryTab] = useState(0);
  const [confirmAlert, setConfirmAlert] = useState(false);
  const [confirmStatus, setConfirmStatus] = useState(false);
  const [formSubmit, setFormSubmit] = useState(false);
  const [autoSaveCount, setAutoSaveCount] = useState(0);
  const [localSurveyResponse, setLocalSurveyResponse] = useState(
    surveyResponse
  );

  const validatedQues = useSelector((state) => state.takeSurvey.validatedQues);

  const activeCategories = questions.map(({ category }) => category);
  const newCatOrder = catOrder.filter((cat) => activeCategories.includes(cat));

  const oldActiveCategory =
    activeCategoryTab > 0 ? catOrder[activeCategoryTab] : newCatOrder[0];

  let newActiveTab = activeCategoryTab;
  if (activeCategoryTab > 0) {
    newActiveTab = Object.keys(newCatOrder).find(
      (key) => newCatOrder[key] === oldActiveCategory
    );
    newActiveTab = parseInt(newActiveTab);
  } else {
    newActiveTab = activeCategoryTab;
  }

  const categoryCount = newCatOrder.length - 1;

  function handleTabChange(newValue, type = null) {
    const activeCategory = newCatOrder[activeCategoryTab];
    const activeCatRequiredQues = questions.filter(
      (ques) => ques.category === activeCategory && ques.isRequired
    );

    let errorQuestions = [];
    if (activeCatRequiredQues.length > 0) {
      activeCatRequiredQues.forEach((ques) => {
        if (!surveyRequiredQuestions[ques.baseQusId]) {
          errorQuestions = [...errorQuestions, ques.baseQusId];
        }
      });
    }
    if (errorQuestions.length > 0) {
      const requiredQuesArr = [...validatedQues, ...errorQuestions];
      dispatch(takeSurveyAction.updateRequiredQuestions(requiredQuesArr));
    } else if (type) {
      handleSurveyContinue(type);
    } else {
      setActiveCategoryTab(newValue);
    }
  }

  function handleSurveyContinue(type = null) {
    let newValue =
      type === "prev" ? activeCategoryTab - 1 : activeCategoryTab + 1;

    if (activeCategoryTab < categoryCount) {
      setActiveCategoryTab(newValue);
      //Save Take survey response when continue button triggers
      dispatch(takeSurveyAction.updateSurveyResponse("continue"));
    } else if (newValue < categoryCount) {
      setActiveCategoryTab(newValue);
      //Save Take survey response when continue button triggers
      dispatch(takeSurveyAction.updateSurveyResponse("continue"));
    } else {
      if (inputValidationCheck()) {
        // "Survey Submit"
        handleSubmit();
      }
    }
  }

  // Auto Save Response

  function makeAutoSave() {
    if (surveyResponse !== surveyLastSavedResponse) {
      dispatch(takeSurveyAction.updateSurveyResponse("continue"));
    }
  }

  useEffect(() => {
    const AUTO_SAVE_INTERVAL = autoSaveCount > 0 ? 15000 : 15000;
    timer = setTimeout(() => {
      const newAutoSaveCount = autoSaveCount + 1;
      const currentDate = new Date();
      makeAutoSave();
      setAutoSaveCount(newAutoSaveCount);
    }, AUTO_SAVE_INTERVAL);
    return () => clearTimeout(timer);
  }, [surveyResponse]);
  //Auto Save End

  //Browser Close Response Save Event
  useBeforeunload(() => {
    dispatch(takeSurveyAction.updateSurveyResponse("continue"));
    return false;
  });
  //Browser Close Response Save Event

  useEffect(() => {
    if (formSubmit && surveyEditResponse) {
      dispatch(takeSurveyAction.updateSurveyResponse("submit"));
    } else if (!surveyEditResponse && confirmStatus) {
      dispatch(takeSurveyAction.updateSurveyResponse("submit"));
    }
  }, [formSubmit, confirmStatus, surveyEditResponse]);

  function inputValidationCheck() {
    let errorQuestions = [];
    if (questions.length > 0) {
      questions.forEach((ques) => {
        if (
          surveyRequiredQuestions.hasOwnProperty(ques.baseQusId) &&
          !surveyRequiredQuestions[ques.baseQusId]
        ) {
          errorQuestions = [...errorQuestions, ques.baseQusId];
        }
      });
    }
    if (surveyResponse.length == 0) {
      dispatch(
        alertAction.error(TakeSurveyConstants.ANSWER_ANY_ONE_QUES_ERROR_MESSAGE)
      );
      return false;
    } else if (errorQuestions.length > 0) {
      dispatch(takeSurveyAction.updateRequiredQuestions(errorQuestions));
      dispatch(
        alertAction.error(TakeSurveyConstants.ANSWER_ALL_REQUIRED_ERROR_MESSAGE)
      );
      return false;
    } else {
      return true;
    }
  }

  function handleSubmit() {
    if (!surveyEditResponse) {
      setConfirmAlert(true);
    }
    setFormSubmit(true);
  }

  function handleSubmitConfirm() {
    setConfirmStatus(true);
    setConfirmAlert(false);
  }

  function handleCloseConfirmAlert() {
    setConfirmAlert(false);
  }

  return (
    <>
      <Grid container alignItems="center">
        <CategoryTabs
          classes={classes}
          questionData={questionData}
          changeTabValue={handleTabChange}
          activeCategoryTab={activeCategoryTab}
          langCode={
            surveyLang ? surveyLang : surveyQuestionsConstants.DEFAULT_LANG_CODE
          }
        />
        <Grid
          item
          sm={12}
          style={{ height: onLoadHeight }}
          className={"scrolly bgwhite pr-1"}
        >
          <QuestionsPanel
            activeCategoryTab={activeCategoryTab}
            questionData={questionData}
            classes={classes}
            langCode={
              surveyLang
                ? surveyLang
                : surveyQuestionsConstants.DEFAULT_LANG_CODE
            }
            handleSurveyContinue={handleSurveyContinue}
            questionPanelHeight={onLoadHeight}
          ></QuestionsPanel>
        </Grid>
      </Grid>

      <ConfirmDialogComponent
        open={confirmAlert}
        title={"Submit Survey"}
        content={
          "Once you submit your survey, you will no longer be able to make changes to your responses. Submit survey?"
        }
        textTrueBtn="Submit"
        textFalseBtn="Cancel"
        handleConfirmAction={handleSubmitConfirm}
        handleCloseAction={handleCloseConfirmAlert}
      />
    </>
  );
}
// default props
TakeSurveyForm.defaultProps = {
  classes: {},
  questionData: { questions: [], catOrder: [], catDetails: {} },
};

// prop types
TakeSurveyForm.propTypes = {
  classes: PropTypes.object,
  questionData: PropTypes.object,
};
// export default TakeSurveyForm;
export default TakeSurveyForm;
