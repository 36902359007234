import React from "react";
import { Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
// import { useDispatch } from "react-redux";

//Image
import {Upload} from "../../../../../assets/images/svgComponents/ImageComponent";

//Import constants
import { excelConstants } from "../../../../../config/constants";


// Import History
import history from "../../../../../config/history";

function ConversionUpload (props) {
  // const dispatch = useDispatch();

  // get props
  const { classes } = props;

  return (
    <React.Fragment>
      <Tooltip arrow title={excelConstants.UPLOAD_CONVERSION}>
      <IconButton
        className={classes.demographyDialogBtn}
        onClick={(e) => { history.push({ pathname: "/manage_people/demographics/conversion/upload" }); }}
        size="large">
        {Upload()}
      </IconButton>
      </Tooltip>
    </React.Fragment>
  );
}
// default props
ConversionUpload.defaultProps = {
  classes: {},
  metadataSelected: {}

};

// prop types
ConversionUpload.propTypes = {
  classes: PropTypes.object,
  metadataSelected: PropTypes.object
};
export default ConversionUpload;
