import React, { useState } from "react";
import { useDispatch } from "react-redux";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";

// Import Styles and Image
import ReportsStyles from "../../../ReportsStyles";
import PlusImg from "../../../../../assets/images/svg/plus_grey.svg";
import MinusImg from "../../../../../assets/images/svg/minus_blue.svg";

// Import Component
import TypographyComponent from "../../../../../components/TypographyComponent/TypographyComponent";
import SearchInputComponent from "../../../../../components/SearchInputCompoment/SearchInputComponent";

//Import Actions
import { reportAction } from "../../../../../redux/actions";

function DemographicList(props) {
  const dispatch = useDispatch();
 
  // get props
  const {
    classes,
    currentDemographic,
    currentDemographicCount,
    allDemographicsItems,
    reportData,
    metadata
  } = props;

  // Set State
  const [search, setSearch] = useState("");
  const [expanded, setExpanded] = React.useState(false);

  // Get Demographic Items from DB 
  const getDemograpicsItemsReq = () => {
    dispatch(
      reportAction.demographicsByName({
        metadata: [],
        surveys: reportData.surveys,
        date: reportData.date,
        demographic: currentDemographic,
        search: "",
      })
    );
  };

  //handle panel open/close
  const handlePanelChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
    if (newExpanded) {
      //get RGI on  expand
      getDemograpicsItemsReq();
    }
  };

  // Get Demographic Name
  let selData=metadata.filter((e) => e.key === currentDemographic)
  const demographicName = selData[0] && selData[0].value?selData[0].value:"";
  //get RGI for cuttent Demographics
  let currentDemographItems = allDemographicsItems[currentDemographic] ? allDemographicsItems[currentDemographic]: [];
  //if search, update the data array
  if (search) {
    const tempData = allDemographicsItems[currentDemographic] ? allDemographicsItems[currentDemographic]: [];
    currentDemographItems = tempData.filter((d) => d["_id"].toLowerCase().match(search.toLowerCase()) != null);
  }

  //handle search
  const handleSearch = (name, value) => {
    setSearch(value);
  };

  // on clear search
  const handleClearSearch = () => {
    setSearch("");
  };

  //update reducer on checkbox change
  const updateDemographics = (e, item) => {
    if (e.target.checked) {
      if (reportData.demographics[currentDemographic]) {
        reportData.demographics[currentDemographic].push(item._id);
      } else {
        reportData.demographics = {
          ...reportData.demographics,
          [currentDemographic]: [item._id],
        };
      }
    } else {
      reportData.demographics[currentDemographic].splice(reportData.demographics[currentDemographic].indexOf(item._id),1);
    }
    //delete demographics if no item is selected
    if(reportData.demographics[currentDemographic].length===0){
      delete reportData.demographics[currentDemographic];
    }
    dispatch(reportAction.updateReport(reportData));
  };

  // generate demographics items
  const generateDemographicItems = () => {
    if (currentDemographItems) {
      return currentDemographItems.sort().map((item) => {
        return (
          <Grid item xs={12} key={item._id} className={`${classes.expansionDetail} pl-2`}>
            <Grid item className={classes.demographyCheckbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    name={item._id}
                    color="primary"
                    checked={
                      reportData.demographics[currentDemographic] &&
                      reportData.demographics[currentDemographic].includes(item._id) ? true : false
                    }
                    onChange={(e) => updateDemographics(e, item)}
                  />
                }
                label={`${item._id} (${item.data})`}
              />
            </Grid>
          </Grid>
        );
      });
    }
  };

  return (
    <React.Fragment>
      <Accordion
        classes={{ root: classes.peaccordion_nocolor_demography }}
        expanded={expanded === "demographyoptions-panel"}
        onChange={handlePanelChange("demographyoptions-panel")}
      >
        <AccordionSummary
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <TypographyComponent
            variant={"h6"}
            className={`${expanded === "demographyoptions-panel" ? "panel-active" : ""}`}
          >
            <Grid container alignItems="center">
              <img
                className={`${classes.expansionPlus} ${"expansionPlus pr-1"}`}
                src={PlusImg}
              ></img>
              <img
                className={`${classes.expansionMinus} ${"expansionMinus pr-1"}`}
                src={MinusImg}
              ></img>
              {`${demographicName} (${currentDemographicCount})`}
            </Grid>
          </TypographyComponent>
        </AccordionSummary>
        <AccordionDetails>
          <Grid key={currentDemographic}>
            <SearchInputComponent
              id={`${currentDemographic}search`}
              placeholder={"Search"}
              value={search}
              field={"search"}
              showClearIcon={true}
              fullWidth={true}
              onInputChangeRequest={handleSearch}
              handleClearSearch={handleClearSearch}
            />
            {generateDemographicItems()}
          </Grid>
        </AccordionDetails>
      </Accordion>
    </React.Fragment>
  );
}
// default props
DemographicList.defaultProps = {
  classes: {},
  currentDemographic:"",
  currentDemographicCount:0,
  allDemographicsItems:{},
  reportData:{},
  metadata:[]
};

// prop types
DemographicList.propTypes = {
  classes: PropTypes.object,
  currentDemographic:PropTypes.string,
  currentDemographicCount:PropTypes.number,
  allDemographicsItems:PropTypes.object,
  reportData:PropTypes.object,
  metadata:PropTypes.array,
};
export default withStyles(ReportsStyles)(DemographicList);
