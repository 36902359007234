import { alertConstant, excelConstant, uiConstant, reportConstant, fileManagerConstants } from "../constants";
import { excelService } from "../services";
import { baseAction } from "./baseAction";
import { saveAs } from 'file-saver';

// Import History
import history from "../../config/history";

//Import Constants
import { excelConstants, meSurveyConstants } from "../../config/constants"

const { request, success, failure } = baseAction;

// Export All Excel Actions Functions
export const excelAction = {
  upload,
  download,
  updateExcelData
};


/**
 * Excel Upload
 */
function upload(file, excelType = "", callback = null, isMS = false) {
  return dispatch => {
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(excelConstant.EXCEL_UPLOAD_REQUEST));
    excelService.upload(file, isMS).then(
      (data) => {
        dispatch(success(excelConstant.EXCEL_UPLOAD_SUCCESS, data));
        dispatch(request(uiConstant.LOADING_FALSE));

        if (excelType === excelConstants.PARTICIPANT_UPLOAD) {
          dispatch(success(fileManagerConstants.GET_FILES_LIST_SUCCESS, data));
        } else if (excelType === excelConstants.REMOVE_SURVEY_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/unassignSurvey/success` });
        } else if (excelType === excelConstants.OVERRIDE_DEMOGRAPHICS_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/override/demographics/success` });
        } else if (excelType === excelConstants.CONVERSION_UPLOAD) {
          history.push({ pathname: `/manage_people/demographics/conversion/success` });
        } else if (excelType === excelConstants.ADMIN_UPLOAD) {
          history.push({ pathname: `/manage_people/admins/upload/success` });
        } else if (excelType === excelConstants.BULK_DELETE_PARTICIPANTS_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/bulkDelete/success` });
        } else if (excelType === excelConstants.ARCHIVE_PARTICIPANTS_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/archive/success` });
        } else if (excelType === excelConstants.ME_PARTICIPANT_UPLOAD) {
          callback()
        }
      },
      (error) => {
        dispatch(failure(excelConstant.EXCEL_UPLOAD_FAILURE, error));
        dispatch(request(uiConstant.LOADING_FALSE));
        //redirect to error upload screen
        if (excelType === excelConstants.PARTICIPANT_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/excel/fail` });
        } else if (excelType === excelConstants.REMOVE_SURVEY_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/unassignSurvey/fail` });
        } else if (excelType === excelConstants.CONVERSION_UPLOAD) {
          history.push({ pathname: `/manage_people/demographics/conversion/fail` });
        } else if (excelType === excelConstants.OVERRIDE_DEMOGRAPHICS_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/override/demographics/fail` });
        } else if (excelType === excelConstants.ADMIN_UPLOAD) {
          history.push({ pathname: `/manage_people/admins/upload/fail` });
        } else if (excelType === excelConstants.BULK_DELETE_PARTICIPANTS_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/bulkDelete/fail` });
        } else if (excelType === excelConstants.ARCHIVE_PARTICIPANTS_UPLOAD) {
          history.push({ pathname: `/manage_people/participants/archive/fail` });
        }
      }
    );
  }
}

/**
 * Excel Download
 */
function download(data, isMS = false) {
  return (dispatch, getState) => {
    const { excelType } = data
    dispatch(request(uiConstant.LOADING_TRUE));
    dispatch(request(excelConstant.EXCEL_DOWNLOAD_REQUEST));
    if (data.reqType === "report") {
      dispatch(request(reportConstant.REPORT_LOADING_TRUE));
    }
    dispatch(request(excelConstant.EXCEL_DOWNLOAD_REQUEST));
    // send search filter to api
    if (excelType === meSurveyConstants.ME_PARTICIPANT_DOWNLOAD) {
      const { assign: { page: filterData } } = getState().managerEffectiveness
      data.search = filterData
    }
    excelService.download(data, isMS)
      .then(
        (data) => {
          if (excelType === excelConstants.PARTICIPANT_DOWNLOAD) {
            dispatch(success(alertConstant.ALERT_SUCCESS, excelConstants.PARTICIPANT_EXPORT_DOWNLOAD_ALERT_TEXT, excelConstants.PARTICIPANT_EXPORT_ALERT_TEXT_DURATION));
          } else if (data && data.data && (data.data.error || data.status === "failed")) {
            dispatch(failure(alertConstant.ALERT_ERROR, data.data.error));
            dispatch(request(reportConstant.REPORT_LOADING_FALSE));
          } else {
            const blob = new Blob([data.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, data.filename);
            dispatch(success(alertConstant.ALERT_SUCCESS, "Successfully downloaded"));
            dispatch(request(reportConstant.REPORT_LOADING_FALSE));
          }
          dispatch(request(uiConstant.LOADING_FALSE));
        },
        (error) => {
          // dispatch(failure(excelConstant.EXCEL_DOWNLOAD_FAILURE, error));
          // dispatch(failure(alertConstant.ALERT_ERROR, error));
          dispatch(request(uiConstant.LOADING_FALSE));
          dispatch(request(reportConstant.REPORT_LOADING_FALSE));
        }
      );
  }
}

/**
 * Update Excel File
 */
function updateExcelData(data) {
  return {
    type: excelConstant.EXCEL_DATA_UPDATE,
    fileDetails: data
  };
}