import React, { useEffect, useState, useRef } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { CopyToClipboard } from "react-copy-to-clipboard";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import withStyles from '@mui/styles/withStyles';
import { ValidatorForm } from "react-material-ui-form-validator";
import IconButton from "@mui/material/IconButton";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import Tooltip from "@mui/material/Tooltip";
import CircularProgress from "@mui/material/CircularProgress";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Autocomplete from '@mui/material/Autocomplete';
import { TextField } from "@mui/material";

// Import Styles and Image
import { EmailOrange, Edit, CopyCaller, QuotesIcon, Tick } from "../../assets/images/svgComponents/ImageComponent";

import SpCallerStyles from "./SpCallerStyles";
import CloseImg from "../../assets/images/svg/close_callerdetail.svg";

// Import Component
import Container from "../Containers/Container";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TextFieldComponent from "../../components/TextFieldComponent/TextFieldComponent";
import AutoCompleteComponent from "../../components/AutoCompleteComponent/AutoCompleteComponent";
import SelectFieldComponent from "../../components/SelectFieldComponent/SelectFieldComponent";
import DatePickerComponent from "../../components/DatePickerComponent/CustomDatePicker";
import { TimePickerValidator } from "../../components/TimePickerComponent/TimePicker";
import TableComponent from "../../components/TableComponent/TableComponent";

// import constants
import { SpcallerConstants, translateLanguagesList, translateLanguagesListRGI } from "../../config/constants";

// Import Actions
import { spCallerAction, alertAction, updateStateReducerAction, clientAction, metadataAction } from "../../redux/actions";

// Import History
import history from "../../config/history";

// Import Helpers
import { getReducerUpdatedData, isNineToNine } from "../../helpers";
import { spCallerConstant } from "../../redux/constants";
import { Avatar } from "@mui/material";

import { uniq, sortBy, reverse } from "lodash"

const editableFieldsName = ["participant_id.phone",
"participant_id.altPhone", "participant_id.email", "participant_id.languages"]

function CallerDetail(props) {
  // client form
  const caller_detail_form = useRef();
  // get props
  const { classes, match, location } = props;
  const pageFrom = location.state || {};

  const { params } = match;
  const spcaller_id = params.spcaller_id ? params.spcaller_id : null;
  const surveyId = params.survey_id ? params.survey_id : null;

  const { loading } = useSelector(state => state.UI);
  const { user: { firstName, lastName, _id: caller_id } } = useSelector(state => state.profile);
  const { caller_detail, caller_queue: { callerQueueIds, page: { sFields } } } = useSelector(state => state.spCaller);
  let {
    participant_id,
    client_id, survey_id,
    status, history: caller_history,
    callerEmpty,
    copyData,
    pulse,
    current_assigned_date,
    survey_dateMap,
    assigned_dates_options
  } = caller_detail;
  const survey_phone_detail = survey_id.distribute && survey_id.distribute.phone ? survey_id.distribute.phone : {};

  const { metadata } = useSelector(state => state.metadata);
  // const languagesListOptions = metadata.filter(s => s.key === 'languages')[0] || { rgi: [] };
  const languagesListOptions = translateLanguagesListRGI


  // notes, date, time state
  const [notes, setNotes] = useState("");
  const [assignedDate, setAssignedDate] = useState();
  const [assignedTime, setAssignedTime] = useState(moment());
  const [showDateTime, setShowDateTime] = useState(false);
  const [expPanel, setExpPanel] = useState(null);
  const [expPanelAsd, setExpPanelAd] = useState(null);

  // set up history
  caller_history = (caller_history || []).map(m => {
    let cdtDate = null
    if (m.cdt && typeof m.cdt === "string") {
      cdtDate = moment(m.cdt).format("MM/DD/YYYY")
    } else {
      cdtDate = m.cdt ? moment(m.cdt.split("T")[0]).format("MM/DD/YYYY") : null
    }
    return {
      ...m,
      assignedDate: m.assignedDate ? moment(m.assignedDate).format("MM/DD/YYYY") : null,
      cdt: cdtDate,
      survey_name: survey_id.name
    }
  }).sort((a, b) => {
    return b.attempt < a.attempt ? -1 : 1
  })

  const [editableFields, setEditableFields] = useState([...editableFieldsName]);
  const dispatch = useDispatch();

  // for pulse survey form accordion
  const pulseDetails = survey_id && survey_id.pulse_obj ? survey_id.pulse_obj.pulseDetails || {} : {};
  const history_pulse = caller_history.reduce((a, b) => {
    if (a.filter(s => s.pulse === b.pulse && s.assigned_date === b.assigned_date).length === 0) {
      a.push({
        displayName: pulseDetails[b.pulse] ? pulseDetails[b.pulse].displayName || "No Display Name" : "No Display Name",
        pulse: b.pulse,
        assigned_date: b.assigned_date,
        data: [b]
      })
    } else {
      a = a.map(s => {
        if (s.pulse === b.pulse) {
          s.data = [...s.data, { ...b }];
        }
        return s;
      })
    }
    return a;
  }, []);

  // group by assign date - I1909
  let assignedDates = reverse(sortBy(uniq(caller_history.map(m => {
    if (!m.assigned_date) {
      m.assigned_date = "null"
    }
    return m.assigned_date
  }))))
  if (assignedDates.indexOf(current_assigned_date) === -1) {
    assignedDates.push(current_assigned_date)
  }

  useEffect(() => {
    const callerNextId = caller_detail.nexCallerId || spcaller_id;
    if (callerNextId) {
      dispatch(spCallerAction.getCallerDetail(callerNextId, callerQueueIds, {
        from: pageFrom.from || "",
        isSearch: (sFields || []).length > 0
      }));
    }
    // dispatch(clientAction.getLanguageList(metadata, () => {
    //   dispatch(metadataAction.getClientMetadata())
    // }));
  }, [])

  useEffect(() => {
    // add time validation
    ValidatorForm.addValidationRule('isValidTime', (value) => {
      if (value == "Invalid Date") return false;
      const dateString = `${moment(new Date(assignedDate)).format("YYYY/MM/DD")} ${moment(value).format("LT")}`;
      if (!moment(new Date(dateString)).isValid()) {
        return false
      }
      const diff = moment(new Date(dateString)).diff(moment(), 'minutes');
      return diff >= 0
    });
  }, [assignedDate])

  /**
   * Close caller detail page
   */
  const handleClose = () => {
    if (callerEmpty && isNineToNine()) {
      history.push({
        pathname: `/spcaller`,
        state: {
          defaultCall: false,
          isPageReset: true
        }
      });
      return
    }

    // remove lock from the user
    if (caller_detail._id) {
      dispatch(spCallerAction.updateCallerDetail(caller_detail._id, { removeLockOnly: true }));
    }
    // navigate based on page from data
    if (pageFrom.from) {
      history.push({
        pathname: `/spcaller/caller-queue/${pageFrom.from}`,
        state: {
          ...pageFrom,
          defaultCall: false,
          isPageReset: true
        }
      });
    } else {
      history.push({
        pathname: `/spcaller/caller-queue/${surveyId}`,
        state: {
          ...pageFrom,
          defaultCall: false,
          isPageReset: true
        }
      });
    }
  }

  /**
   * Render Email icon
   * @param {*} labelText title
   * @param {*} copyText copy text
   */
  const SendEmailButton = () => {
    return (
      <IconButton
        color="primary"
        aria-label="Send Survey"
        title="Send Survey"
        className={`${classes.callerdetCopyGrid} emailIcon`}
        style={{
          backgroundColor: "none"
        }}
        onClick={() => {
          if (["Max Attempts", "Refused"].indexOf(status) >= 0) {
            dispatch(alertAction.error(`Email will not be sent as the call status is ${status}.`))
          } else if (participant_id && participant_id._id && participant_id.email) {
            dispatch(spCallerAction.updateCallerDetail(caller_detail._id, {
              ...caller_detail.participant_id,
              survey_id,
              callNext: false,
              sendEmail: {
                _id: survey_id._id,
                participant_id: participant_id._id
              }
            }, callerQueueIds));
          } else {
            dispatch(alertAction.error("Email is empty!"))
          }
        }}
        size="large">
        <Avatar className={`${classes.callerdetEditsendimg} emailorange`}>
          {EmailOrange()}
        </Avatar>
      </IconButton>
    );
  }

  /**
   * Render copy icon
   * @param {*} labelText title
   * @param {*} copyText copy text
   */
  const CopyIconButton = (labelText, copyText = "") => {
    return (
      <CopyToClipboard
        text={copyText}
        onCopy={() => {
          dispatch(alertAction.successAlert("Copied"))
        }}
      >
        <Tooltip arrow title={"Copy"}>
          <IconButton
            color="primary"
            aria-label={labelText}
            className={classes.callerdetCopyGrid}
            size="large">
            {CopyCaller()}
          </IconButton>
        </Tooltip>
      </CopyToClipboard>
    );
  }

  /**
   * Render Edit icon
   * @param {*} labelText Title
   * @param {*} handleIconClick on click of copy icon
   */
  const EditIconButton = (labelText, handleIconClick, fieldStatus = 0) => {
    return (
      <IconButton
        color="primary"
        aria-label={labelText}
        className={classes.callerdetEditGrid}
        onClick={handleIconClick}
        style={fieldStatus >= 0 ? {} : { padding: '0px' }}
        size="large">
        {fieldStatus >= 0 ?
          <Tooltip arrow title={"Edit"}>
            <Avatar className={"avatarwhite"}>{Edit()}</Avatar>
          </Tooltip>
          :
          <Avatar className={"avatarwhite"}>{Tick()}</Avatar>
        }

      </IconButton>
    );
  }

  /**
   * Enable / Disable  editable field status
   */
  const editField = (fieldName) => {
    if (editableFields.indexOf(fieldName) >= 0) {
      setEditableFields([...editableFields.filter(f => f !== fieldName)]);
    } else {
      setEditableFields([...editableFields, fieldName]);
    }
  }

  /**
   * To update value in reducer object
   * @param {*} e contains value, name of the field
   */
  const handleChange = (e, extraProps = {}) => {
    if (e && e.name && e.name === "participant_id.phone" || e.name === "participant_id.altPhone") {
      e["value"] = e.value.replace(/[^+\d]+/g, "")
    }
    const updatedData = getReducerUpdatedData(e, { ...caller_detail, ...extraProps })
    dispatch(updateStateReducerAction.update(spCallerConstant.UPDATE_CALLER_DETAIL_REDUCER, updatedData));
  }

  /**
   * Will push a new history attempt to caller history array
   * @param {*} status status of history to push
   * @param {*} extraProps like assignedDate and assignedTime to push in history if the status is Assigned
   */
  const pushToHistory = (status, extraProps = {}) => {
    const ad = expPanelAsd || current_assigned_date
    let maxAttempt = caller_history.length > 0 ? Math.max(...(caller_history.filter(f => f.assigned_date === ad).map(m => m.attempt)), 0) : 0;
    const maxRemainder = survey_phone_detail.remainder || 0;
    maxAttempt = maxAttempt + 1;
    const allowedStatusTypes = ["Open", "Busy", "Left Message"];
    const pulseData = pulse ? { pulse } : {};
    status = maxAttempt === maxRemainder && allowedStatusTypes.indexOf(status) > -1 ? "Max Attempts" : status
    const newHistory = {
      status: status,
      attempt: maxAttempt,
      notes: notes,
      caller: `${firstName} ${lastName}`,
      mby_id: caller_id,
      redFlag: true,
      cdt: new Date().toUTCString(),
      assigned_date: current_assigned_date, // add latest survey assigned date of the participant
      ...pulseData,
      ...extraProps
    }
    const updatedHistory = [newHistory, ...caller_history];
    const updatedData = getReducerUpdatedData(
      { value: updatedHistory, name: 'history' },
      { ...caller_detail, status: status, ...pulseData })
    dispatch(updateStateReducerAction.update(spCallerConstant.UPDATE_CALLER_DETAIL_REDUCER, updatedData));

    // call update api here push history api
    dispatch(spCallerAction.addHistoryToCallerDetail(caller_detail._id, newHistory));

    // clear notes local state
    setNotes("");
    if (status === "Assigned") {
      setAssignedDate("");
      setAssignedTime("");
      setShowDateTime(false);
    }
  }

  /**
   * Handle Asign button on click
   */
  const assignOnClick = () => {
    // validate date and time and then push to history
    if (caller_detail_form && caller_detail_form.current) {
      caller_detail_form.current.isFormValid(false).then(isValid => {
        if (isValid) {
          pushToHistory(status, { assignedDate: moment(assignedDate).format("YYYY-MM-DD"), assignedTime: moment(assignedTime).format("LT") });
        }
      });
    }
  }

  /**
   * Handle Asign button on cancel
   */
  const assignOnCancel = () => {
    // change to old status
    let oldStatus = null
    if (caller_history.length === 0) {
      if (participant_id.phone || participant_id.altPhone) {
        oldStatus = "Open"
      } else {
        oldStatus = "Unreachable"
      }
    } else {
      oldStatus = caller_history[0].status
    }

    handleChange({ value: oldStatus, name: 'status' });
    setAssignedDate("");
    setAssignedTime("");
    setShowDateTime(false);
  }

  /**
   * submit the form to update caller detail api
   * on next button click
   */
  const handleSubmit = () => {
    dispatch(spCallerAction.updateCallerDetail(caller_detail._id, {
      ...caller_detail.participant_id,
      survey_id,
      callNext: true,
      removeLockOnly: true,
      from: pageFrom.from || "",
      isSearch: (sFields || []).length > 0
    }, callerQueueIds));
    setNotes("")
    setAssignedDate("");
    setAssignedTime("");
    setShowDateTime(false);
    setEditableFields([...editableFieldsName])
  }

  const updateCopyData = () => {
    return {
      ...copyData,
      participant_id: {
        ...copyData.participant_id,
        ...caller_detail.participant_id
      }
    }
  }

  const handleTakeSurvey = () => {
    const selectedLang = participant_id.languages ? participant_id.languages.code || "en" : "en";
    const win = window.open(`${window.location.origin}/#/takesurvey_spcaller/${client_id._id}/${survey_id._id}/${participant_id._id}/${caller_id}?lang=${selectedLang}`, "Take Survey", "width=1024,height=768")
    win.onbeforeunload = () => {
      getCallerDetail()
    }
    win.onblur = () => {
      getCallerDetail()
    }
  }

  /**
   * when phone number update from empty to some data need to change status to open
   * @param {*} field contains change field
   * @param {*} status editable status of field if greater than -1 then it is in editable state to save
   */
  const updateStatus = (field, status) => {
    // this is means user is on save click
    if (status === -1) {
      switch (field) {
        case "email":
          // just update the email info alone
          dispatch(spCallerAction.updateCallerDetail(caller_detail._id, {
            ...caller_detail.participant_id,
            spCallerUpdateFields: {
              locked: caller_id
            },
            callNext: false,
            removeLockOnly: false
          }, callerQueueIds));
          break;
        case "phone":
        case "altPhone":
        case "languages":
          // check with copy data if phone number is added
          if (copyData.participant_id) {
            if (((!copyData.participant_id.phone && participant_id.phone) ||
              !copyData.participant_id.altPhone && participant_id.altPhone) && caller_history.length === 0) {
              // change status to open
              handleChange({ name: "status", value: "Open" }, { copyData: updateCopyData() });
              // service call
              dispatch(spCallerAction.updateCallerDetail(caller_detail._id, {
                ...caller_detail.participant_id,
                spCallerUpdateFields: {
                  status: "Open",
                  locked: caller_id
                },
                callNext: false,
                removeLockOnly: false
              }, callerQueueIds));
            } else if (caller_history.length === 0 && !participant_id.phone
              && !participant_id.altPhone) {
              // change status to open
              handleChange({ name: "status", value: "Unreachable" }, { copyData: updateCopyData() });
              // service call
              dispatch(spCallerAction.updateCallerDetail(caller_detail._id, {
                ...caller_detail.participant_id,
                spCallerUpdateFields: {
                  status: "Unreachable",
                  locked: caller_id
                },
                callNext: false,
                removeLockOnly: false
              }, callerQueueIds));
            } else {
              // just update the phone info alone
              dispatch(spCallerAction.updateCallerDetail(caller_detail._id, {
                ...caller_detail.participant_id,
                spCallerUpdateFields: {
                  locked: caller_id
                },
                callNext: false,
                removeLockOnly: false
              }, callerQueueIds));
            }
          }
        default:
      }
    }
  }

  const renderNoPulseHistory = () => {
    const ad = expPanelAsd || current_assigned_date
    const assigned_date_history = caller_history.filter(f => f.assigned_date === ad)
    return (
      <TableComponent
        height={"auto"}
        searchVisible={false}
        showPagination={false}
        data={assigned_date_history}
        header={["Caller", "Attempts", "Status", "Notes",
          "Assigned Date", "Assigned Time", "Last Called"]}
        removeSortingHeader={["Caller", "Attempts", "Status", "Notes",
          "Assigned Date", "Assigned Time", "Last Called"]}
        field={["caller", "attempt", "status", "notes", "assignedDate", "assignedTime", "cdt"]}
      />
    )
  }

  const renderPulse = () => {
    const ad = expPanelAsd || current_assigned_date
    const accords = history_pulse.filter(a => a.assigned_date === ad).map((h) => {
      return (
        <Accordion
          expanded={expPanel ? h.pulse === expPanel : pulse === h.pulse}
          onChange={() => {
            setExpPanel(h.pulse)
          }}
          className="inner_accord"
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon className="expandicon" />}
            id="panel1a-header"
          >
            <TypographyComponent
              variant="h6"
            >
              {h.displayName}
            </TypographyComponent>
          </AccordionSummary>
          <AccordionDetails>
            <TableComponent
              height={"auto"}
              searchVisible={false}
              showPagination={false}
              data={h.data}
              header={["Caller", "Survey Name", "Attempts", "Status", "Notes",
                "Assigned Date", "Assigned Time", "Last Called"]}
              removeSortingHeader={["Caller", "Survey Name", "Attempts", "Status", "Notes",
                "Assigned Date", "Assigned Time", "Last Called"]}
              field={["caller", "survey_name", "attempt", "status", "notes", "assignedDate", "assignedTime", "cdt"]}
            />
          </AccordionDetails>
        </Accordion>
      )
    });
    return <div style={{ paddingTop: "12px" }}>{accords}</div>;
  }

  const assignedDatesSelection = () => {
    return (
      <Autocomplete
        name={"assigned_date_sel"}
        variant={"outlined"}
        disableClearable
        className="heightone"
        blurOnSelect
        value={expPanelAsd || current_assigned_date}
        options={assignedDates}
        getOptionLabel={(option) => {
          if (option) {
            let dt = option
            if (assigned_dates_options && assigned_dates_options[option]) {
              dt = assigned_dates_options[option]
            }
            return survey_dateMap[option] === false && option !== "null" ? `Survey Assigned - ${moment(dt).format('LL')}` : "Previous attempts on unassigned survey"
          } else {
            return "Previous attempts on unassigned survey"
          }
        }}
        onChange={(event, newValue) => {
          setExpPanelAd(newValue)
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            variant={"outlined"}
            value={expPanelAsd || current_assigned_date}
            margin={"dense"}
            InputProps={{ ...params.InputProps, type: "text", className: "headerSearch pr-3" }}
          />
        )}
      />
      // <AutoCompleteComponent
      //   suggestionType="array"
      //   suggestions={assignedDates}
      //   name="assigned_date_sel"
      //   value={expPanelAsd || current_assigned_date}
      //   validators={["required"]}
      //   fullWidth={true}
      //   handleAutoCompolete={(value) => {
      //     setExpPanelAd(value)
      //   }}
      //   extraProps={{
      //     getOptionLabel: (option) => {
      //     console.log("🚀 ~ file: CallerDetail.jsx ~ line 591 ~ assignedDatesSelection ~ option", option)
      //       if (option) {
      //         let dt = option
      //         if (assigned_dates_options && assigned_dates_options[option]) {
      //           dt = assigned_dates_options[option]
      //         }
      //         return survey_dateMap[option] === false && option !== "null" ? `Survey Assigned - ${moment(dt).format('LL')}` : "Previous attempts on unassigned survey"
      //       } else {
      //         return "Previous attempts on unassigned survey"
      //       }
      //     }
      //   }}
      // />
    )
  }

  // if user take survey return back to the caller detail window
  const getCallerDetail = () => {
    const callerNextId = caller_detail._id || spcaller_id;
    dispatch(spCallerAction.getCallerDetail(callerNextId, callerQueueIds, {
      from: pageFrom.from || "",
      isSearch: (sFields || []).length > 0,
      getDataOnly: true
    }, true));
  }

  return (
    <React.Fragment>
      {
        !loading && (
          <Grid
            className="caller-detail-popup"
            onClose={handleClose}
            aria-labelledby="caller_detail_form"
          >
            <ValidatorForm
              id="caller_detail_form"
              name="caller_detail_form"
              useref="caller_detail_form"
              autoComplete="off"
              ref={caller_detail_form}
              // onSubmit={handleSubmit}
              onError={(errors) => console.log(errors)}
            >
              <Container align={"baseline"} classes={{
                root: classes.callerDetailPage
              }}>
                <Grid aria-live="polite" aria-relevant="text" id="caller-detail-page" container spacing={2}>
                  <Grid item xs={12}>
                    <Grid container justifyContent="space-between" alignItems="center" style={{ height: "49px" }}>
                      <Grid>
                        <Grid container alignItems="center">
                          <TypographyComponent variant="h3">
                            {!callerEmpty ? SpcallerConstants.CALLER_DETAIL : ""}
                          </TypographyComponent>
                        </Grid>
                      </Grid>
                      <Grid>
                        <Grid container alignItems="center">

                          <IconButton onClick={handleClose} size="large">
                            <img src={CloseImg} alt="close" style={{ height: "21px" }}></img>
                          </IconButton>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                  {!callerEmpty && <><Grid item sm={12}>
                    <Grid item sm={12} className={classes.callerDetailContainer}>
                      <Grid container>
                        <Grid item sm={12}>
                          <Grid container justifyContent="space-between">
                            <Grid item>
                              <TypographyComponent
                                variant="h3"
                                title={`${participant_id.firstName || ''} ${participant_id.lastName || ''}`}>
                              </TypographyComponent>
                            </Grid>
                            <Grid item>
                              <ButtonComponent
                                size="small"
                                color="primary"
                                title="Take Survey"
                                handleClick={() => handleTakeSurvey()}
                              >
                              </ButtonComponent>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid container style={{ position: "relative" }}>
                        <Grid sm={4}>
                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={3}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.CALLER_PHONE}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={9}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Grid container alignItems="center"
                                    className={`${classes.editContainer} ${editableFields.indexOf("participant_id.phone") > -1 ? "" : "editable"}`}
                                  >
                                    <TextFieldComponent
                                      name={"participant_id.phone"}
                                      value={participant_id.phone}
                                      margin={"dense"}
                                      size="small"
                                      fullWidth={false}
                                      className="bordernone bgnone w218"
                                      disabled={editableFields.indexOf("participant_id.phone") > -1}
                                      handleChange={(e) => handleChange(e)}
                                      validators={["isPhone"]}
                                    />
                                    {CopyIconButton("Copy Phone Nummber", participant_id.phone)}
                                  </Grid>
                                </Grid>
                                {EditIconButton("Toggle Phone Edit", () => {
                                  // status change check
                                  updateStatus("phone", editableFields.indexOf("participant_id.phone"));
                                  editField("participant_id.phone")
                                }, editableFields.indexOf("participant_id.phone"))}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={3}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.ALTPHONE}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={9}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Grid container alignItems="center" className={`${classes.editContainer} ${editableFields.indexOf("participant_id.altPhone") > -1 ? "" : "editable"}`}>
                                    <TextFieldComponent
                                      // id={"Text"}
                                      name={"participant_id.altPhone"}
                                      value={participant_id.altPhone}
                                      margin={"dense"}
                                      size="small"
                                      fullWidth={false}
                                      className="bordernone bgnone w218"
                                      disabled={editableFields.indexOf("participant_id.altPhone") > -1}
                                      handleChange={(e) => handleChange(e)}
                                    // validators={["required", "isEmail"]}
                                    />
                                    {CopyIconButton("Copy Alternative Phone Nummber", participant_id.altPhone)}
                                  </Grid>
                                </Grid>
                                {EditIconButton("Toggle Alternative Phone Edit", () => {
                                  // status change check
                                  updateStatus("altPhone", editableFields.indexOf("participant_id.altPhone"));
                                  editField("participant_id.altPhone")
                                }, editableFields.indexOf("participant_id.altPhone"))}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={3}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.EMAIL}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={9}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Grid container alignItems="center" className={`${classes.editContainer} ${editableFields.indexOf("participant_id.email") > -1 ? "" : "editable"}`}>
                                    <TextFieldComponent
                                      // id={"Text"}
                                      name={"participant_id.email"}
                                      value={participant_id.email}
                                      margin={"dense"}
                                      size="small"
                                      fullWidth={false}
                                      className="bordernone bgnone w218"
                                      disabled={editableFields.indexOf("participant_id.email") > -1}
                                      handleChange={(e) => handleChange(e)}
                                    // validators={["required", "isEmail"]}
                                    />
                                    {SendEmailButton()}
                                  </Grid>
                                </Grid>
                                {EditIconButton("Toggle Email Edit", () => {
                                  // status change check
                                  updateStatus("email", editableFields.indexOf("participant_id.email"));
                                  editField("participant_id.email")
                                }, editableFields.indexOf("participant_id.email"))}
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={3}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.STATE}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={9}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Grid container alignItems="center" className={`${classes.stateContainer}`}>
                                    <TypographyComponent variant="h6" className="pl-1">
                                      {participant_id.state}
                                    </TypographyComponent>
                                  </Grid>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={3}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.LANGUAGE}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={9}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <Grid container alignItems="center"
                                    className={`${classes.editContainer} ${editableFields.indexOf("participant_id.languages") > -1 ? "" : "editable"} bgnone`}
                                  >
                                    <AutoCompleteComponent
                                      suggestionType="array"
                                      suggestions={languagesListOptions || []}
                                      name="participant_id.languages"
                                      className="heightone"
                                      value={participant_id.languages ? participant_id.languages.name || "" : ""}
                                      validators={["required"]}
                                      fullWidth={true}
                                      disabled={editableFields.indexOf("participant_id.languages") > -1}
                                      handleAutoCompolete={(value, name) => {
                                        const getLangObj = translateLanguagesList.filter(f => f.name === value)[0] || {
                                          name: "English",
                                          code: "en"
                                        }
                                        handleChange({ value: getLangObj, name })
                                      }}
                                    />
                                  </Grid>
                                </Grid>
                                {EditIconButton("Toggle Language Edit", () => {
                                  // status change check
                                  updateStatus("languages", editableFields.indexOf("participant_id.languages"));
                                  editField("participant_id.languages")
                                }, editableFields.indexOf("participant_id.languages"))}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid sm={3}>
                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={4}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.CLIENT_NAME}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={8}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <TypographyComponent variant="h6" className="txtBold">
                                    {client_id.name || ""}
                                  </TypographyComponent>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={4}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.SURVEY}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={8}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <TypographyComponent variant="h6">
                                    {survey_id.name}
                                  </TypographyComponent>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={4}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.FREQUENCY}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={8}>
                              <Grid container alignItems="center">
                                <Grid item>
                                  <TypographyComponent variant="h6">
                                    {survey_id.frequency || ""}
                                  </TypographyComponent>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>

                          <Grid container className={classes.quoteContainer}>
                            <Grid item sm={12}>
                              {QuotesIcon()}
                            </Grid>
                            <Grid item sm={12}>
                              <TypographyComponent
                                variant="h6"
                                className="txtcenter"
                              >
                                <TextareaAutosize
                                  className={`${"width100 borderRadius6"}`}
                                  disabled
                                  value={survey_phone_detail.details || ""}
                                  minRows={3}
                                  style={{
                                    padding: "0px",
                                    backgroundColor: "#fff",
                                    border: "none",
                                    resize: "none"
                                  }}
                                />
                              </TypographyComponent>
                            </Grid>
                            <Grid item sm={12} className="txtright">
                              <Grid className={classes.quoteIconRotate}>
                                {QuotesIcon()}
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid sm={5}>
                          <Grid container>
                            <Grid item sm={12}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.NOTES}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={12}>
                              <TextareaAutosize
                                className={`${"width100 borderRadius6 pe_textarea"}`}
                                name={"Notes"}
                                placeholder={"Enter notes here"}
                                value={notes}
                                onChange={(e) => {
                                  setNotes(e.target.value)
                                }}
                                minRows={3}
                              />
                            </Grid>
                          </Grid>

                          <Grid container alignItems="center" className="mb-15">
                            <Grid item sm={4}>
                              <TypographyComponent
                                variant="h6"
                                title={SpcallerConstants.STATUS}
                              ></TypographyComponent>
                            </Grid>
                            <Grid item sm={8}>
                              <Grid container alignItems="center">
                                <Grid item sm={8}>
                                  <SelectFieldComponent
                                    options={SpcallerConstants.CALLER_STATUS}
                                    name="status"
                                    validators={["required"]}
                                    fullWidth={true}
                                    value={status}
                                    extraProps={{
                                      SelectProps: {
                                        // need to write in on close because,
                                        // user need to select option that is already selected
                                        // onchange wont trigger for already selected option
                                        onClose: (event) => {
                                          if (event.target) {
                                            const selectedValue = event.target.getAttribute("data-value");
                                            if (SpcallerConstants.CALLER_STATUS.indexOf(selectedValue) >= 0) {
                                              if (selectedValue === "Assigned") {
                                                handleChange({ name: 'status', value: 'Assigned' });
                                                setAssignedTime(moment());
                                                setShowDateTime(true);
                                              } else {
                                                setShowDateTime(false);
                                                pushToHistory(selectedValue);
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }}
                                  />
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                          {
                            showDateTime && (
                              <>
                                <Grid
                                  container
                                  alignItems="center"
                                  className="mb-15"
                                  style={{ minHeight: "90px" }}
                                >
                                  <Grid item sm={4}>
                                    <TypographyComponent
                                      variant="h6"
                                      title={SpcallerConstants.SELECT_DATETIME}
                                    ></TypographyComponent>
                                  </Grid>
                                  <Grid item sm={8} className={'caller_detail_datetime_entry'}>
                                    <Grid container alignItems="flex-start" className={'callerDetailSelectDateAndTime'}>
                                      <DatePickerComponent
                                        name={"assignedDate"}
                                        value={assignedDate}
                                        className={classes.CutomerDetailDate}
                                        handleChange={({ value }) => {
                                          setAssignedDate(value)
                                          if (caller_detail_form && caller_detail_form.current) {
                                            caller_detail_form.current.isFormValid(false).then(isValid => { });
                                          }
                                        }}
                                        inputVariant="outlined"
                                        margin="dense"
                                        fullWidth={false}
                                        disabled={false}
                                        validators={['required', 'isGoodDate', 'isFutureDate']}
                                        errorMessages={['Enter Assigned Date', 'Format must be MM/DD/YYYY', 'Must be Future Date']}
                                      />
                                      <TimePickerValidator
                                        name={"assignedTime"}
                                        value={assignedTime}
                                        fullWidth={false}
                                        className={classes.CutomerDetailTime}
                                        validators={['required', 'isValidTime']}
                                        errorMessages={['Enter Valid Assigned Time', 'Enter valid future time']}
                                        onValueChange={({ dateValue: value }) => {
                                          setAssignedTime(value)
                                        }}
                                      />
                                    </Grid>
                                  </Grid>
                                </Grid>

                                <Grid container alignItems="center" className="mb-15">
                                  <Grid item sm={4} />
                                  <Grid item sm={8}>
                                    <ButtonComponent
                                      size="small"
                                      color="primary"
                                      title={SpcallerConstants.ASSIGN}
                                      pageClassName="mr-1"
                                      handleClick={assignOnClick}
                                    />
                                    <ButtonComponent
                                      size="small"
                                      color="secondary"
                                      title={SpcallerConstants.CANCEL}
                                      handleClick={assignOnCancel}
                                    />
                                  </Grid>
                                </Grid>
                              </>
                            )
                          }
                        </Grid>

                        <Grid sm={1}>
                          <Grid container
                            className={`${classes.callderdetNextBtn} ${"height100"}`}
                            alignItems="center"
                            justifyContent="flex-end">
                            <ButtonComponent
                              color={"primary"}
                              size={"small"}
                              variant="contained"
                              pageClassName="mr-1 buttonOrange"
                              // type="submit"
                              // form="caller_detail_form"
                              style={{
                                width: "75px",
                                position: "absolute",
                                right: "-60px",
                                top: "50%",
                              }}
                              title={"Next"}
                              handleClick={() => {
                                if (caller_detail_form && caller_detail_form.current) {
                                  caller_detail_form.current.isFormValid(false).then(isValid => {
                                    if (isValid) {
                                      handleSubmit()
                                    }
                                  });
                                }
                              }}
                              endIcon={<ChevronRightIcon></ChevronRightIcon>}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>

                    <Grid item sm={12}>
                      <Grid container>
                        <Grid item sm={12}>
                          <Grid container>
                            <Grid item xs={8}>
                              <TypographyComponent
                                variant="h3"
                                className="txtBold">
                                {SpcallerConstants.CALLER}
                              </TypographyComponent>
                            </Grid>
                            <Grid item xs={4}>
                              {
                                assignedDatesSelection()
                              }
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item sm={12} className="caller-history">
                          {
                            pulse && survey_id.frequency === "Pulse" ? renderPulse() : renderNoPulseHistory()
                          }
                        </Grid>
                      </Grid>
                    </Grid></>}
                  {
                    callerEmpty && (
                      <div style={{ textAlign: "center", display: "block", width: "100%" }}>
                        <h2>There are no more participants in the call queue.</h2>
                      </div>
                    )
                  }
                </Grid>
              </Container>
            </ValidatorForm>
          </Grid>
        )
      }
      {loading && (
        <div className="caller_detail_loader">
          <CircularProgress />
        </div>
      )}
    </React.Fragment>
  );
}
// default props
CallerDetail.defaultProps = {
  classes: {},
  handlePopoverClick: () => { }
};

// prop types
CallerDetail.propTypes = {
  classes: PropTypes.object,
  handlePopoverClick: PropTypes.func
};
export default withStyles(SpCallerStyles)(CallerDetail);
