import * as apihelper from "../../helpers/apiHelper";

/**
 * Export All Authentication Service Functions
 */
export const adminService = {
  search,
  getOne,
  create,
  update,
  remove,
  getRoles,
  updateStatus,
  excelUpload,
  excelDownload,
  updateReportPreference,
  getReportPreference,
  getSurveys,
  getAdminSurveyAccess
};

/**
 * Get All Admin Users
 * @param id
 * @param updatedData
 */
async function search(skip, limit, sortBy, sort, sFields, sValues) {
  const response = await apihelper.postRequest(`admin/search?skip=${skip}&limit=${limit}&sortBy=${sortBy}&sort=${sort}`, { sFields, sValues });
  return response;
}

/**
* Get Particular User Admin
* @param id
* @param updatedData
*/
async function getOne(id) {
  const response = await apihelper.getRequest("admin/" + id);
  return response;
}

/**
 * Update Report Preferences
 * @param {*} reportPreference 
 */
async function updateReportPreference(reportPreference) {
  const response = await apihelper.postRequest('admin/report/preference', reportPreference)
  return response;
}

/**
 * Put Report Preferences
 * updated to default hide the hotspot data
 */
async function getReportPreference(data) {
  const response = await apihelper.putRequest('admin/report/preference', data)
  return response;
}

/**
* Create New Admin
* @param id
* @param updatedData
*/
async function create(data) {
  const response = await apihelper.postRequest("admins", data);
  return response;
}


/**
* Update Particular Admin Roles
* @param id
* @param updatedData
*/
async function update(updatedData) {
  const response = await apihelper.putRequest("admins", updatedData);
  return response;
}

/**
* Remove Particular Admin Roles
* @param id
*/
async function remove(id) {
  const response = await apihelper.deleteRequest("admins/", { _id: id });
  return response;
}

/**
* Get all Roles
*/
async function getRoles(id) {
  const response = await apihelper.getRequest("roles/");
  return response;
}


/**
* Update Particular Admin Roles
* @param id
* @param updatedData
*/
async function updateStatus(updatedData) {
  const response = await apihelper.putRequest("admins/status", updatedData);
  return response;
}

/* Excel Upload
*/
async function excelUpload(file) {
  try {
    const response = await apihelper.postRequest('admins/excel/import', file);
    return response;
  } catch (e) {
    console.error(e)
  }
}

/* Excel Download
*/
async function excelDownload(skip, limit, sortBy, sort, sFields, sValues) {
  try {
    const response = await apihelper.postRequest('admins/excel/export', skip, limit, sortBy, sort, sFields, sValues);
    return response;
  } catch (e) {
    console.error(e)
  }
}

/**
* Get Client Surveys
*/
async function getSurveys() {
  try {
    const response = await apihelper.getRequest("admins/surveys");
    return response;
  } catch (e) {
    console.error(e)
  }
}

/**
* Get Client Surveys
*/
async function getAdminSurveyAccess() {
  try {
    const response = await apihelper.getRequest("admins/surveyAccess");
    return response;
  } catch (e) {
    console.error(e)
  }
}