/* eslint-disable react/prop-types */
/* eslint-disable prefer-const */
/* eslint-disable no-unneeded-ternary */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import withStyles from '@mui/styles/withStyles';
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import Link from "@mui/material/Link";
import Box from '@mui/material/Box';
import Grid from "@mui/material/Grid";
import StepButton from "@mui/material/StepButton";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import MenuItem from "@mui/material/MenuItem";
import TextField from '@mui/material/TextField';
import Select from "@mui/material/Select";
import RadioButtonCheckedIcon from "@mui/icons-material/RadioButtonChecked";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

// Import SVG Icons
import { BackArrow, PencilEdit } from "../../assets/images/svgComponents/ImageComponent";

// import BackArrow from "../../assets/images/svg/back.svg";


// Import History
import history from "../../config/history";

// Import Styles and Image
import SurveysStyles from "./SurveysStyles";

// Import Actions
import { clientAction, categoryAction, surveyAction, metadataAction, updateStateReducerAction, alertAction } from "../../redux/actions";

// Import Container
import Container from "../Containers/Container";

// Import Component
import ButtonComponent from "../../components/ButtonComponent/ButtonComponent";
import TypographyComponent from "../../components/TypographyComponent/TypographyComponent";
import ToolTipComponent from "../../components/ToolTipComponent/ToolTipComponent";

// Import Pages
import SurveyPanel from "./SurveysNewEdit/SurveyPanel";
import QuestionsPanel from "./SurveysNewEdit/QuestionsPanel";
import SettingsPanel from "./SurveysNewEdit/SettingsPanel";


import { isTranslated, iseditedLangtxt } from "../../helpers";


// Import Constants
import { surveyConstants, surveyTypes, surveyTypesArray } from "../../config/constants";
import { getTableRowSize } from "../../config/layout";
import { surveyConstant } from "../../redux/constants";

// Get Stepper Page Title
function getSteps() {
  return ["Survey", "Questions", "Settings"];
}

function StepIconComponent(props) {
  const { active, completed } = props;
  return (
    active || completed ? <RadioButtonCheckedIcon className={"colorTheme"} /> : <RadioButtonUncheckedIcon className={"colorTheme"} />
  );
}

function SurveyNewEdit(props) {

  const dispatch = useDispatch();
  const { classes, match, page } = props;
  const { params } = match;
  const [openCancelDialog, setOpenCancelDialog] = useState({ value: "", open: false });
  const [openTriggerDialog, setOpenTriggerDialog] = useState({ dialog: false, error: false });
  const [openSurveyCancelDialog, setOpenSurveyCancelDialog] = useState({
    open: false,
    message: ""
  });
  const [showSurveyDDL, setShowSurveyDDL] = useState(false);
  // Get Reducer Updates
  const survey = useSelector((state) => state.survey.getOne);
  const { translated_history } = useSelector((state) => state.survey);
  const settings = useSelector((state) => state.survey.settings);
  let { survey_name, languages } = useSelector((state) => state.survey.questions);
  const clientId = useSelector((state) => state.client.getOne._id);
  
  const tableRowSize = getTableRowSize();
  const current_lang = languages.filter((val) => { return val.active === true })[0].code
  const [surveyNameEdit, setSurveyNameEdit] = useState({status: false, name: survey.name ? survey.name : ''});
  useEffect(() => {
    setSurveyNameEdit(prevState => ({...prevState, name: survey.name}))
  }, [survey])

  /**
 * Call Get Survey By Id
 */
  useEffect(() => {
    dispatch(clientAction.getSurveyPermission());
    dispatch(metadataAction.getDemographicsName());
    dispatch(surveyAction.getOne(params.id ? params.id : null));
  }, [dispatch, params.id]);

  // Get Page Names
  const steps = getSteps();

  const handleTextChange = (event) => {
    survey_name[current_lang] = event.target.value
    dispatch(updateStateReducerAction.update(surveyConstant.SURVEY_NAME_REDUCER_UPDATE, survey_name));
    iseditedLangtxt(translated_history, current_lang, "surveyname")
  }
  /**
   * Cancel Button Event
   */
  const handleCancel = () => {
    if (page === 0 && params.id === undefined) {
      setOpenSurveyCancelDialog({
        open: true,
        message: surveyConstants.CANCEL_BUTTON_DIALOG_MESSAGE_1
      });
    } else {
      // LocalStorage to Handle the Dialog Yes Form Submission Redirect
      localStorage.setItem("formSubmitCancelAction", true);
      setOpenCancelDialog({ value: settings.status, open: true });
    }
  };

  // Save Button Event
  const handleSave = (e) => {
    const currentTrigger = settings?.distribute?.triggers;
    const prevTrigger = settings?.distribute?.prevTrigger;
    const selectedType = settings?.distribute?.audience?.select;
    const result = currentTrigger && prevTrigger && currentTrigger.constructor === String && prevTrigger.constructor === String  ? currentTrigger === prevTrigger : true;
    if (!result && currentTrigger !== '{"$and":[{}]}' && selectedType === 'trigger' && JSON.stringify(currentTrigger) !== JSON.stringify(prevTrigger)) {
      setOpenTriggerDialog({ dialog: true, error: true });
      e.preventDefault();
    }
  }

  /**
  * Handle Dialog Close Action
  */
  const handleDialogCancelAction = () => {
    localStorage.removeItem("formSubmitCancelAction");
    setOpenCancelDialog({ value: "", open: false });
    setOpenSurveyCancelDialog({
      open: false,
      message: ""
    });
  };

  /**
 * Handle Dialog Yes Action
 */
  const handleDialogYesAction = () => {
    setOpenCancelDialog({ value: "", open: false });
    setOpenSurveyCancelDialog({
      open: false,
      message: ""
    });
  };

  /**
   * Generate Survey Type Menu Items
   */
  let bindSurveyMenuItem = () => {
    if (survey.type === surveyTypes.MULTI_RATER_FEEDBACK) {
      return <MenuItem disabled key={`surveytype-ME`} value={surveyTypes.MULTI_RATER_FEEDBACK}>{surveyTypes.MULTI_RATER_FEEDBACK}</MenuItem>
    } else {
      let updatedSurveyTypes = surveyTypesArray.filter((sur) => sur.key !== surveyTypes.MULTI_RATER_FEEDBACK)
      return updatedSurveyTypes.map((item, index) => {
        if (item.value === surveyTypes.CUSTOM_SURVEY) {
          return <MenuItem key={`surveytype-${index}`} value={surveyTypes.CUSTOM_SURVEY}>Other</MenuItem>;
        } else {
          return <MenuItem key={`surveytype-${index}`} value={item.value}>{item.key}</MenuItem>;
        }
      });
    }
  };

  // Handle Survey Type DropDown
  const handleChange = (event) => {
      const updateSurveyType = {
        ...survey,
        isStepperChanger: true,
        template: event.target.value,
        type: event.target.value
      }

      dispatch(surveyAction.update(updateSurveyType, () => {
        dispatch(clientAction.getOne(() => {
          dispatch(surveyAction.getOne(params.id, () => {
            dispatch(categoryAction.search(''));
            dispatch(surveyAction.getQuestions(params.id));
            dispatch(surveyAction.getResponseDetails(params.id));
            dispatch(surveyAction.getAnswerSet(params.id));
          }));
        }));
      }));
  };

  // Handle Survey Type DropDown Close
  const handleClose = () => {
    setShowSurveyDDL(true);
  };

  /**
  * Handle back to survey list
  */
  const gotoSurveyListPage = (e) => {
    localStorage.removeItem("formSubmitCancelAction");
    history.replace("/surveys/manage?reset=true");
  };

  /**
* Handle back to survey list
*/
  const handleGoBackAction = (e) => {
    setOpenSurveyCancelDialog({
      open: true,
      message: surveyConstants.CONFIRM_UNSAVED_CONTENT
    });
  }

  /**
   * Return Page Component
   */
  function getPage(page) {
    switch (page) {
      case 1:
        return <QuestionsPanel match={match} cancelButtonAction={openCancelDialog}></QuestionsPanel>;
      case 2:
        return <SettingsPanel match={match} isAudienceTabError={openTriggerDialog.error}></SettingsPanel>;
      default:
        return <SurveyPanel match={match} cancelButtonAction={openCancelDialog}></SurveyPanel>;
    }
  }

  // eslint-disable-next-line no-unused-vars
  function stepperClickAction(page) {
    switch (page) {
      case 0:
        if (survey._id) {
          history.push({
            pathname: `/surveys/${params.id}`
          });
        }
        break;
      case 1:
        if (survey._id) {
          history.push({
            pathname: `/surveys/${params.id}/questions`
          });
        }
        break;
      case 2:
        if (survey._id) {
          history.push({
            pathname: `/surveys/${params.id}/settings`
          });
        }
        break;
      default:
        break;
    }
  }

  // Handle Survey Name Edit
  const changeSurveyName = () => {
    if (!surveyNameEdit.name || !surveyNameEdit.name.trim()) {
      dispatch(alertAction.error(surveyConstants.EMPTY_SURVEY));
      setSurveyNameEdit({name: survey.name, status: false});
      return;
    }
    if (surveyNameEdit.name !== survey.name) {
      dispatch(surveyAction.update({...survey, name: surveyNameEdit.name, isStepperChanger: false}));
    }
    setSurveyNameEdit(prevState => ({...prevState, status: false}));
  }

  const handleSurveyNameEdit = (e) => {
    if (e.key === "Escape" || e.key === "Enter") {
      setSurveyNameEdit(prevState => ({...prevState, name: e.target.value}));
      changeSurveyName();
    }
  }

  return (
    <div className={classes.root}>
      {/* Header Part */}
      <Grid container direction="row" justifyContent="space-between" alignItems="center" className={classes.surveyheader}>
        <Grid item>
          <Grid container alignItems="center">
            <Link
              component="button"
              onClick={handleGoBackAction}
              className="peLink1">
              <TypographyComponent
                variant={"h6"}
                align={"center"}
              > {/* <img src={BackArrow} alt="Back" /> */} {BackArrow()} Back to my surveys</TypographyComponent>
            </Link>

          </Grid>
        </Grid>
        {
          page !== 0 &&
          <Grid item className={"txtright dflex"}>
            {current_lang !== "en" &&
              <TextField
                id={"survey_name"}
                className={`height36 ${classes.survey_name} ${translated_history.hasOwnProperty(current_lang) ? (isTranslated(translated_history, current_lang, "surveyname") ? classes.translatedField : classes.editedtranslatedField) : ""}`}
                name={"surveyname"}
                value={survey_name[current_lang] ? survey_name[current_lang] : ""}
                margin={"dense"}
                onChange={(e) => handleTextChange(e)}
              />
            }
            <Grid item className={"pl-1"} >
              <Box className={`${classes.surveyNameBoxWrap}`}>
                {
                  !surveyNameEdit.status ? (
                    <>
                      <ToolTipComponent title={surveyConstant.EDIT_THE_SURVEY}><div className="dflex" onClick={() => setSurveyNameEdit(prevState => ({...prevState, status: true}))}>{PencilEdit()}</div></ToolTipComponent>
                      <TypographyComponent className={`${classes.createsur_headtxtone}`} variant="h6" title={survey.name}></TypographyComponent>
                    </>
                  ) : (
                    <ClickAwayListener onClickAway={changeSurveyName} mouseEvent='onMouseDown'>
                      <TextField
                        id={"survey_name_edit"}
                        name={"surveyNameEdit"}
                        variant={"outlined"}
                        defaultValue={survey.name}
                        onChange={(e) => setSurveyNameEdit(prevState => ({...prevState, name: e.target.value}))}
                        onKeyDown={(e) => handleSurveyNameEdit(e)}
                        className={`${classes.surveyNameEdit}`}
                        autoFocus
                        focused
                      />
                    </ClickAwayListener>
                  )
                }
              </Box>

              {((survey.type === surveyTypes.CUSTOM_SURVEY && survey.status === "Open") || survey.status === "Pending" || showSurveyDDL === true)
                ? <Select
                  id="surveyTypeDDL"
                  name={"type"}
                  onClose={handleClose}
                  disabled={page === 2 ? true : false}
                  value={survey.type}
                  displayEmpty
                  variant="standard"
                  disableUnderline={true}
                  className={classes.bottomAlign}
                  style={{ height: 0 }}
                  classes={{
                    root: classes.headerSurveyDropDownRoot,
                    icon: classes.headerSurveyDropDownIcon,
                    select: classes.headerSurveyDropDownSelect,
                    disabled: classes.headerSurveyDropDownDisabled
                  }}
                  onChange={handleChange}
                >
                  {
                    bindSurveyMenuItem()
                  }
                </Select>
                : <TypographyComponent className={`${classes.createsur_headtxttwo}`} variant="h6" title={survey.type}></TypographyComponent>
              }
            </Grid>
          </Grid>
        }
      </Grid>

      {/* Content Part */}
      <Container page={"sub"}>
        <div className={classes.root}/*  style={{height: (window.innerHeight - 264)}} */>
          {
            getPage(page)
          }
        </div>
      </Container>

      {/* Stpper / Footer Part */}
      <div className={classes.stepper_container}>
        <Stepper className={`${classes.stepper_div} ${"stepper_div"}`} activeStep={page} alternativeLabel>
          {steps.map((label, index) => (
            <Step key={label}>
              <StepButton
                type="submit"
                className={"p-0 m-0"}
                onClick={() => stepperClickAction(index)}
                completed={index <= page - 1}
              >
                <StepLabel StepIconComponent={StepIconComponent}>{label}</StepLabel>
              </StepButton>
            </Step>
          ))}
        </Stepper>
        <div className={`${classes.stepper_btns} ${"stepper_btns"}`}>
          <ButtonComponent
            title={page !== 2 ? surveyConstants.CONTINUE_BUTTON : surveyConstants.SAVE_BUTTON}
            color={"primary"}
            size={"small"}
            componentClassName={"marginLR5"}
            type="submit"
            handleClick={handleSave}
            form={page === 0 ? "surveyPanelForm" : (page === 1 ? "surveyQuestionPanelForm" : "surveySettingsPanelForm")}
          ></ButtonComponent>
          <ButtonComponent
            title={surveyConstants.CANCEL_BUTTON}
            className={classes.stepper_cancel}
            color={"default"}
            size={"small"}
            handleClick={handleCancel}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </div>
      </div>

      {/* Dialog survey Content */}
      <Dialog
        open={openSurveyCancelDialog.open}
        onClose={handleDialogCancelAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent style={{ margin: "auto" }}>
          {openSurveyCancelDialog.message}
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            title={"Yes"}
            color={"primary"}
            size={"small"}
            handleClick={gotoSurveyListPage}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
          <ButtonComponent
            title={"No"}
            // color={"secondary"}
            size={"small"}
            className={classes.cancelBtn}
            handleClick={handleDialogCancelAction}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </DialogActions>
      </Dialog>

      {/* Dialog questions and settings Content */}
      <Dialog
        open={openCancelDialog.open}
        onClose={handleDialogCancelAction}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="pt-35">
          {surveyConstants.CANCEL_BUTTON_DIALOG_MESSAGE_2}
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            title={"Yes"}
            color={"primary"}
            size={"small"}
            componentClassName={"marginLR5"}
            type="submit"
            form={page === 0 ? "surveyPanelForm" : (page === 1 ? "surveyQuestionPanelForm" : "surveySettingsPanelForm")}
            handleClick={handleDialogYesAction}
          ></ButtonComponent>
          <ButtonComponent
            title={"No"}
            // color={"secondary"}
            className={classes.cancelBtn}
            size={"small"}
            handleClick={gotoSurveyListPage}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
          <ButtonComponent
            title={"Cancel"}
            // color={"secondary"}
            className={classes.cancelBtn}
            size={"small"}
            handleClick={handleDialogCancelAction}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </DialogActions>
      </Dialog>

      {/* Dialog for changing audience - automatic triggers */}
      <Dialog
        open={openTriggerDialog.dialog}
        onClose={() => {setOpenTriggerDialog(prev => {return {dialog: false, ...prev}})}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent className="pt-35">
          {surveyConstants.AUTOMATIC_TRIGGER_EDIT_MESSAGE}
        </DialogContent>
        <DialogActions>
          <ButtonComponent
            title={"Yes"}
            color={"primary"}
            size={"small"}
            componentClassName={"marginLR5"}
            type="submit"
            form={page === 0 ? "surveyPanelForm" : (page === 1 ? "surveyQuestionPanelForm" : "surveySettingsPanelForm")}
            handleClick={() => {setOpenTriggerDialog({dialog: false, error: false})}}
          ></ButtonComponent>
          <ButtonComponent
            title={"Cancel"}
            color={"primary"}
            className={classes.cancelBtn}
            size={"small"}
            handleClick={() => {setOpenTriggerDialog({dialog: false, error: true})}}
            componentClassName={"marginLR5"}
          ></ButtonComponent>
        </DialogActions>
      </Dialog>
    </div>
  );
}

// default props
SurveyNewEdit.defaultProps = {
  classes: {},
  match: {},
  page: 0
};

// prop types
SurveyNewEdit.propTypes = {
  classes: PropTypes.object,
  match: PropTypes.object,
  page: PropTypes.number
};

export default withStyles(SurveysStyles)(SurveyNewEdit);
