import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";
import { IconButton, Popover, Checkbox, FormControlLabel, Button, Box } from "@mui/material";

// Import Styles and Image
import {
  DashboardClose,
  Download,
  Expand,
  CustomizeColumns
} from "../../../../assets/images/svgComponents/ImageComponent";

// Import Component
import TypographyComponent from "../../../../components/TypographyComponent/TypographyComponent";
import DialogComponent from "../../../../components/DialogComponent/DialogComponent";
import HighlevelsummaryPopup from "../HighLevelSummary/HighlevelsummaryPopup";
import HighLevelSummary from "../../Insight/HighLevelSummary/Highlevelsummary";
import CacheComponent from "../../../../components/CacheComponent";


// Import Reducer Constant
import { dashboardConstant } from "../../../../redux/constants";

// Import Constants
import { excelConstants, summaryColumnField } from "../../../../config/constants";

// Redux Actions
import { updateStateReducerAction, excelAction, dashboardAction, adminsAction } from "../../../../redux/actions";

function HighLevelSummaryContent(props) {
  // Define Dispatch
  const dispatch = useDispatch();

  //Get props
  const {
    classes,
    handleClose,
    DragHandle,
    isUpdate,
    preference,
    stopChartReload,
    allSurveys,
    updateReportPreference,
    filtersAppliedChange,
    rlChart
  } = props;

  // Get Redux Values
  const { dashboardData: { keydriver }, highlevelSummary: { showPopup, loading, hcData, hlsData, metadataKeyname, summaryWidgetColumnsDropdown, filtersApplied }, isDataFiltersApplied } = useSelector((state) => state.dashboard);

  // state
  const [openOptions, setOpenOptions] = useState(null)
  const [tempSummaryTableColumns, setTempSummaryTableColumns] = useState([])
  const [summaryTableColumns, setSummaryTableColumns] = useState([])

  useEffect(() => {
    const [{selectedFields, isupdated}] = JSON.parse(localStorage.getItem("summaryWidgetColumns")) || [{}];
    let tempSelectedFields = extraColumnsAddOrUpdate(selectedFields, preference.hls && preference.hls["type"] ? preference.hls["type"] : "category", keydriver && keydriver.length > 0, Boolean(hlsData));
    if (!_.isEqual(selectedFields, tempSelectedFields)) {
      localStorage.setItem("summaryWidgetColumns", JSON.stringify([{ isupdated, selectedFields: tempSelectedFields }]));
    }
  }, [preference])

  const extraColumnsAddOrUpdate = (field, value, isKeyDriver, isHLS) => {
    let tempField = new Set(field);
    if (value === "question" || value === "keyDrivers") {
      if (isKeyDriver) {
        tempField = new Set([...tempField, ...["keyDriverValue", "categoryName"]])
      } else {
        tempField.add("categoryName")
        tempField.delete("keyDriverValue")
      }
    } else if (value === "category" || (isHLS && value === "demographic")) {
      tempField.delete("keyDriverValue");
      tempField.delete("categoryName")
    }
    return [...new Set(tempField)]
  }

  const commonColumns = ["participantCount", "mean", "percentfavorable", "companyComparison", "historicalData"];
  const getInitalColumnFields = () => {
    let field = [];
    let selectValue = preference.hls && preference.hls["type"] ? preference.hls["type"] : "category";
    let isKeyDriver = keydriver && keydriver.length > 0;
    if (selectValue === "question" || selectValue === "keyDrivers") {
      if (isKeyDriver) {
        field = ["keyDriverValue", "categoryName", ...commonColumns];
      } else {
        field = ["categoryName", ...commonColumns];
      }
    }
    else if (selectValue === "category") {
      field = commonColumns;
    }
    else if (hlsData && selectValue === "demographic") {
      field = commonColumns;
    }

    return field;
  }

  useEffect(() => {
    if (!loading && isDataFiltersApplied) {
      filtersAppliedChange('hls', {hlsData, filtersApplied}, 'hlsData');
    }
  }, [loading])

  useEffect(()=>{
    let [{selectedFields, isupdated}] = JSON.parse(localStorage.getItem("summaryWidgetColumns")) || [{}];
    const field = getInitalColumnFields();
    selectedFields = extraColumnsAddOrUpdate(selectedFields, preference.hls && preference.hls["type"] ? preference.hls["type"] : "category", keydriver && keydriver.length > 0, Boolean(hlsData));
    // update the dropdown
    dispatch(dashboardAction.updateSummaryWidgetColumnsDropdown(field))

    if (isupdated && !_.isEqual(selectedFields, field)) {
      setTempSummaryTableColumns([...selectedFields])
      setSummaryTableColumns([...selectedFields])
      if (!_.isEqual(selectedFields, summaryWidgetColumnsDropdown)) {
        dispatch(dashboardAction.updateSelectedSummaryWidgetColumnsDropdown([...selectedFields]))
      }
    } else {
      localStorage.setItem("summaryWidgetColumns", JSON.stringify([{
        isupdated: false,
        selectedFields: field
      }]));
      setTempSummaryTableColumns(field);
      setSummaryTableColumns(field);
      if (!_.isEqual(field, summaryWidgetColumnsDropdown)) {
        dispatch(dashboardAction.updateSelectedSummaryWidgetColumnsDropdown(field))
      }
    }
  },[])

  const handleCloseSummaryWidget = () =>{
    handleClose("0");

    // reset customize columns selections to default
    const initialColumn = getInitalColumnFields();
    dispatch(dashboardAction.updateSelectedSummaryWidgetColumnsDropdown(initialColumn));
    localStorage.removeItem("summaryWidgetColumns");
  }

  // popup close
  const onClose = () => {
    dispatch(
      updateStateReducerAction.update(
        dashboardConstant.SHOW_HIGH_LEVEL_SUMMERY_POPUP,
        !showPopup
      )
    );
  };

  // open popup
  const openPopup = () => {
    dispatch(
      updateStateReducerAction.update(
        dashboardConstant.SHOW_HIGH_LEVEL_SUMMERY_POPUP,
        !showPopup
      )
    );
  };

  //excel Download Function
  const excelDownload = () => {
    const { hls: HLS, historical } = { ...preference };

    let selectValue = HLS && HLS["type"] ? HLS["type"] : "category";
    const excelData = {
      hcData,
      hlsData,
      keydriver: keydriver || [],
      metadataKeyname,
      selectValue: selectValue,
      excelType: excelConstants.HLS_DASHBOARD_EXCEL,
      historical: historical,
      hls: HLS,
      summaryTableColumns: summaryTableColumns,
      filtersApplied: filtersApplied,
    };

    dispatch(excelAction.download(excelData));
  };

  const handleApplyColumnChanges = () => {
    const [{selectedFields}] = JSON.parse(localStorage.getItem("summaryWidgetColumns"))
    setTempSummaryTableColumns(tempSummaryTableColumns);
    setSummaryTableColumns(tempSummaryTableColumns);
    if (!_.isEqual(selectedFields, tempSummaryTableColumns)) {
      localStorage.setItem("summaryWidgetColumns", JSON.stringify([{
        isupdated: true,
        selectedFields: [...tempSummaryTableColumns]
      }]))
      dispatch(dashboardAction.updateSelectedSummaryWidgetColumnsDropdown([...tempSummaryTableColumns]))
    }
    
    setOpenOptions(null);
  }

  const open = Boolean(openOptions)

  const [isCustomColumnDisabled, setIsCustomColumnDisabled] = useState(false);
  useEffect(() => {
    let cusColumn = new Set(tempSummaryTableColumns);
    const currentViewType = preference.hls && preference.hls["type"] ? preference.hls["type"] : "category";
    const isKeyDriver = keydriver && keydriver.length > 0;
    if (currentViewType === "question" || currentViewType === "keyDrivers") {
      if (isKeyDriver) {
        cusColumn.delete("keyDriverValue")
        cusColumn.delete("categoryName")
      } else {
        cusColumn.delete("categoryName")
      }
    }
    const cusColumnCheckBox = [...cusColumn].filter(r => r);
    
    setIsCustomColumnDisabled(!Boolean(cusColumnCheckBox.length))
  }, [tempSummaryTableColumns])

  return (
    <React.Fragment>
      <Grid item sm={12} className={"mb-14"}>
        <Grid item className={classes.insightTile}>
          <Grid container alignItems="center" className="mb-1">
            {!loading && (
              <Grid item>
                <DragHandle />
              </Grid>
            )}
            <Grid item>
              <TypographyComponent
                variant="h4"
                className="txtBold fontSize16 ml-1"
              >
                {"Summary"}
              </TypographyComponent>
            </Grid>
            <Grid item className={classes.viewActionButton}>
              {!loading && (
                <Grid container>
                  {hlsData?.['category'] && !loading && Object.keys(hlsData?.['category']).length > 0 && (
                    <React.Fragment>
                      <Grid className={classes.IconContainer}>
                        <Tooltip title={"Customize Columns"} arrow>
                          <IconButton
                            className={classes.IconContainer}
                            onClick={(e) => setOpenOptions(e.target)}
                            size="large"
                          >
                            <Grid className={classes.IconContainer}>
                              {CustomizeColumns()}
                            </Grid>
                          </IconButton>
                        </Tooltip>
                      </Grid>
                      <Grid className={classes.IconContainer}>
                            <Tooltip
                              title={"Excel Download"}
                              style={{ paddingLeft: "8px", paddingTop: "12px" }}
                              arrow
                            >
                              <IconButton
                                className={classes.IconContainer}
                                onClick={() => {
                                  excelDownload();
                                }}
                                size="large"
                              >
                                <Grid className={`mt3 ${classes.IconContainer}`}>
                                  {Download()}
                                </Grid>
                              </IconButton>
                            </Tooltip>
                      </Grid>
                    </React.Fragment>
                  )}
                  <Grid className={classes.IconContainer}>
                    <Tooltip title={"Expand"} arrow>
                      <IconButton
                        className={classes.IconContainer}
                        onClick={() => {
                          openPopup();
                        }}
                        size="large"
                      >
                        <Grid className={classes.IconContainer}>
                          {Expand()}
                        </Grid>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                  <Grid className={classes.IconContainer}>
                    <Tooltip title={"Close"} arrow>
                      <IconButton
                        className={classes.IconContainer}
                        onClick={() => {
                          handleCloseSummaryWidget()
                        }}
                        size="large"
                      >
                        <Grid className={classes.IconContainer}>
                          {DashboardClose()}
                        </Grid>
                      </IconButton>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>

          <Grid container spacing={3} className="pt-3">
            <HighLevelSummary
              isUpdate={isUpdate}
              preference={preference}
              stopChartReload={stopChartReload}
              allSurveys={allSurveys}
              updateReportPreference={updateReportPreference}
              summaryTableColumns={summaryTableColumns}
            />
          </Grid>

          <Grid container></Grid>
        </Grid>
      </Grid>

      {/* Popup expand for High level summary table */}

      <DialogComponent open={showPopup} fullScreen={true}>
        <HighlevelsummaryPopup
          classes={classes}
          onClose={onClose}
          isUpdate={isUpdate}
          preference={preference}
          allSurveys={allSurveys}
          stopChartReload={stopChartReload}
          updateReportPreference={updateReportPreference}
          summaryTableColumns={summaryTableColumns}
        />
      </DialogComponent>
      <Grid item xs={12}>
        {/*customize columns popover */}

        <Popover
          className={classes.summaryWidgetPopover}
          open={open}
          anchorEl={openOptions}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={() => {
            setOpenOptions(null);
            setTempSummaryTableColumns([...summaryTableColumns]);
          }}
        >
          <TypographyComponent
            style={{
              color: "#B1B2B3",
              fontSize: "12px",
              fontFamily: "Montserrat",
              fontWeight: "500",
              paddingBottom: "5px",
              paddingTop: "15px",
              paddingLeft: "15px",
            }}
          >
            Select columns to view or hide
          </TypographyComponent>
          {commonColumns.length &&
            commonColumns.map((item) => (
              <Grid style={{ paddingLeft: "25px", paddingRight: "25px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                        tempSummaryTableColumns.findIndex((f) => f === item) > -1
                    }
                    onClick={() => {
                      const fieldIndex = tempSummaryTableColumns.findIndex(
                        (f) => f === item
                      );

                      if (fieldIndex > -1) {
                        const currentSurveyColumns = [
                          ...tempSummaryTableColumns,
                        ];

                        currentSurveyColumns.splice(fieldIndex, 1);

                        setTempSummaryTableColumns([...currentSurveyColumns]);
                      } else {
                        setTempSummaryTableColumns([
                          ...tempSummaryTableColumns,,
                          item,
                        ]);
                      }
                    }}
                  ></Checkbox>
                }
                label={summaryColumnField[item]}
              ></FormControlLabel>
              </Grid>
            ))}
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ paddingTop: "10px", paddingBottom: "10px" }}
          >
          <Button
            color="primary"
            variant="contained"
            size="small"
              style={{
                marginLeft: "25px",
                marginBottom: "10px",
                marginRight: "10px",
              }}
              disabled={isCustomColumnDisabled}
            onClick={handleApplyColumnChanges}
          >
            Apply
          </Button>
          </Box>
        </Popover>
      </Grid>
    </React.Fragment>
  );
}
// default props
HighLevelSummaryContent.defaultProps = {
  classes: {},
  handleClose: () => { },
  DragHandle: {},
  isUpdate: false,
  stopChartReload: () => { },
  updateReportPreference: () => { },
  allSurveys: [],
  filtersAppliedChange: () => {}
};

// prop types
HighLevelSummaryContent.propTypes = {
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  DragHandle: PropTypes.object,
  isUpdate: PropTypes.bool,
  preference: PropTypes.object,
  stopChartReload: PropTypes.func,
  updateReportPreference: PropTypes.func,
  allSurveys: PropTypes.array,
  filtersAppliedChange: PropTypes.func,
};
export default HighLevelSummaryContent;
